<template>
    <li>
        <RouterLink
            :to="{ name: props.routeName }"
            class="flex items-center p-2 rounded-lg hover:bg-gray-100 group"
            active-class="active"
        >
            <i :class="`fi ${props.icon} translate-y-0.5`"></i>
            <span class="ms-3">{{ t(props.titleTKey) }}</span>
        </RouterLink>
    </li>
</template>

<script setup lang="ts">
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { RouteName } from '@/types'
import { TKey } from '@/i18n'

interface Props {
    titleTKey: TKey
    icon: string
    routeName: RouteName
}

const props = defineProps<Props>()
const { t } = useI18n()
</script>
