<template>
    <AVInput
        :name="name"
        type="date"
        :placeholder="placeholder"
        :model-value="
            modelValue ? modelValue.toISOString().slice(0, 10) : undefined
        "
        @update:modelValue="onChange"
        @blur="onBlur"
        :prefix="prefix"
        :label="label"
    />
</template>

<script setup lang="ts">
import AVInput from './AVInput.vue'
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'

interface Props extends FormFieldProps<Date | null> {
    placeholder?: string
    type?: string
    prefix?: string
}

interface Emits extends FormFieldEmits<string | Date> {
    (event: 'blur', domEvent: Event): void
}

withDefaults(defineProps<Props>(), {
    placeholder: '',
})

const emit = defineEmits<Emits>()

const onChange = (value: string) => {
    emit('update:modelValue', value ? new Date(value) : value)
}

const onBlur = (event: Event) => {
    emit('blur', event)
}
</script>
