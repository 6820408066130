import { Recipient, RecipientSimple, RecipientWrite } from '@/models/recipients'
import { defineStore } from 'pinia'
import {
    RecipientListParams,
    createRecipient,
    deleteRecipient,
    listAllRecipientsSimple,
    listRecipients,
    readRecipient,
    updateRecipient,
    downloadCSVRecipients,
} from './requests'
import { defineCrudStore } from '@/stores/crud'
import { HttpStatusCode } from 'axios'
import { computed } from 'vue'
import { castToDeepReadOnly } from '@/utils/types'
import { useRequestTracker } from '@/utils/request-tracker'
import { ModelId } from '@/models/types'

export const useRecipientPickerStore = defineStore(
    'Backoffice:Recipients:RecipientPicker',
    () => {
        const requestTracker = useRequestTracker<Array<RecipientSimple>>()
        const listRecipients = () =>
            requestTracker.runRequest(
                listAllRecipientsSimple,
                HttpStatusCode.Ok,
                []
            )
        const recipients = computed(requestTracker.getLoadedData)
        return castToDeepReadOnly({
            _requestTracker: requestTracker.state,
            listRecipients,
            recipients,
        })
    }
)

export const useRecipientsBackofficeCrudStore = defineCrudStore<
    Recipient,
    RecipientWrite,
    RecipientListParams
>('Backoffice:Recipients:Crud', {
    listRequest: listRecipients,
    readRequest: readRecipient,
    createRequest: createRecipient,
    updateRequest: updateRecipient,
    deleteRequest: deleteRecipient,
})

export const useRecipientsExtraStore = defineStore(
    'Backoffice:Recipients:Extra',
    () => {
        const downloadListAsCsv = async (
            rlistId: ModelId | null = null,
            fileName: string | null = null
        ) => {
            const response = await downloadCSVRecipients(rlistId)
            const blob = new Blob([response.data], { type: 'text/csv' })
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            const currentDate = new Date()
            const year = currentDate.getFullYear()
            const month = String(currentDate.getMonth() + 1).padStart(2, '0')
            const day = String(currentDate.getDate()).padStart(2, '0')
            a.style.display = 'none'
            a.href = url
            a.download = fileName
                ? `${fileName}.csv`
                : `recipients_${year}-${month}-${day}.csv`
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
        }
        return castToDeepReadOnly({
            downloadListAsCsv,
        })
    }
)
