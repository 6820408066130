<template>
    <PageHeader>
        <template #title>
            {{ t(props.titleTKey) }}
        </template>
        <template #subtitle>
            {{ t(props.descriptionTKey) }}
        </template>
        <template v-if="downloadTKey" #download>
            <button
                @click="emit('downloadClicked')"
                class="mt-4 lg:mt-0 hover:bg-blue-700 hover:text-white font-semibold py-1 px-3 rounded-3xl mr-4 border border-bbb hover:border-opacity-0"
            >
                <i class="fi fi-rs-download w-3 h-3 mr-1 text-xs"></i>
                {{ t(downloadTKey) }}
            </button>
        </template>
        <template #actions>
            <RouterLink
                v-if="props.createActionTKey && props.createRouteName"
                :to="{ name: props.createRouteName }"
                class="mt-4 lg:mt-0 bg-primary hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-3xl"
            >
                <i class="fi fi-rs-plus w-3 h-3 mr-1 text-xs"></i>
                {{ t(props.createActionTKey) }}
            </RouterLink>
        </template>
    </PageHeader>
</template>
<script setup lang="ts">
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { RouteName } from '@/types'
import { TKey } from '@/i18n'
import PageHeader from './PageHeader.vue'

interface Props {
    titleTKey: TKey
    descriptionTKey: TKey
    createActionTKey?: TKey
    createRouteName?: RouteName
    downloadTKey?: TKey
}

interface Emits {
    (event: 'downloadClicked'): void
}

const emit = defineEmits<Emits>()

const props = defineProps<Props>()
const { t } = useI18n()
</script>
