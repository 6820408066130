<template>
    <AVModalWithRouter
        v-if="!crudStore.isBusy && currentRecipient"
        :route-name-on-close="props.routeNameOnClose"
        title-t-key="backoffice.recipients.updateRecipient"
    >
        <template v-slot:content>
            <p class="text-gray-600 mb-6">Update recipient details.</p>
            <RecipientForm
                :recipient="currentRecipient!"
                :route-name-on-close="props.routeNameOnClose"
                :validation-errors="crudStore.itemValidationErrors"
                submit-t-key="backoffice.actions.save"
                @submit="onSubmitClicked"
            />
        </template>
    </AVModalWithRouter>
</template>

<script setup lang="ts">
import AVModalWithRouter from '@/components/AVModalWithRouter.vue'
import { useRecipientsBackofficeCrudStore } from './stores'
import RecipientForm from './RecipientForm.vue'
import { RecipientWrite } from '@/models/recipients'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount } from 'vue'
import { RouteName } from '@/types'
import { ModelId } from '@/models/types'

interface Props {
    routeNameOnClose: RouteName
    recipientId: ModelId
}

const props = defineProps<Props>()
const crudStore = useRecipientsBackofficeCrudStore()

const { getItemById } = storeToRefs(crudStore)
const currentRecipient = computed(() => getItemById.value(props.recipientId))

onBeforeMount(crudStore.resetItemValidationError)

const onSubmitClicked = async (recipient: RecipientWrite) => {
    await crudStore.runUpdate(props.recipientId, recipient, {
        nextRouteName: props.routeNameOnClose,
        message: 'backoffice.recipients.messageUpdated',
    })
    crudStore.runList()
}
</script>

<style scoped></style>
