import { createI18n, useI18n } from 'vue-i18n'
import merge from 'lodash/merge'
import {
    TRANSLATIONS as BACKOFFICE_TRANSLATIONS,
    LOCALES_AVAILABLE as BACKOFFICE_LOCALES_AVAILABLE,
    TranslationsSchema as BackofficeTranslationsSchema,
} from '../AppBackoffice/i18n'
import {
    TRANSLATIONS as CAMPAIGNS_TRANSLATIONS,
    LOCALES_AVAILABLE as CAMPAIGNS_LOCALES_AVAILABLE,
    TranslationsSchema as CampaignsTranslationsSchema,
} from '../AppCampaigns/i18n'
import TRANSLATIONS_FR_SHARED from './translations/fr.json'
import TRANSLATIONS_EN_SHARED from './translations/en.json'
import TRANSLATIONS_IT_SHARED from './translations/it.json'
import TRANSLATIONS_SV_SHARED from './translations/sv.json'
import TRANSLATIONS_DE_SHARED from './translations/de.json'
import TRANSLATIONS_PL_SHARED from './translations/pl.json'
import TRANSLATIONS_HR_SHARED from './translations/hr.json'
import TRANSLATIONS_PT_SHARED from './translations/pt.json'
import { Path } from 'vee-validate'
import { defineStore } from 'pinia'
import { Ref, computed, ref } from 'vue'
import { AppKey } from '../types'
import { assertTypesMatch, TypeEqualityGuard } from '@/utils/types'
import { components } from '../schema'

export type Locale = 'en' | 'fr' | 'de' | 'it' | 'sv' | 'pl' | 'hr' | 'pt'

assertTypesMatch<
    TypeEqualityGuard<components['schemas']['LanguageEnum'], Locale>
>()

const DEFAULT_LOCALE: Locale = 'en'

export const LOCALES_AVAILABLE = [
    'en',
    'fr',
    'de',
    'it',
    'sv',
    'pl',
    'hr',
    'pt',
] as const

type SharedTranslationsSchema = {
    shared: typeof TRANSLATIONS_EN_SHARED
}

export const SHARED_TRANSLATIONS: {
    [locale: string]: SharedTranslationsSchema
} = {
    fr: {
        shared: TRANSLATIONS_FR_SHARED,
    },
    en: {
        shared: TRANSLATIONS_EN_SHARED,
    },
    it: {
        shared: TRANSLATIONS_IT_SHARED,
    },
    sv: {
        shared: TRANSLATIONS_SV_SHARED,
    },
    de: {
        shared: TRANSLATIONS_DE_SHARED,
    },
    pl: {
        shared: TRANSLATIONS_PL_SHARED,
    },
    hr: {
        shared: TRANSLATIONS_HR_SHARED,
    },
    pt: {
        shared: TRANSLATIONS_PT_SHARED,
    },
} as const

export const LOCALE_OPTIONS: Array<{ label: TKey; value: Locale }> = [
    { label: 'backoffice.languages.english', value: 'en' },
    { label: 'backoffice.languages.french', value: 'fr' },
    { label: 'backoffice.languages.deutsch', value: 'de' },
    { label: 'backoffice.languages.italian', value: 'it' },
    { label: 'backoffice.languages.swedish', value: 'sv' },
    { label: 'backoffice.languages.polish', value: 'pl' },
    { label: 'backoffice.languages.croatian', value: 'hr' },
    { label: 'backoffice.languages.portuguese', value: 'pt' },
] as const

const TRANSLATIONS = merge(
    {},
    BACKOFFICE_TRANSLATIONS,
    CAMPAIGNS_TRANSLATIONS,
    SHARED_TRANSLATIONS
)

type TranslationsSchema = BackofficeTranslationsSchema &
    CampaignsTranslationsSchema &
    SharedTranslationsSchema

export type TKey = Path<(typeof TRANSLATIONS)['en']>

const i18n = createI18n<[TranslationsSchema], Locale>({
    legacy: false,
    locale: DEFAULT_LOCALE,
    fallbackLocale: DEFAULT_LOCALE,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    messages: TRANSLATIONS as any,
})

/**
 * This pinia store is used so that we can separate the locales and translations available
 * for the backoffice and campaigns.
 */
export const useI18CustomStore = defineStore(
    'i18n',
    () => {
        // STATE
        const _localesAvailable = ref<Readonly<Array<Locale>>>([])
        const _currentLocale = ref<Locale>(DEFAULT_LOCALE)

        // ACTIONS
        const loadTranslations = (appName: AppKey) => {
            switch (appName) {
                case 'AppBackoffice':
                    _localesAvailable.value = BACKOFFICE_LOCALES_AVAILABLE
                    break
                case 'AppCampaigns':
                    _localesAvailable.value = CAMPAIGNS_LOCALES_AVAILABLE
                    break
            }
        }

        const persistLocale = (locale: Locale) => {
            _currentLocale.value = locale
        }

        const restoreLocale = (localeRef: Ref<Locale>) => {
            localeRef.value = _currentLocale.value
        }

        // GETTERS
        const localeOptions = computed(() =>
            LOCALE_OPTIONS.filter((option) =>
                _localesAvailable.value.includes(option.value)
            )
        )

        return {
            _localesAvailable,
            _currentLocale,
            loadTranslations,
            persistLocale,
            restoreLocale,
            localeOptions,
        }
    },
    {
        // Use lib `pinia-plugin-persistedstate` to persist `auth` from the state.
        persist: {
            paths: ['_currentLocale'],
        },
    }
)

/**
 * Composable to restore the locale that was persisted
 * in local storage through the pinia plugin.
 */
export const useRestoreLocale = () => {
    const { locale } = useI18n()
    const i18nStore = useI18CustomStore()
    i18nStore.restoreLocale(locale as Ref<Locale>)
}

export default i18n
