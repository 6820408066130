<template>
    <div class="flex flex-row space-x-4 mt-5 mb-3">
        <div class="flex w-1/12 items-center justify-center">
            {{ optionIndexDisplay }}.
        </div>
        <div class="flex w-3/12">
            <AVInput
                v-bind="answerField.props.value"
                v-on="answerField.listeners.value"
                :placeholder="'Ex: Bleu'"
            />
        </div>
        <div class="flex-1 flex-auto w-7/12">
            <AVInput
                v-bind="promptField.props.value"
                v-on="promptField.listeners.value"
                :placeholder="'Ex: Ecris ce message du point vu d\'une utilisateurice ayant repondu *bleu*'"
            />
        </div>
        <div class="flex w-1/12 items-center justify-center cursor-pointer">
            <i
                @click="onClickRemoveOption"
                class="fi fi-rs-circle-cross text-primary"
            />
        </div>
    </div>
</template>
<script setup lang="ts">
import { FieldPath, makeUseField } from '@/utils/forms'
import AVInput from '@/components/forms/AVInput.vue'
import { CustomQuestionSelectOption } from '@/models/custom-questions'
import { CampaignWrite } from '@/models/campaigns'

interface Props {
    optionIndex: number
    optionPath: FieldPath<
        CampaignWrite,
        `custom_questions.${number}.options.${number}`
    >
}

interface Emits {
    (event: 'removeOption'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const optionIndexDisplay = props.optionIndex + 1

const useFieldCustomQuestionOption = makeUseField<
    CampaignWrite,
    CustomQuestionSelectOption
>(props.optionPath)
const answerField = useFieldCustomQuestionOption('answer')
const promptField = useFieldCustomQuestionOption('prompt')

const onClickRemoveOption = () => {
    emit('removeOption')
}
</script>
