<template>
    <div class="relative w-full h-full">
        <Transition name="reenter-appear">
            <!-- Reenter screen -->
            <SendCampaignContainer v-if="store.isSendDone">
                <SendCampaignInnerContainer>
                    <ProgressBarMobile
                        class="block md:hidden"
                        :has-previous-button="true"
                        title-t-key="user.email.StepReenterProgressBarTitle"
                        @previous="onMobilePreviousClicked"
                    >
                        <template #circle>
                            <CircleProgress progress="FULL" label="✓" />
                        </template>
                    </ProgressBarMobile>
                </SendCampaignInnerContainer>

                <SendCampaignInnerContainer class="pt-8 md:pb-8">
                    <ReenterScreen />
                </SendCampaignInnerContainer>
            </SendCampaignContainer>

            <!-- All steps -->
            <SendCampaignContainer v-else>
                <SendCampaignInnerContainer>
                    <ProgressBar
                        v-if="wizardStore.selectedStepsDefinitions.length > 0"
                        :current-step-index="wizardStore.currentStepIndex"
                        :selected-steps-definitions="
                            wizardStore.selectedStepsDefinitions
                        "
                        :can-go-forward="wizardStore.canGoForward"
                        :can-go-backward="wizardStore.canGoBackward"
                        @change-current-step="wizardStore.goToStep"
                    />
                </SendCampaignInnerContainer>
                <SendCampaignInnerContainerPadding
                    v-if="isInitialized"
                    class="relative h-max-full flex-1 flex flex-col"
                >
                    <!-- Component below is used just for a small gradient at the top of the page -->
                    <div
                        class="absolute left-0 top-0 h-[2em] w-full bg-gradient-to-b z-10"
                    ></div>
                    <div
                        ref="scrollContainer"
                        class="flex-1 pt-6 md:pt-16 overflow-auto"
                    >
                        <div
                            v-if="campaign && campaign.state === 'active'"
                            class="relative"
                        >
                            <Transition :name="stepTransitionName">
                                <StepInterpellationSelection
                                    v-if="
                                        wizardStore.currentStepKey ===
                                        'StepInterpellationSelection'
                                    "
                                />
                                <StepGeolocation
                                    v-else-if="
                                        wizardStore.currentStepKey ===
                                        'StepGeolocation'
                                    "
                                />
                                <StepInterpellationModeSelection
                                    v-else-if="
                                        wizardStore.currentStepKey ===
                                        'StepInterpellationModeSelection'
                                    "
                                />
                                <StepSenderQuestions
                                    v-else-if="
                                        wizardStore.currentStepKey ===
                                        'StepSenderQuestions'
                                    "
                                />
                                <StepGenerateMessage
                                    v-else-if="
                                        wizardStore.currentStepKey ===
                                        'StepGenerateMessage'
                                    "
                                />
                            </Transition>
                        </div>
                        <div v-else class="text-center">
                            <p>{{ t('user.campaign.ended') }}</p>
                        </div>
                    </div>
                </SendCampaignInnerContainerPadding>
            </SendCampaignContainer>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import SendCampaignContainer from './SendCampaignContainer.vue'
import SendCampaignInnerContainer from './SendCampaignInnerContainer.vue'
import SendCampaignInnerContainerPadding from './SendCampaignInnerContainerPadding.vue'
import ReenterScreen from './ReenterScreen/ReenterScreen.vue'
import ProgressBarMobile from '@/components/ProgressBar/ProgressBarMobile.vue'
import CircleProgress from '@/components/ProgressBar/CircleProgress.vue'
import StepInterpellationSelection from './StepInterpellationSelection/StepInterpellationSelection.vue'
import StepGeolocation from './StepGeolocation/StepGeolocation.vue'
import StepInterpellationModeSelection from './StepInterpellationModeSelection/StepInterpellationModeSelection.vue'
import StepSenderQuestions from './StepSenderQuestions/StepSenderQuestions.vue'
import StepGenerateMessage from './StepGenerateMessage/StepGenerateMessage.vue'
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'
import { onMounted, ref, watch } from 'vue'
import { CampaignPublic } from '@/models/campaigns'
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'

export interface Props {
    campaign: CampaignPublic
}

const props = defineProps<Props>()

const isInitialized = ref(false)
const scrollContainer = ref<HTMLDivElement | null>(null)
const stepTransitionName = ref<'next-step' | 'previous-step'>('next-step')
const { locale, t } = useI18n()
const store = useSendCampaignStore()
const wizardStore = useSendCampaignWizardStore()

store.$onAction(async ({ name }) => {
    if (name === 'setSender') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
})

watch(
    () => wizardStore.currentStepIndex,
    (newIndex, oldIndex) => {
        stepTransitionName.value =
            newIndex > oldIndex ? 'next-step' : 'previous-step'
        if (scrollContainer.value && newIndex !== oldIndex) {
            scrollContainer.value.scrollTop = 0
        }
    }
)

const onMobilePreviousClicked = () => {
    store.setIsSendDone(false)
}

onMounted(() => {
    locale.value = props.campaign.language || 'en'
    store.$reset()
    store.setCampaign(props.campaign)
    isInitialized.value = true
})
</script>

<style scoped>
.next-step-enter-active,
.next-step-leave-active,
.previous-step-enter-active,
.previous-step-leave-active {
    transition: transform 350ms linear;
}

.next-step-enter-from {
    position: absolute;
    top: 0;
    transform: translateX(100vw);
}
.next-step-leave-from {
    transform: translateX(0);
}
.next-step-leave-active {
    transform: translateX(-100vw);
}

.previous-step-enter-from {
    transform: translateX(-100vw);
}
.previous-step-leave-from {
    transform: translateX(0);
}
.previous-step-leave-active {
    transform: translateX(100vw);
}

.reenter-appear-enter-active,
.reenter-appear-leave-active {
    transition: opacity 0.5s ease;
}

.reenter-appear-enter-from,
.reenter-appear-leave-to {
    opacity: 0;
}
</style>
