import { Organization, OrganizationWrite } from '@/models/organizations'
import { defineStore } from 'pinia'
import {
    readMyOrganization,
    updateMyOrganization,
    updateLogoOrganizationRequest,
} from './requests'
import { castToDeepReadOnly } from '@/utils/types'
import { computed, ref } from 'vue'
import { useCrudOperations } from '@/stores/crud/item'

export const useMyOrganizationStore = defineStore(
    'Backoffice:Organizations:Crud',
    () => {
        const crudOperations = useCrudOperations<
            Organization,
            OrganizationWrite
        >({
            readRequest: readMyOrganization,
            updateRequest: (_: string, organization: OrganizationWrite) =>
                updateMyOrganization(organization),
        })

        const _companyData = ref<Organization | null>(null)

        const updateLogo = async (file: File) => {
            const formData = new FormData()
            if (file.size > 0) {
                formData.append('image', file)
            }
            await updateLogoOrganizationRequest(formData)
        }

        return castToDeepReadOnly({
            // STATE
            _requestTracker: crudOperations.requestTracker.state,
            // ACTIONS
            runRead: () => crudOperations.runRead(''),
            runUpdate: (organization: OrganizationWrite) =>
                crudOperations.runUpdate('', organization),
            updateLogo,
            // GETTERS
            isBusy: computed(crudOperations.requestTracker.isRequestInProgress),
            myOrganization: computed(() => {
                const companyData =
                    crudOperations.requestTracker.getLoadedData() ||
                    _companyData.value
                if (companyData) {
                    _companyData.value = companyData
                }
                return companyData
            }),
            validationErrors: computed(crudOperations.validationErrors),
        })
    }
)
