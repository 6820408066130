<template>
    <StepContainer>
        <StepTitle
            :titles="[
                {
                    title: t(
                        'user.campaign.StepInterpellationModeSelectionHeading',
                        {
                            rlistName: store.interpellation!.name,
                        }
                    ),
                },
            ]"
        />
        <StepContainerInner>
            <StepInstructions>
                {{ t('user.campaign.select_interpellation_mode') }}
            </StepInstructions>
            <InterpellationModesButtonList
                :interpellationModesAvailable="
                    store.interpellationModesAvailableForSelectedRecipients
                "
                @choose-interpellation-mode="onChooseInterpellationMode"
            />
        </StepContainerInner>
    </StepContainer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'
import { InterpellationMode } from '@/models/campaigns'
import { onBeforeMount } from 'vue'
import InterpellationModesButtonList from './InterpellationModesButtonList.vue'
import StepTitle from '../StepTitle.vue'
import StepContainer from '../StepContainer.vue'
import StepContainerInner from '../StepContainerInner.vue'
import StepInstructions from '../StepInstructions.vue'

const store = useSendCampaignStore()
const wizardStore = useSendCampaignWizardStore()
const { t } = useI18n()

const onChooseInterpellationMode = (interpellationMode: InterpellationMode) => {
    store.setInterpellationMode(interpellationMode)
    wizardStore.goToNextStep()
}

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have a campaign set')
    }
    if (!store.interpellation) {
        throw new Error('Store should have a interpellation set')
    }
    store.setInterpellationMode(null)
})
</script>
