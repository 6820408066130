<template>
    <div
        class="copy-button-container"
        @click="copyToClipboard"
        :class="copyButtonClassObject"
    >
        <span class="copy-button idle"
            >{{ t('user.common.copy') }}
            <i class="fi fi-rs-copy-alt text-xs"></i>
        </span>
        <span class="copy-button copied"
            >{{ t('user.common.copied') }}
            <i class="fi fi-rs-check text-xs"></i>
        </span>
    </div>
</template>

<script setup lang="ts">
import { toRef } from 'vue'
import { useCopyButton } from '../utils'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
    contentToCopy: string
}

const props = defineProps<Props>()

const { copyToClipboard, copyButtonClassObject } = useCopyButton(
    toRef(props, 'contentToCopy')
)
</script>

<style scoped>
.copy-button {
    display: inline-block;
    font-size: 11px;
    color: var(--color-blue-gray);
    cursor: pointer;
    border: 1px solid var(--color-light-gray);
    background: var(--color-white);
    border-radius: 10px;
    padding: 0px 5px;
    position: absolute;
    top: 0;
    right: 0px;
    line-height: 1.2em;
}

.copy-button i {
    font-size: 9px;
}

.copy-button.idle {
    opacity: 1;
}

.copy-button.copied {
    opacity: 0;
}

.copy-button-container.copied .copy-button.idle {
    opacity: 0;
}

.copy-button-container.copied .copy-button.copied {
    opacity: 1;
}

.copy-button {
    transition: opacity 0.5s;
}

.copy-button i {
    margin-left: 2px;
}
</style>
