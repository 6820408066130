<template>
    <div>
        <AVInput
            class="mb-3"
            v-bind="subjectField.props.value"
            v-on="subjectField.listeners.value"
            :placeholder="
                t('backoffice.contentSamples.emailSubjectPlaceholder')
            "
        />
        <AVTextarea
            v-bind="contentField.props.value"
            v-on="contentField.listeners.value"
            placeholder="backoffice.contentSamples.emailBodyPlaceholder"
        />
    </div>
</template>
<script setup lang="ts">
import AVTextarea from '@/components/forms/AVTextarea.vue'
import AVInput from '@/components/forms/AVInput.vue'
import { CampaignWrite } from '@/models/campaigns'
import { ContentSampleEmail } from '@/models/content-samples'
import { makeUseField } from '@/utils/forms'
import { ContentSampleFieldsProps } from './types'
import { useI18n } from 'vue-i18n'

interface Props extends ContentSampleFieldsProps {}

const props = defineProps<Props>()

const { t } = useI18n()

const useContentSampleField = makeUseField<CampaignWrite, ContentSampleEmail>(
    props.contentSamplePath
)
const subjectField = useContentSampleField('subject')
const contentField = useContentSampleField('body')
</script>
