<template>
    <div>
        <label
            :class="{
                'block mb-2 text-sm font-normal text-gray-900': true,
                'block mb-2 text-sm font-medium text-red-700': errorTKey,
            }"
            v-if="label"
            >{{ label }}</label
        >
        <textarea
            :class="{
                'block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 bg-white': true,
                'bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5':
                    errorTKey,
            }"
            :name="name"
            :rows="rows"
            :value="modelValue"
            @input="handleChange"
            :placeholder="placeholder ? t(placeholder) : ''"
            :required="required"
        ></textarea>
        <AVError v-if="errorTKey" :error-t-key="errorTKey" />
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'
import { TKey } from '@/i18n'
import AVError from '@/components/forms/AVError.vue'

interface Props extends FormFieldProps<string | null | undefined> {
    rows?: number
    placeholder?: TKey
    required?: boolean
}

interface Emits extends FormFieldEmits<string> {}

withDefaults(defineProps<Props>(), {
    rows: 15,
    required: false,
})
const emit = defineEmits<Emits>()

const { t } = useI18n()

const handleChange = (event: Event) => {
    emit('update:modelValue', (event.target as HTMLTextAreaElement).value)
}
</script>
