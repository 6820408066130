<template>
    <AVModalWithRouter
        :route-name-on-close="props.routeNameOnClose"
        title-t-key="backoffice.rlists.updateRList"
        class="overflow-auto max-h-100"
    >
        <template v-slot:content>
            <RListForm
                :initial-value="currentRList!"
                :route-name-on-close="props.routeNameOnClose"
                submit-t-key="backoffice.actions.save"
                :validation-errors="crudStore.itemValidationErrors"
                @submit="onSubmitClicked"
            />
        </template>
    </AVModalWithRouter>
</template>

<script setup lang="ts">
import AVModalWithRouter from '@/components/AVModalWithRouter.vue'
import { useRListsBackofficeCrudStore } from './stores'
import RListForm from './RListForm.vue'
import { RListWrite } from '@/models/rlists'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount } from 'vue'
import { RouteName } from '@/types'
import { ModelId } from '@/models/types'

interface Props {
    routeNameOnClose: RouteName
    rlistId: ModelId
}

const props = defineProps<Props>()
const crudStore = useRListsBackofficeCrudStore()
const { getItemById } = storeToRefs(crudStore)
const currentRList = computed(() => getItemById.value(props.rlistId))

onBeforeMount(crudStore.resetItemValidationError)

const onSubmitClicked = async (rlist: RListWrite) => {
    await crudStore.runUpdate(props.rlistId, rlist, {
        nextRouteName: props.routeNameOnClose,
        message: 'backoffice.rlists.messageUpdated',
    })
    crudStore.runList()
}
</script>

<style scoped></style>
