import { ContextMenuId } from '@/components/context-menus/types'
import { castToDeepReadOnly } from '@/utils/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useContextMenusStore = defineStore('ContextMenus', () => {
    // STATE
    const openMenuId = ref<ContextMenuId | null>(null)

    // ACTIONS
    // This is used from menu buttons to request opening menu.
    // The action is then caught by the corresponding context menu,
    // which will do the work, and call `setMenuOpen` when it is done.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const openMenu = (_: ContextMenuId, __: Event) => {
        // no-op
    }

    const setMenuOpen = (menuId: ContextMenuId) => {
        openMenuId.value = menuId
    }

    const closeMenu = (menuId: ContextMenuId) => {
        if (openMenuId.value === menuId) {
            openMenuId.value = null
        }
    }

    return castToDeepReadOnly({
        openMenuId,
        openMenu,
        setMenuOpen,
        closeMenu,
    })
})
