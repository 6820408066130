import UpdatePasswordView from '@/AppBackoffice/user/UpdatePasswordView.vue'
import ProfileView from '@/AppBackoffice/user/ProfileView.vue'
import { RouteRecordRaw } from 'vue-router'
import ManageEmails from './ManageEmails.vue'
import EmailVerify from './EmailVerify.vue'
import LoginView from './LoginView.vue'
import StartResetPassword from './StartResetPassword.vue'
import ResetPassword from './ResetPassword.vue'

export const anonymousUserRoutes: Array<RouteRecordRaw> = [
    {
        path: 'login',
        name: 'login',
        component: LoginView,
        props: (route) => ({ next: route.query.next }),
    },
    {
        path: 'start-reset',
        name: 'start-reset',
        component: StartResetPassword,
    },
    {
        path: 'reset-password/:token',
        name: 'reset-password',
        component: ResetPassword,
        props: (route) => ({ token: route.params.token }),
    },
    {
        path: 'email-verify/:emailKey',
        name: 'email-verify',
        component: EmailVerify,
        props: (route) => ({
            emailKey: route.params.emailKey,
        }),
    },
]

export const userRoutes: Array<RouteRecordRaw> = [
    {
        path: 'profile',
        children: [
            {
                path: '',
                name: 'profile',
                component: ProfileView,
            },
            {
                path: 'update-password',
                name: 'update_password',
                component: UpdatePasswordView,
            },
            {
                path: 'manage-emails',
                name: 'manage_emails',
                component: ManageEmails,
            },
        ],
    },
]
