<template>
    <div>
        <label
            :class="{
                'block mb-2 text-[15px] font-medium lg:font-normal text-gray-900': true,
                'text-red-700 ': errorTKey,
            }"
            v-if="label"
            >{{ label }}</label
        >
        <div class="flex items-center justify-center w-full mb-3">
            <label
                :for="name"
                class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                @dragover.prevent
                @drop.prevent="onHandleDrop"
                v-if="!fileName"
            >
                <div
                    class="flex flex-col items-center justify-center pt-5 pb-6"
                >
                    <i class="fi fi-rs-cloud-upload text-4xl text-gray-500"></i>
                    <p class="mb-2 text-sm text-gray-500">
                        Click to upload or drag and drop
                    </p>
                    <p
                        v-if="acceptedFileExtensions"
                        class="text-xs text-gray-500"
                    >
                        Accepted file types: {{ acceptedFileExtensions }}
                    </p>
                </div>

                <input
                    :id="name"
                    :name="name"
                    type="file"
                    class="hidden"
                    @change="onHandleFileInput"
                    :accept="acceptedFileExtensions || '*.*'"
                />
            </label>
            <div
                v-else-if="!isImageFile"
                class="flex flex-col items-center justify-center pt-5 pb-6"
            >
                <i class="fi fi-rs-file text-4xl text-gray-500"></i>
                <p class="mb-2 text-sm text-gray-500">
                    File selected:
                    <span class="font-semibold">{{ fileName }}</span>
                </p>
                <button
                    @click.stop="removeFile"
                    class="text-sm text-red-500 hover:text-red-700"
                >
                    Remove file
                </button>
            </div>
            <div v-else class="flex flex-col items-center justify-center p-0">
                <img
                    :src="tempUrl || currentFileUrl"
                    alt="Image preview"
                    class="object-cover rounded-lg"
                />

                <button
                    @click.stop="removeFile"
                    class="text-sm text-red-500 hover:text-red-700"
                >
                    Remove file
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'
import { ref } from 'vue'

interface Props extends FormFieldProps<File | null | undefined> {
    fileTypes?: Array<string>
    currentFileUrl?: string
    isImageFile: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isImageFile: false,
})

const fileName = ref<string | null>(
    props.currentFileUrl?.split('/').pop() || null
)
const tempUrl = ref<string | null>(null)

interface Emits extends FormFieldEmits<File> {
    (event: 'clear', domEvent: Event): void
}

const emit = defineEmits<Emits>()

const acceptedFileExtensions = props.fileTypes
    ?.map((type) => `.${type.toLowerCase()}`)
    .join(',')

const onHandleFileInput = (event: Event) => {
    const target = event.target as HTMLInputElement
    if (target.files) {
        processFile(target.files[0])
    }
}

const removeFile = () => {
    fileName.value = null
    tempUrl.value = null
    emit('clear', new Event('clear'))
}

const onHandleDrop = (event: DragEvent) => {
    if (event.dataTransfer?.files) {
        processFile(event.dataTransfer.files[0])
    }
}

const processFile = (file: File) => {
    fileName.value = file.name
    tempUrl.value = URL.createObjectURL(file)
    emit('update:modelValue', file)
}
</script>
