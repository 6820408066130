<template>
    <AVModalWithRouter>
        <template v-slot:content>
            <AVSelectButtons
                name="embedVariant"
                v-model="embedVariant"
                :options="[
                    { value: 'data', label: 'Data' },
                    { value: 'id', label: 'ID' },
                ]"
            />
            <p class="mb-4">{{ variantDescription }}</p>
            <pre
                class="w-full h-[25em] overflow-auto border-solid border-2 border-primary p-2 mb-8"
                >{{ generatedCodeSnippet }}</pre
            >
            <AVButton
                @click="copyCampaignCode()"
                button-type="outline-primary"
                class="mr-3 mb-2"
                :class="{ 'text-[#333]': wasCopiedToClipboard }"
            >
                <span v-if="!wasCopiedToClipboard">Copy Campaign code</span>
                <span v-else>Copied to clipboard</span>
            </AVButton>
        </template>
    </AVModalWithRouter>
</template>
<script setup lang="ts">
import { Campaign } from '@/models/campaigns'
import { computed, ref } from 'vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVSelectButtons from '@/components/forms/AVSelectButtons.vue'
import AVModalWithRouter from '@/components/AVModalWithRouter.vue'
import {
    generateEmbedWithDataSnippet,
    generateEmbedWithIdSnippet,
} from '@/AppCampaigns/embed/utils'
import { EMBED_JS_ROOT_URL } from '@/constants'
import { useCampaignBackofficeCrudStore } from '../stores'

type EmbedVariant = 'data' | 'id'

interface Props {
    campaign: Campaign
}

const props = defineProps<Props>()

const crudStore = useCampaignBackofficeCrudStore()

const variantDescription = computed(() => {
    switch (embedVariant.value) {
        case 'data':
            return 'Embed code contains the full campaign data, and can be modified in place.'
        case 'id':
            return 'Embed code contains only the campaign ID, and will fetch the campaign data from the activoice server.'
        default:
            throw new Error('Unknown embed variant')
    }
})
const generatedCodeSnippet = computed(() => {
    switch (embedVariant.value) {
        case 'data':
            return generateEmbedWithDataSnippet(
                props.campaign,
                EMBED_JS_ROOT_URL
            )
        case 'id':
            return generateEmbedWithIdSnippet(
                props.campaign.id,
                EMBED_JS_ROOT_URL
            )
        default:
            throw new Error('Unknown embed variant')
    }
})

const embedVariant = ref<EmbedVariant>('data')
const wasCopiedToClipboard = ref(false)

const copyCampaignCode = () => {
    if (!crudStore.itemData) {
        throw new Error('No campaign data to copy')
    }
    navigator.clipboard.writeText(generatedCodeSnippet.value)
    wasCopiedToClipboard.value = true
    setTimeout(() => {
        wasCopiedToClipboard.value = false
    }, 3000)
}
</script>
