<template>
    <div class="relative">
        <LoaderWithOverlay v-if="crudStore.isBusy" />
        <div class="flex justify-center items-center" v-if="isInitialized">
            <div class="w-full md:w-8/12">
                <ProgressBar
                    v-if="wizardStore.currentStepIndex >= 0"
                    class="pt-2"
                    :current-step-index="wizardStore.currentStepIndex"
                    :selected-steps-definitions="
                        wizardStore.selectedStepsDefinitions
                    "
                    :can-go-forward="wizardStore.canGoForward"
                    :can-go-backward="wizardStore.canGoBackward"
                    @change-current-step="wizardStore.goToStep"
                />
                <div class="mt-5 w-full">
                    <StepBasicInfo
                        v-if="wizardStore.currentStepKey === 'StepBasicInfo'"
                        :campaign-id="props.campaignId"
                    />
                    <StepCustomQuestions
                        v-if="
                            wizardStore.currentStepKey === 'StepCustomQuestions'
                        "
                        :campaign-id="
                            props.campaignId || crudStore.itemData!.id
                        "
                    />
                    <StepInterpellations
                        v-if="
                            wizardStore.currentStepKey === 'StepInterpellations'
                        "
                        :campaign-id="
                            props.campaignId || crudStore.itemData!.id
                        "
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'
import StepBasicInfo from './StepBasicInfo/StepBasicInfo.vue'
import StepInterpellations from './StepInterpellations/StepInterpellations.vue'
import {
    useCampaignBackofficeCrudStore,
    useCampaignCreateUpdateWizardStore,
} from '../stores'
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import StepCustomQuestions from './StepCustomQuestions/StepCustomQuestions.vue'
import { onBeforeMountOrPropChange } from '@/utils/components'

interface Props {
    campaignId?: string
}

const props = defineProps<Props>()

const crudStore = useCampaignBackofficeCrudStore()
const wizardStore = useCampaignCreateUpdateWizardStore()
const isInitialized = ref(false)

/**
 * In case we navigate from campaign edit to campaign create directly
 * the component is not unmounted and mounted again, so we need to reset it.
 */
const resetComponent = async () => {
    isInitialized.value = false
    crudStore.resetItemState()
    wizardStore.$reset(
        ['StepBasicInfo', 'StepCustomQuestions', 'StepInterpellations'],
        'StepBasicInfo',
        !props.campaignId
    )
    if (props.campaignId) {
        await crudStore.runRead(props.campaignId)
    }
    setTimeout(() => {
        isInitialized.value = true
    }, 0)
}

onBeforeMountOrPropChange(() => props.campaignId, resetComponent)
</script>
