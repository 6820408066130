<template>
    <span class="cursor">▒</span>
</template>
<style scoped>
.cursor {
    margin-left: 0.05em;
    animation:
        typing 3.5s steps(40, end),
        blink-caret 0.75s step-end infinite;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    from,
    to {
        opacity: 0;
    }
    50% {
        opacity: 0.5;
    }
}
</style>
