<template>
    <div>
        <LoaderWithOverlay v-if="crudStore.isBusy" />

        <div class="container mx-auto px-4 py-8">
            <RouterView></RouterView>
            <ListHeader
                titleTKey="backoffice.rlists.rlists"
                descriptionTKey="backoffice.rlists.description"
                createActionTKey="backoffice.actions.create"
                createRouteName="rlist_create"
            />
            <div v-if="crudStore.isListDataEmpty">
                <ListEmptyLayout routeName="rlist_create">
                    <template #no-element-msg>
                        <p>You have no created any lists yet</p>
                    </template>
                    <template #create-element-msg>
                        Create your first list
                    </template>
                </ListEmptyLayout>
            </div>
            <div v-else>
                <PaginationAndFilter
                    v-if="crudStore.listMeta"
                    :list-config="crudStore.listConfig"
                    :list-filters="crudStore.listFilters"
                    :list-meta="crudStore.listMeta"
                    route-name="rlists_list"
                />
                <div
                    class="container py-2 w-full"
                    v-for="rlist in crudStore.listData"
                    :key="rlist.id"
                >
                    <div class="bg-white py-6 rounded-lg shadow-sm">
                        <div class="overflow-x-auto">
                            <div class="flex justify-between items-center p-4">
                                <div>
                                    <h2 class="text-xl font-bold text-gray-800">
                                        {{ rlist.name }}
                                    </h2>
                                    <p class="text-gray-500">
                                        {{
                                            t(
                                                'backoffice.rlists.recipientsCount',
                                                rlist.recipients
                                                    ? rlist.recipients.length
                                                    : 0
                                            )
                                        }}
                                    </p>
                                </div>

                                <div class="flex items-center space-x-4">
                                    <button
                                        @click="
                                            extraStore.downloadListAsCsv(
                                                rlist.id,
                                                rlist.name
                                            )
                                        "
                                    >
                                        <i
                                            class="fi fi-rs-download w-3 h-3 mr-1 text-xs"
                                        ></i>
                                        {{ t('backoffice.actions.download') }}
                                    </button>
                                    <RouterLink
                                        :to="{
                                            name: 'rlist_update_inPlace',
                                            params: { rlistId: rlist.id },
                                        }"
                                        class="flex items-center text-primary hover:text-blue-700 text-sm"
                                    >
                                        <i
                                            class="fi fi-rs-pencil mr-2 block translate-y-0.5"
                                        ></i
                                        >{{ t('backoffice.actions.edit') }}
                                    </RouterLink>
                                    <RouterLink
                                        :to="{
                                            name: 'rlist_delete_inPlace',
                                            params: { rlistId: rlist.id },
                                        }"
                                        class="flex items-center text-red-600 hover:text-red-700 text-sm"
                                    >
                                        <i
                                            class="fi fi-rs-trash mr-2 block translate-y-0.5"
                                        ></i
                                        >{{ t('backoffice.actions.delete') }}
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import ListEmptyLayout from '@/AppBackoffice/components/ListEmptyLayout.vue'
import ListHeader from '@/AppBackoffice/components/ListHeader.vue'
import { onBeforeMountOrPropChange } from '@/utils/components'
import PaginationAndFilter from '@/AppBackoffice/components/PaginationAndFilter.vue'
import { RouterLink, RouterView } from 'vue-router'
import { useRListsBackofficeCrudStore } from './stores'
import { useI18n } from 'vue-i18n'
import { useRecipientsExtraStore } from '../recipients/stores'

const extraStore = useRecipientsExtraStore()

interface Props {
    page: number
    filter?: string
}

const props = defineProps<Props>()
const crudStore = useRListsBackofficeCrudStore()
const { t } = useI18n()

const onListConfigUpdated = () => {
    crudStore.runList({
        page: props.page,
        filter: props.filter,
    })
}

onBeforeMountOrPropChange(
    [() => props.page, () => props.filter],
    onListConfigUpdated
)
</script>
