<template>
    <button
        v-if="type !== 'link'"
        :class="buttonClassObject"
        :type="type"
        :disabled="disabled"
    >
        <slot></slot>
    </button>
    <a
        v-else
        :class="buttonClassObject"
        :type="type"
        :disabled="disabled"
        :href="href"
        target="_blank"
        rel="noopener noreferrer"
    >
        <slot></slot>
    </a>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
    buttonType?:
        | 'primary'
        | 'outline-primary'
        | 'accent'
        | 'secondary'
        | 'outline-danger'
        | 'link'
    type?: 'button' | 'submit' | 'link'
    disabled?: boolean
    href?: string
}

const props = withDefaults(defineProps<Props>(), {
    buttonType: 'primary',
    type: 'button',
    disabled: false,
})

const buttonClassObject = computed(() => {
    return {
        'hover:opacity:90 font-medium rounded-lg text-[15px] px-8 py-3 focus:outline-none':
            props.buttonType !== 'link',
        'focus:ring-4 focus:ring-blue-300 font-medium rounded-lg focus:outline-none':
            props.buttonType === 'primary',
        'bg-primary text-white':
            props.buttonType === 'primary' && !props.disabled,
        'text-center border font-semibold text-primary rounded-lg shadow-sm bg-transparent':
            props.buttonType === 'outline-primary',
        'text-primary border-dark-gray font-semibold text-primary rounded-lg shadow-sm hover:bg-blue-100 bg-transparent':
            props.buttonType === 'outline-primary' && !props.disabled,
        'text-grey border-bbb':
            props.buttonType === 'outline-primary' && props.disabled,
        'bg-secondary hover:bg-secondary hover:opacity-90':
            props.buttonType === 'accent',
        'bg-secondary hover:bg-secondary hover:opacity-90 text-gray-900':
            props.buttonType === 'secondary',
        'text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-center':
            props.buttonType === 'outline-danger',
        'border-none bg-none underline': props.buttonType === 'link',
        'text-primary': props.buttonType === 'link' && !props.disabled,
        'text-neutral-500': props.buttonType === 'link' && props.disabled,
        'bg-primary-disabled text-neutral-500':
            props.disabled &&
            ['primary', 'outline-primary'].includes(props.buttonType),
    }
})
</script>
