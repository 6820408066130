<template>
    <AVModalWithRouter
        :route-name-on-close="props.routeNameOnClose"
        title-t-key="backoffice.recipients.newRecipient"
    >
        <template v-slot:content>
            <p class="text-gray-600 mb-6">Only email is a required field</p>
            <RecipientForm
                submit-t-key="backoffice.actions.create"
                route-name-on-close="recipients_list"
                :validation-errors="crudStore.itemValidationErrors"
                @submit="onSubmitClicked"
            />
        </template>
    </AVModalWithRouter>
</template>

<script setup lang="ts">
import AVModalWithRouter from '@/components/AVModalWithRouter.vue'
import RecipientForm from './RecipientForm.vue'
import { onBeforeMount } from 'vue'
import { useRecipientsBackofficeCrudStore } from './stores'
import { RecipientWrite } from '@/models/recipients'
import { RouteName } from '@/types'

interface Props {
    routeNameOnClose: RouteName
}

const props = defineProps<Props>()
const crudStore = useRecipientsBackofficeCrudStore()

onBeforeMount(crudStore.resetItemValidationError)

const onSubmitClicked = async (newRecipient: RecipientWrite) => {
    await crudStore.runCreate(newRecipient, {
        nextRouteName: props.routeNameOnClose,
        message: 'backoffice.recipients.messageCreated',
    })
    crudStore.runList()
}
</script>

<style scoped></style>
