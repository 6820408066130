<template>
    <div class="input-group" :class="{ 'has-errors': errorTKey }">
        <label class="label" v-if="label">{{ label }}</label>

        <div
            class="button-group"
            :class="{
                'flex flex-col': props.vertical,
            }"
        >
            <span
                class="options"
                v-for="(option, index) in options"
                :key="index"
                :class="{
                    'bg-white text-vanilla': !isOptionSelected(option.value),
                    'bg-primary text-white': isOptionSelected(option.value),
                }"
                @click="onSelectOption(option.value)"
            >
                {{ option.label }}
            </span>
        </div>
        <AVError v-if="errorTKey" :error-t-key="errorTKey" />
    </div>
</template>

<script setup lang="ts" generic="T, AT extends T | Array<T>">
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'
import { watch } from 'vue'
import { Ref, ref } from 'vue'
import AVError from '@/components/forms/AVError.vue'

interface Props extends FormFieldProps<AT> {
    options: Array<{ value: T; label: string }>
    multiple?: boolean
    vertical?: boolean
    compareFunction?: (a: T, b: T) => boolean
}

interface Emits extends FormFieldEmits<AT> {}

const props = withDefaults(defineProps<Props>(), {
    multiple: false,
    vertical: false,
    compareFunction: (a: T, b: T) => a === b,
})

const emit = defineEmits<Emits>()

// Need to cast as `Ref` because :
// SEE : https://github.com/vuejs/core/issues/2136
const selectedValues = ref<Array<T>>(props.modelValue as Array<T>) as Ref<
    Array<T>
>

const isOptionSelected = (value: T) => {
    if (props.multiple) {
        return selectedValues.value.some((v) => props.compareFunction(v, value))
    } else {
        return props.compareFunction(props.modelValue as T, value)
    }
}

const onSelectOption = (value: T) => {
    if (props.multiple) {
        const index = selectedValues.value.findIndex((other) =>
            props.compareFunction(other, value)
        )
        if (index > -1) {
            selectedValues.value.splice(index, 1)
        } else {
            selectedValues.value.push(value)
        }
        emit('update:modelValue', selectedValues.value as AT)
    } else {
        emit('update:modelValue', value as AT)
    }
}

// Not sure whether that's the right way to validate the model value
watch(
    () => props.modelValue,
    (newValue) => {
        if (props.multiple && !Array.isArray(newValue)) {
            throw new Error(
                'Model value must be an array when multiple is true'
            )
        }
    }
)
</script>

<style scoped>
.input-group {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
}

select {
    width: auto;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    border-radius: 0.375rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    max-width: 100%;
}

select:focus,
select:focus-visible {
    color: var(--color-blue-gray);
    background-color: var(--color-white);
    border-color: var(--color-dark-gray-2);
}

label {
    display: block;
    color: var(--color-blue-gray);
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 1.3em;
}

.error-message {
    margin-top: 2px;
}

.options {
    padding: 8px 12px;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid #eee;
    display: inline-block;
}

.bg-white.options:hover {
    background-color: var(--color-white-hover);
}

.has-errors .options {
    border: 1px solid var(--color-error);
}

.has-errors label {
    color: var(--color-error);
}

@media only screen and (max-width: 900px) {
    .input-group {
        font-size: 1em;
    }
}
</style>
