<template>
    <AVModal @closeModal="emit('closeModal')">
        <template #content>
            <div class="py-4 mb-4">
                {{
                    t(`embed.actions.${store.interpellationMode}.action_opened`)
                }}
            </div>
            <div
                class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 justify-end"
            >
                <AVButton
                    class="w-full md:w-auto flex-1"
                    @click="onWasSentClicked"
                >
                    {{ t(`embed.actions.${store.interpellationMode}.sent`) }}
                </AVButton>
                <AVButton
                    class="w-full md:w-auto flex-1"
                    button-type="outline-primary"
                    @click="onMarkNotSent"
                >
                    {{
                        t(`embed.actions.${store.interpellationMode}.not_sent`)
                    }}
                </AVButton>
            </div>
        </template>
    </AVModal>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import AVModal from '@/components/AVModal.vue'
import AVButton from '@/components/forms/AVButton.vue'
import { useSendCampaignStore } from '@/AppCampaigns/stores'

interface Emits {
    (event: 'closeModal'): void
}

const emit = defineEmits<Emits>()

const { t } = useI18n()
const store = useSendCampaignStore()

const onWasSentClicked = () => {
    store.markContentAsSent()
    store.setIsSendDone(true)
}

const onMarkNotSent = () => {
    emit('closeModal')
}
</script>
