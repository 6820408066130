import { components } from '@/schema'
import * as Yup from 'yup'
import { recipientSimpleValidator } from './recipients'

export type RList = components['schemas']['RecipientList']

export type RListSimple = components['schemas']['RecipientListSimple']

export type RListPublic = components['schemas']['RecipientListPublic']

export type RListWrite = Omit<RList, 'id' | 'updated_date'>

export const rlistValidationBase = {
    id: Yup.string().optional(),
    name: Yup.string().required(),
}

export const rlistSimpleValidator: Yup.ObjectSchema<RListSimple> =
    Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
        recipients: Yup.array().of(recipientSimpleValidator).required(),
        geolocation_available: Yup.boolean().default(false),
    })
