<template>
    <AVModalWithRouter
        :route-name-on-close="props.routeNameOnClose"
        title-t-key="backoffice.rlists.new"
    >
        <template v-slot:content>
            <p class="text-gray-600 mb-6">
                Create new lists to organize your recipients.
            </p>
            <RListForm
                submit-t-key="backoffice.actions.create"
                route-name-on-close="rlists_list"
                :validation-errors="crudStore.itemValidationErrors"
                @submit="onSubmitClicked"
            />
        </template>
    </AVModalWithRouter>
</template>

<script setup lang="ts">
import AVModalWithRouter from '@/components/AVModalWithRouter.vue'
import RListForm from './RListForm.vue'
import { useRListsBackofficeCrudStore } from './stores'
import { RListWrite } from '@/models/rlists'
import { RouteName } from '@/types'
import { onBeforeMount } from 'vue'

interface Props {
    routeNameOnClose: RouteName
}

const props = defineProps<Props>()

const crudStore = useRListsBackofficeCrudStore()

onBeforeMount(crudStore.resetItemValidationError)

const onSubmitClicked = async (newRList: RListWrite) => {
    await crudStore.runCreate(newRList, {
        nextRouteName: props.routeNameOnClose,
        message: 'backoffice.rlists.messageCreated',
    })
    crudStore.runList()
}
</script>

<style scoped></style>
