import { API_ROOT_URL } from '@/constants'
import { CampaignPublic } from '@/models/campaigns'
import { ModelId } from '@/models/types'
import axios, { AxiosResponse } from 'axios'

export const readCampaignPublic = (
    campaignId: ModelId
): Promise<AxiosResponse<CampaignPublic>> => {
    return axios.get(`${API_ROOT_URL}/api/campaigns/${campaignId}/`)
}
