<template>
    <form @submit.prevent="onSubmit" class="mt-4">
        <div class="mb-4 w-1/2">
            <AVInput
                :label="t('backoffice.organizations.name_field')"
                v-bind="nameField.props.value"
                v-on="nameField.listeners.value"
                :placeholder="t('backoffice.organizations.namePlaceholder')"
            />
        </div>
        <div class="mb-4">
            <AVCheckBox
                :label="t('backoffice.organizations.isTrackingEmails')"
                v-bind="isTrackingEmailsField.props.value"
                v-on="isTrackingEmailsField.listeners.value"
            />
        </div>
        <div class="mb-4 w-1/2">
            <AVInput
                :label="t('backoffice.organizations.openai_api_key_field')"
                v-bind="openaiApiKeyField.props.value"
                v-on="openaiApiKeyField.listeners.value"
                :placeholder="
                    t('backoffice.organizations.openaiApiKeyPlaceholder')
                "
            />
        </div>

        <div class="mb-4 w-full md:w-40">
            <AVInput
                :label="'Primary color'"
                v-bind="colorPrimaryField.props.value"
                v-on="colorPrimaryField.listeners.value"
                :placeholder="t('backoffice.organizations.namePlaceholder')"
                type="color"
            />
        </div>

        <div class="mb-4 w-full md:w-40">
            <AVInput
                :label="'Secondary color'"
                v-bind="colorSecondaryField.props.value"
                v-on="colorSecondaryField.listeners.value"
                :placeholder="t('backoffice.organizations.namePlaceholder')"
                type="color"
            />
        </div>

        <div class="mb-4 w-full md:w-40">
            <AVInput
                :label="'Accent color'"
                v-bind="colorAccentField.props.value"
                v-on="colorAccentField.listeners.value"
                :placeholder="t('backoffice.organizations.namePlaceholder')"
                type="color"
            />
        </div>
        <div class="mb-4 w-full md:w-1/2">
            <AVFileDropZone
                name="logo_organization"
                :label="t('backoffice.organizations.logo')"
                :fileTypes="['svg', 'png', 'jpg']"
                :currentFileUrl="
                    myOrganizationStore.myOrganization?.logo || undefined
                "
                v-model="newLogo"
                :isImageFile="true"
                @clear="clearLogo"
            />
        </div>
        <AVButton type="submit">
            {{ t(submitTKey) }}
        </AVButton>
    </form>
</template>

<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/yup'
import AVInput from '@/components/forms/AVInput.vue'
import AVCheckBox from '@/components/forms/AVCheckBox.vue'
import { useMyOrganizationStore } from './stores'
import { computed, DeepReadonly, ref } from 'vue'
import AVButton from '@/components/forms/AVButton.vue'
import { organizationValidaton } from './validation'
import { useForm } from 'vee-validate'
import { makeUseField } from '@/utils/forms'
import { OrganizationWrite, Organization } from '@/models/organizations'
import { useI18n } from 'vue-i18n'
import { TKey } from '@/i18n'
import { ValidationErrors } from '@/utils/validation-errors'
import { onBeforeMountOrPropChange } from '@/utils/components'
import AVFileDropZone from '@/components/forms/AVFileDropZone.vue'

interface Props {
    organization?: Organization
    submitTKey: TKey
    validationErrors: DeepReadonly<ValidationErrors<OrganizationWrite>> | null
}

interface Emits {
    (event: 'submit', organization: OrganizationWrite): void
    (event: 'cancel'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()

const validationSchema = computed(() => toTypedSchema(organizationValidaton))

const myOrganizationStore = useMyOrganizationStore()

const { validate, setErrors } = useForm({
    validationSchema,
    initialValues: myOrganizationStore.myOrganization || {},
})

const setValidationErrors = () => {
    if (props.validationErrors) {
        setErrors(props.validationErrors)
    }
}

const newLogo = ref<File | null>(null)
const clearLogo = () => {
    newLogo.value = new File([], '')
}

const useOrganizationField = makeUseField<OrganizationWrite>()
const nameField = useOrganizationField('name')
const openaiApiKeyField = useOrganizationField('openai_api_key')
const isTrackingEmailsField = useOrganizationField('is_tracking_sent_emails')
const colorPrimaryField = useOrganizationField('color_primary')
const colorSecondaryField = useOrganizationField('color_secondary')
const colorAccentField = useOrganizationField('color_accent')

onBeforeMountOrPropChange(() => props.validationErrors, setValidationErrors)

const onSubmit = async () => {
    const { valid: isValid, values } = await validate()
    if (isValid) {
        if (newLogo.value instanceof File) {
            await myOrganizationStore.updateLogo(newLogo.value)
        }
        emit('submit', values!)
    }
}
</script>
