<template>
    <div class="mt-2 mb-2 md:max-w-[60%]">
        {{ t(instructionsTKey) }}
    </div>
</template>
<script setup lang="ts">
import { TKey } from '@/i18n'
import { useI18n } from 'vue-i18n'

interface Props {
    instructionsTKey: TKey
}

defineProps<Props>()

const { t } = useI18n()
</script>
