<template>
    <div>
        <div class="font-bold">
            {{ t('backoffice.contentSamples.email.sampleTitle') }}
        </div>
        <div class="font-semibold">
            {{ t('backoffice.contentSamples.emailSubjectLabel') }}
        </div>
        <div class="">
            {{ contentSample.subject }}
        </div>
        <div class="font-semibold">
            {{ t('backoffice.contentSamples.emailBodyLabel') }}
        </div>
        <div class="">
            {{ contentSample.body }}
        </div>
    </div>
</template>
<script setup lang="ts">
import { ContentSampleEmail } from '@/models/content-samples'
import { useI18n } from 'vue-i18n'

interface Props {
    contentSample: ContentSampleEmail
}
defineProps<Props>()
const { t } = useI18n()
</script>
