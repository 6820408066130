<template>
    <div
        class="lds-ripple"
        :style="{ width: size + 'px', height: size + 'px' }"
    >
        <div :style="{ border: loaderColor }"></div>
        <div :style="{ border: loaderColor, animationDelay: '-0.5s' }"></div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
    color?: string
    size?: number
}

const props = withDefaults(defineProps<Props>(), {
    color: 'var(--color-accent)',
    size: 80,
})

const loaderColor = computed(() => `4px solid ${props.color}`)
</script>

<style scoped>
.lds-ripple {
    display: inline-block;
    position: relative;
}

.lds-ripple div {
    position: absolute;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-ripple {
    0% {
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
</style>
