<template>
    <div>
        <div class="text-xl font-bold mb-2">
            {{
                t(`backoffice.contentSamples.${contentSampleType}.samplesTitle`)
            }}
        </div>
        <div
            class="text-sm py-4 text-warning"
            v-if="filteredContentSamples.length === 0"
        >
            {{
                t('backoffice.contentSamples.interpellationModeDisabled', {
                    modes: interpellationModes
                        .filter(
                            (mode) =>
                                selectContentSampleTypeForInterpellationMode(
                                    mode
                                ) === contentSampleType
                        )
                        .join(', '),
                })
            }}
        </div>
        <ContainerBoxShadow>
            <div
                v-for="(
                    contentSampleIndex, filteredListIndex
                ) in filteredContentSamples"
                :key="contentSampleIndex"
            >
                <div class="mb-2 flex flex-row justify-between">
                    <div class="text-md font-bold">
                        {{
                            t(
                                `backoffice.contentSamples.${contentSampleType}.sampleTitle`
                            )
                        }}
                        {{ filteredListIndex + 1 }}
                    </div>
                    <div
                        v-if="
                            (contentSamplesField.value.value || []).length > 1
                        "
                        @click="onRemoveContentSample(contentSampleIndex)"
                        class="text-primary text-xs cursor-pointer"
                    >
                        <span class="underline inline-block -translate-y-0.5"
                            >Remove sample</span
                        >
                        <i class="fi fi-rs-trash"></i>
                    </div>
                </div>
                <div class="w-full md:w-8/12">
                    <ContentSampleEmailFields
                        v-if="contentSampleType === 'email'"
                        :content-sample-path="`${interpellationPath}.content_samples.${contentSampleIndex}`"
                    />
                    <ContentSampleCommentFields
                        v-else-if="contentSampleType === 'comment'"
                        :content-sample-path="`${interpellationPath}.content_samples.${contentSampleIndex}`"
                    />
                    <ContentSampleTweetFields
                        v-else-if="contentSampleType === 'tweet'"
                        :content-sample-path="`${interpellationPath}.content_samples.${contentSampleIndex}`"
                    />
                </div>
            </div>
            <AVButton @click="onAddContentSample" button-type="link">
                <i class="fi fi-rs-add text-primary"></i>
                {{
                    t(
                        `backoffice.contentSamples.${contentSampleType}.addSample`
                    )
                }}
            </AVButton>
        </ContainerBoxShadow>
    </div>
</template>
<script setup lang="ts">
import { CampaignWrite, InterpellationMode } from '@/models/campaigns'
import {
    ContentSample,
    defaultContentSampleComment,
    defaultContentSampleEmail,
    defaultContentSampleTweet,
} from '@/models/content-samples'
import ContainerBoxShadow from '@/AppBackoffice/components/ContainerBoxShadow.vue'
import ContentSampleEmailFields from './ContentSampleEmailFields.vue'
import ContentSampleCommentFields from './ContentSampleCommentFields.vue'
import ContentSampleTweetFields from './ContentSampleTweetFields.vue'
import AVButton from '@/components/forms/AVButton.vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { selectContentSampleTypeForInterpellationMode } from '@/models/selectors'
import { InterpellationWrite } from '@/models/interpellations'
import { makeUseField } from '@/utils/forms'

interface Props {
    contentSampleType: ContentSample['type']
    interpellationModes: Array<InterpellationMode>
    interpellationPath: `interpellations.${number}`
}

const props = defineProps<Props>()

const useInterpellationField = makeUseField<CampaignWrite, InterpellationWrite>(
    props.interpellationPath
)
const contentSamplesField = useInterpellationField('content_samples')
const { t } = useI18n()

const filteredContentSamples = computed(() =>
    (contentSamplesField.value.value || [])
        .map((contentSample, contentSampleIndex) => ({
            contentSample,
            contentSampleIndex,
        }))
        .filter(
            ({ contentSample }) =>
                contentSample.type === props.contentSampleType
        )
        .map(({ contentSampleIndex }) => contentSampleIndex)
)

const onAddContentSample = () => {
    contentSamplesField.value.value = contentSamplesField.value.value || []
    if (props.contentSampleType === 'email') {
        contentSamplesField.value.value.push(defaultContentSampleEmail())
    } else if (props.contentSampleType === 'comment') {
        contentSamplesField.value.value.push(defaultContentSampleComment())
    } else if (props.contentSampleType === 'tweet') {
        contentSamplesField.value.value.push(defaultContentSampleTweet())
    }
}

const onRemoveContentSample = (contentSampleIndex: number) => {
    contentSamplesField.value.value!.splice(contentSampleIndex, 1)
}
</script>
