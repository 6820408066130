<template>
    <form @submit.prevent="onSubmit">
        <AVInput
            :label="t('backoffice.rlists.nameForm')"
            v-bind="nameField.props.value"
            v-on="nameField.listeners.value"
            :placeholder="t('backoffice.rlists.nameFormPlaceholder')"
            class="w-full mb-4"
        />

        <RecipientPicker
            v-bind="recipientsField.props.value"
            v-on="recipientsField.listeners.value"
            label="Recipients"
            placeholder="Select recipients"
        />

        <div class="flex justify-between items-end">
            <AVButton
                type="button"
                button-type="accent"
                @click="router.push({ name: 'rlist_upload' })"
            >
                {{ t('backoffice.rlists.upload') }}
            </AVButton>
            <ModalButtons
                :ok-t-key="props.submitTKey"
                :route-name-on-close="props.routeNameOnClose"
                ok-button-type="submit"
                class="mt-5"
            />
        </div>
    </form>
</template>
<script setup lang="ts">
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import { onBeforeMountOrPropChange } from '@/utils/components'
import { toTypedSchema } from '@vee-validate/yup'
import ModalButtons from '@/AppBackoffice/components/ModalButtons.vue'
import { RList, RListWrite } from '@/models/rlists'
import { useI18n } from 'vue-i18n'
import AVInput from '@/components/forms/AVInput.vue'
import { RouteName } from '@/types'
import { ValidationErrors } from '@/utils/validation-errors'
import RecipientPicker from '@/AppBackoffice/components/RecipientPicker/RecipientPicker.vue'
import { makeUseField } from '@/utils/forms'
import { TKey } from '@/i18n'
import { rlistValidator } from './validation'
import AVButton from '@/components/forms/AVButton.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

interface Props {
    initialValue?: RList
    routeNameOnClose: RouteName
    submitTKey: TKey
    validationErrors: ValidationErrors<RList> | null
}

interface Emits {
    (event: 'submit', rlist: RListWrite): void
    (event: 'cancel'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()
const validationSchema = computed(() => toTypedSchema(rlistValidator))

const { validate, setErrors } = useForm({
    validationSchema,
    initialValues: props.initialValue || {},
})

const useRListField = makeUseField<RListWrite>()
const nameField = useRListField('name')
const recipientsField = useRListField('recipients')

const onSubmit = async () => {
    const { valid: isValid, values } = await validate()
    if (isValid) {
        emit('submit', values!)
    }
}

const setValidationErrors = () => {
    if (props.validationErrors) {
        setErrors(props.validationErrors)
    }
}

onBeforeMountOrPropChange(() => props.validationErrors, setValidationErrors)
</script>
