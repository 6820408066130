import { StepDefinition } from '@/components/ProgressBar/types'
import { defineStore } from 'pinia'
import { UnwrapRef, computed, ref } from 'vue'
import { castToDeepReadOnly } from '@/utils/types'

export const defineWizardStore = <
    StepDefinitions extends { [key: string]: StepDefinition },
>(
    name: string,
    stepDefinitions: StepDefinitions
) =>
    defineStore(name, () => {
        type StepKey = keyof StepDefinitions

        // STATE
        const currentStepIndex = ref<number>(0)
        const selectedStepsKeys = ref<Array<StepKey>>([])
        const isNewWizard = ref<boolean>(true) // If the wizard is for a new entity, no possibility to go forward without validation
        const isFormDirty = ref<boolean>(false) // If the form on the current step of the wizard is dirty

        // ACTIONS
        const setDirtyState = (isDirty: boolean) => {
            isFormDirty.value = isDirty
        }

        const setSelectedStepsKeys = (
            stepsKeys: Array<StepKey>,
            currentStepKey: StepKey | null = null
        ) => {
            if (currentStepKey !== null) {
                const _currentStepIndex = stepsKeys.indexOf(currentStepKey)
                if (_currentStepIndex === -1) {
                    throw new Error('Current step key not found in stepsKeys')
                }
                currentStepIndex.value = _currentStepIndex
            } else {
                currentStepIndex.value = 0
            }
            selectedStepsKeys.value = stepsKeys as UnwrapRef<Array<StepKey>>
        }

        const goToNextStep = () => {
            goToStep(currentStepIndex.value + 1)
        }

        const goToPreviousStep = () => {
            goToStep(currentStepIndex.value - 1)
        }

        const goToStep = (stepIndex: number) => {
            if (stepIndex >= 0 && stepIndex < selectedStepsKeys.value.length) {
                currentStepIndex.value = stepIndex
            }
        }

        /**
         * @param stepsKeys reinitialize the keys of the steps in the wizard
         * @param currentStepKey reinitialize the current step by using its key
         */
        const $reset = (
            stepsKeys: Array<StepKey> | null = null,
            currentStepKey: StepKey | null = null,
            isNewWizardValue: boolean | null = null
        ) => {
            setSelectedStepsKeys(
                stepsKeys !== null
                    ? stepsKeys
                    : (selectedStepsKeys.value as Array<StepKey>),
                currentStepKey
            )
            isNewWizard.value =
                isNewWizardValue !== null ? isNewWizardValue : isNewWizard.value
            isFormDirty.value = false
        }

        // GETTERS
        const canGoForward = computed<boolean>(() => {
            return (
                currentStepIndex.value < selectedStepsKeys.value.length - 1 &&
                !isFormDirty.value &&
                !isNewWizard.value
            )
        })
        const canGoBackward = computed<boolean>(() => {
            return currentStepIndex.value > 0 && !isFormDirty.value
        })
        const currentStepKey = computed<StepKey | null>(() =>
            currentStepIndex.value < 0
                ? null
                : (selectedStepsKeys.value[currentStepIndex.value] as StepKey)
        )

        const isAtLastStep = computed<boolean>(
            () => currentStepIndex.value === selectedStepsKeys.value.length - 1
        )

        const selectedStepsDefinitions = computed<Array<StepDefinition>>(() =>
            selectedStepsKeys.value.map(
                (stepId) =>
                    (stepDefinitions as StepDefinitions)[stepId as StepKey]
            )
        )

        return castToDeepReadOnly({
            // Export state
            currentStepIndex,
            selectedStepsKeys,
            isFormDirty,
            isNewWizard,
            // Export actions
            goToNextStep,
            goToPreviousStep,
            goToStep,
            setDirtyState,
            setSelectedStepsKeys,
            $reset,
            // Export getters
            canGoForward,
            canGoBackward,
            currentStepKey,
            isAtLastStep,
            selectedStepsDefinitions,
        })
    })
