import RecipientsListView from './RecipientsListView.vue'
import RecipientCreateModal from './RecipientCreateModal.vue'
import RecipientReadView from './RecipientReadView.vue'
import RecipientUpdateModal from './RecipientUpdateModal.vue'
import RecipientDeleteModal from './RecipientDeleteModal.vue'
import { RouteRecordRaw } from 'vue-router'
import {
    propagatePreviousRouteQuery,
    routeToListViewProps,
} from '../utils/routes'

const recipientsRoutes: Array<RouteRecordRaw> = [
    {
        path: 'recipients',
        name: 'recipients_list',
        component: RecipientsListView,
        props: routeToListViewProps,
        children: [
            {
                path: 'create',
                name: 'recipient_create',
                component: RecipientCreateModal,
                props: { routeNameOnClose: 'recipients_list' },
            },
            {
                path: 'delete/:recipientId',
                name: 'recipient_delete_inPlace',
                component: RecipientDeleteModal,
                props: (route) => ({
                    recipientId: route.params.recipientId,
                    routeNameOnClose: 'recipients_list',
                }),
                beforeEnter: [propagatePreviousRouteQuery],
            },
            {
                path: 'update/:recipientId',
                name: 'recipient_update_inPlace',
                component: RecipientUpdateModal,
                props: (route) => ({
                    recipientId: route.params.recipientId,
                    routeNameOnClose: 'recipients_list',
                }),
                beforeEnter: [propagatePreviousRouteQuery],
            },
        ],
    },
    {
        path: 'recipients/:recipientId',
        name: 'recipients_read',
        component: RecipientReadView,
        props: true,
        children: [
            {
                path: 'delete',
                name: 'recipient_delete',
                component: RecipientDeleteModal,
                props: (route) => ({
                    recipientId: route.params.recipientId,
                    routeNameOnClose: 'recipients_list',
                }),
            },
            {
                path: 'update',
                name: 'recipient_update',
                component: RecipientUpdateModal,
                props: (route) => ({
                    recipientId: route.params.recipientId,
                    routeNameOnClose: 'recipients_read',
                }),
            },
        ],
    },
]

export default recipientsRoutes
