<template>
    <div class="dot-flashing"></div>
</template>

<style scoped>
/* REF : https://codepen.io/nzbin/pen/GGrXbp */
.dot-flashing,
.dot-flashing::before,
.dot-flashing::after {
    --animation-duration: 0.5s;
    --dot-size: 5px;
    --spacing: 11px;
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: calc(var(--dot-size) / 2);
    background-color: var(--color-vanilla);
    animation: dot-flashing var(--animation-duration) infinite linear alternate;
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing {
    margin-left: calc(var(--spacing) + var(--dot-size) / 2 + 0.25em);
    display: inline-block;
    position: relative;
    animation-delay: calc(var(--animation-duration) / 3);
}
.dot-flashing::before {
    left: calc(var(--spacing) * -1);
    animation-delay: 0s;
}
.dot-flashing::after {
    left: calc(var(--spacing));
    animation-delay: calc(var(--animation-duration) / 1.5);
}

@keyframes dot-flashing {
    0% {
        background-color: var(--color-vanilla);
    }
    80%,
    100% {
        background-color: rgba(var(--color-vanilla), 0.2);
    }
}
</style>
