import { loadWebFonts } from '@/services/webfonts'
import { loadColors } from '@/services/colors'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useInitAppStore = defineStore('InitAppStore', () => {
    // STATE
    const isComplete = ref(false)

    // ACTIONS
    const start = async () => {
        if (isComplete.value === true) {
            return
        }
        await loadColors()
        await loadWebFonts()
        isComplete.value = true
    }

    return {
        isComplete,
        start,
    }
})
