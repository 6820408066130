<template>
    <AVModal @close-modal="onCloseModal">
        <template #content>
            <slot name="content"></slot>
        </template>
    </AVModal>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router'
import { RouteName } from '@/types'
import AVModal, {
    Props as AVModalProps,
    Emits as AVModalEmits,
} from './AVModal.vue'

interface Props extends AVModalProps {
    routeNameOnClose?: RouteName
}

interface Emits extends AVModalEmits {
    (event: 'closeModal'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const route = useRoute()
const router = useRouter()

const onCloseModal = () => {
    emit('closeModal')
    if (props.routeNameOnClose) {
        router.push({
            name: props.routeNameOnClose,
            // restore the query when closing the modal
            query: route.query,
        })
    }
}
</script>
