<template>
    <div class="wrapper w-full">
        <ol class="progress-bar flex">
            <li
                v-for="(stepDefinition, stepIndex) in selectedStepsDefinitions"
                :key="stepIndex"
                class="step flex flex-col flex-1 text-center"
                :class="{
                    'step-active': stepIndex === currentStepIndex,
                    'step-completed': stepIndex < currentStepIndex,
                    'cursor-pointer':
                        (stepIndex > currentStepIndex && canGoForward) ||
                        (stepIndex < currentStepIndex && canGoBackward),
                }"
                @click="onChangeCurrentStep(stepIndex)"
            >
                <div
                    class="circle"
                    :class="{
                        'bg-neutral-500': stepIndex > currentStepIndex,
                        'bg-primary': stepIndex <= currentStepIndex,
                        'font-semibold': stepIndex === currentStepIndex,
                        'font-normal': stepIndex !== currentStepIndex,
                    }"
                >
                    <div
                        v-if="
                            !currentStepIndex || stepIndex >= currentStepIndex
                        "
                    >
                        {{ stepIndex + 1 }}
                    </div>
                    <div v-else><i class="fi fi-rs-check text-lg"></i></div>
                </div>
                <p class="step-title">{{ t(stepDefinition.title) }}</p>
            </li>
        </ol>
    </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { StepDefinition } from './types'

interface Props {
    currentStepIndex: number
    selectedStepsDefinitions: Readonly<Array<StepDefinition>>
    canGoForward: boolean
    canGoBackward: boolean
}

interface Emits {
    (event: 'change-current-step', stepIndex: number): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()

const onChangeCurrentStep = (stepIndex: number) => {
    if (
        (stepIndex > props.currentStepIndex && props.canGoForward) ||
        (stepIndex < props.currentStepIndex && props.canGoBackward)
    ) {
        emit('change-current-step', stepIndex)
    }
}
</script>

<style scoped>
.progress-bar {
    --circle-size: 32px;
    --spacing: clamp(0rem, 0vw, 0rem);
}

.circle {
    content: '';
    display: block;
    width: var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    margin: 0 auto 1rem;
    margin-bottom: 0.3rem;
    font-size: 1rem;
    color: var(--color-white);
    padding-top: 4px;
    z-index: 1;
}

.step:after {
    content: '';
    position: relative;
    top: calc(var(--circle-size) / 2 + 2px);
    width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2) + 4px);
    left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)) - 2px);
    height: 5px;
    order: -1;
    z-index: 0;
}

.step:not(:last-child):after {
    width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2) + 4px);
    left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)) - 2px);
    background-color: var(--color-white);
}

.step.step-completed:not(:last-child):after {
    background-color: var(--color-primary);
}

.step-title {
    color: var(--color-dark-gray);
    font-size: 13px;
    /* font-size: clamp(0.85rem, 2vw, 1rem); */
    padding-left: var(--spacing);
    padding-right: var(--spacing);
}

.step-active .step-title {
    font-weight: 600;
}

.step-active .step-title,
.step-completed .step-title {
    color: var(--color-primary);
}
</style>
