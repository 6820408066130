import { createRouter, createWebHistory } from 'vue-router'
import PageNotFound from '@/components/PageNotFound.vue'
import backofficeEntryPoint from './AppBackoffice/routes'
import campaignsEntryPoint from './AppCampaigns/routes'
import { useI18CustomStore } from './i18n'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/admin',
            children: [backofficeEntryPoint],
            beforeEnter: () => {
                useI18CustomStore().loadTranslations('AppBackoffice')
            },
        },
        {
            path: '',
            children: [campaignsEntryPoint],
            beforeEnter: () => {
                useI18CustomStore().loadTranslations('AppCampaigns')
            },
        },
        {
            path: '/404',
            name: '404',
            component: PageNotFound,
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: '/admin',
        },
    ],
})

export default router
