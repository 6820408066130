import { components } from '@/schema'
import * as Yup from 'yup'
import { ContentSample } from './content-samples'

export type Interpellation = Omit<
    components['schemas']['Interpellation'],
    'content_samples'
> & {
    content_samples: Array<ContentSample>
}

export type InterpellationPublic = Omit<
    components['schemas']['InterpellationPublic'],
    'content_samples'
> & {
    content_samples: Array<ContentSample>
}

export type InterpellationWrite = Omit<
    Interpellation,
    'id' | 'created_date' | 'updated_date'
>

export const interpellationValidationBase = {
    id: Yup.string().optional(),
    name: Yup.string().required(),
}
