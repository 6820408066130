import { defineStore } from 'pinia'
import {
    Campaign,
    CampaignStats,
    CampaignWrite,
    CampaignStatsListItem,
    CampaignInterpellationStatsListItem,
} from '@/models/campaigns'
import { defineWizardStore } from '@/stores/wizard'
import { CREATE_UPDATE_CAMPAIGN_STEP_DEFINITIONS } from './constants'
import { defineCrudStore, defineListOnlyStore } from '@/stores/crud'
import {
    createCampaign,
    deleteCampaign,
    listCampaigns,
    readCampaign,
    updateCampaign,
    readCampaignStats,
    listCampaignsStats,
    duplicateCampaignRequest,
    CampaignListParams,
    CampaignStatsListParams,
    getCampaignPrompt,
    listCampaignStatsByInterpellation,
    updateCampaignImageRequest,
} from './requests'
import { message } from '@/models/types'
import { computed } from 'vue'
import { castToDeepReadOnly } from '@/utils/types'
import { useToastsStore } from '@/stores/toasts'
import { components } from '@/schema'
import { useRequestTracker } from '@/utils/request-tracker'
import { HttpStatusCode } from 'axios'
import { RequestStatus } from '@/types'
import { useRouter } from 'vue-router'

export const useCampaignExtraStore = defineStore(
    'Backoffice:Campaigns:Extra',
    () => {
        const router = useRouter()
        const toastsStore = useToastsStore()
        const promptPreviewRequestTracker = useRequestTracker<Array<message>>()
        const campaignStatsRequestTracker =
            useRequestTracker<Array<CampaignStats>>()
        const campaignInterpellationStatsListRequestTracker =
            useRequestTracker<Array<CampaignInterpellationStatsListItem>>()
        const duplicateCampaignRequestTracker = useRequestTracker()

        // ACTIONS
        const getPromptPreview = async (
            campaignId: string,
            interpellationMode: string,
            params: components['schemas']['PromptPreview']
        ) =>
            promptPreviewRequestTracker.runRequest(
                getCampaignPrompt,
                HttpStatusCode.Ok,
                [campaignId, interpellationMode, params]
            )

        const getCampaignStats = async (campaignId: string) =>
            campaignStatsRequestTracker.runRequest(
                readCampaignStats,
                HttpStatusCode.Ok,
                [campaignId]
            )

        const updateCampaignImage = async (campaignId: string, image: File) => {
            const formData = new FormData()
            if (image.size > 0) {
                formData.append('image', image)
            }
            await updateCampaignImageRequest(formData, campaignId)
        }

        const listCampaignInterpellationsStats = async (
            filters: CampaignStatsListParams,
            campaignId: string
        ) =>
            campaignInterpellationStatsListRequestTracker.runRequest(
                listCampaignStatsByInterpellation,
                HttpStatusCode.Ok,
                [campaignId, filters]
            )

        const duplicateCampaign = async (campaignId: string) => {
            const statusRequest =
                await duplicateCampaignRequestTracker.runRequest(
                    duplicateCampaignRequest,
                    HttpStatusCode.Created,
                    [campaignId]
                )

            if (statusRequest === RequestStatus.SUCCESS) {
                toastsStore.addMessageTKey(
                    'backoffice.campaigns.duplicateCampaignSuccess',
                    'success'
                )
                router.push({
                    name: 'campaigns_list',
                })
            } else if (statusRequest === RequestStatus.ERROR) {
                toastsStore.addMessageTKey(
                    'backoffice.messages.error',
                    'danger'
                )
            }
            return statusRequest
        }

        // GETTERS
        const promptPreview = computed(
            promptPreviewRequestTracker.getLoadedData
        )
        const campaignStats = computed(
            campaignStatsRequestTracker.getLoadedData
        )
        const campaignInterpellationsStats = computed(
            campaignInterpellationStatsListRequestTracker.getLoadedData
        )

        const isBusy = computed(
            () =>
                promptPreviewRequestTracker.isRequestInProgress() ||
                campaignStatsRequestTracker.isRequestInProgress() ||
                campaignInterpellationStatsListRequestTracker.isRequestInProgress() ||
                duplicateCampaignRequestTracker.isRequestInProgress()
        )

        return castToDeepReadOnly({
            // ACTIONS
            getCampaignStats,
            duplicateCampaign,
            getPromptPreview,
            clearPromptPreview: promptPreviewRequestTracker.reset,
            listCampaignInterpellationsStats,
            updateCampaignImage,
            // GETTERS
            campaignInterpellationsStats,
            isBusy,
            campaignStats,
            promptPreview,
        })
    }
)

export const useCampaignBackofficeCrudStore = defineCrudStore<
    Campaign,
    CampaignWrite,
    CampaignListParams
>('Backoffice:Campaigns:Crud', {
    listRequest: listCampaigns,
    readRequest: readCampaign,
    createRequest: createCampaign,
    updateRequest: updateCampaign,
    deleteRequest: deleteCampaign,
})

export const useCampaignStatsListStore = defineListOnlyStore<
    CampaignStatsListItem,
    CampaignStatsListParams
>('Backoffice:Campaigns:Stats', {
    listRequest: listCampaignsStats,
})

export const useCampaignCreateUpdateWizardStore = defineWizardStore(
    'Backoffice:Campaigns:CreateUpdateWizard',
    CREATE_UPDATE_CAMPAIGN_STEP_DEFINITIONS
)
