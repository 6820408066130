<template>
    <div>
        <span class="text-sm text-gray-600 mr-4"
            >{{ props.listConfig.pageSize * (props.listConfig.page - 1) + 1 }}
            -
            {{
                props.listConfig.pageSize * props.listConfig.page >
                props.listMeta.listLength
                    ? props.listMeta.listLength
                    : props.listConfig.pageSize * props.listConfig.page
            }}
            {{ t('backoffice.pagination.element') }}
            {{ props.listMeta.listLength }}</span
        >
        <span
            :class="`previous-page ${props.listConfig.page > 1 ? 'text-blue-500 cursor-pointer' : 'text-gray-300'}`"
            :disabled="props.listConfig.page <= 1"
            @click="onClickPreviousPage"
        >
            <i class="fi fi-rs-angle-small-left relative text-lg top-[3px]" />
        </span>
        <span
            :class="`ml-4 ${props.listConfig.page < props.listMeta.totalPages ? 'text-blue-500 cursor-pointer' : 'text-gray-300'}`"
            :disabled="props.listConfig.page >= props.listMeta.totalPages - 1"
            @click="onClickNextPage"
        >
            <i class="fi fi-rs-angle-small-right relative text-lg top-[3px]" />
        </span>
    </div>
</template>

<script setup lang="ts">
import { ListPagination, ListMeta } from '@/AppBackoffice/types'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
    listConfig: ListPagination
    listMeta: ListMeta
}

const props = defineProps<Props>()

interface Emits {
    (event: 'goToPage', page: number): void
}

const emit = defineEmits<Emits>()

const onClickPreviousPage = () => {
    const previousPage = Math.max(props.listConfig.page - 1, 1)
    if (previousPage !== props.listConfig.page) {
        emit('goToPage', previousPage)
    }
}

const onClickNextPage = () => {
    const nextPage = Math.min(
        props.listConfig.page + 1,
        props.listMeta?.totalPages || 1
    )
    if (nextPage !== props.listConfig.page) {
        emit('goToPage', nextPage)
    }
}
</script>
