<template>
    <VueSimpleContextMenu
        :element-id="props.menuId"
        ref="contextMenu"
        :options="contextMenuOptions"
        @menu-closed="onMenuClosed"
        @option-clicked="onOptionClicked"
    />
</template>
<script setup lang="ts">
import { useContextMenusStore } from '@/stores/context-menus'
import { ContextMenuId } from './types'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import VueSimpleContextMenu from 'vue-simple-context-menu'
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'

interface Props {
    menuId: ContextMenuId
    activeSlugs: Array<MenuOption['slug']>
}

const props = defineProps<Props>()

interface Emits {
    (event: 'updateClicked'): void
    (event: 'deleteClicked'): void
    (event: 'viewClicked'): void
    (event: 'deactivateClicked'): void
}

interface MenuOption {
    name: string
    slug: 'edit' | 'delete' | 'view' | 'deactivate'
}

const emit = defineEmits<Emits>()

const { t } = useI18n()
const contextMenusStore = useContextMenusStore()
const contextMenu = ref<typeof VueSimpleContextMenu | null>(null)
const contextMenuOptions = computed<Array<MenuOption>>(() => {
    return [
        {
            name:
                `<div class="flex items-center px-2 py-2 text-gray-700">` +
                `<i class="fi fi-rs-eye translate-y-0.5 mr-3"></i>${t(
                    'backoffice.actions.view'
                )}` +
                `</div>`,
            slug: 'view',
        },
        {
            name:
                `<div class="flex items-center px-2 py-2 text-gray-700">` +
                `<i class="fi fi-rs-cross translate-y-0.5 mr-3"></i>${t(
                    'backoffice.actions.deactivate'
                )}` +
                `</div>`,
            slug: 'deactivate',
        },
        {
            name:
                `<div class="flex items-center px-2 py-2 text-gray-700">` +
                `<i class="fi fi-rs-pencil translate-y-0.5 mr-3"></i>${t(
                    'backoffice.actions.edit'
                )}` +
                `</div>`,
            slug: 'edit',
        },
        {
            name:
                `<div class="flex items-center px-2 py-2 text-gray-700">` +
                `<i class="fi fi-rs-trash translate-y-0.5 mr-3"></i>${t(
                    'backoffice.actions.delete'
                )}` +
                `</div>`,
            slug: 'delete',
        },
    ].filter((option) =>
        props.activeSlugs.includes(option.slug as MenuOption['slug'])
    ) as Array<MenuOption>
})

contextMenusStore.$onAction(({ name, args }) => {
    if (name === 'openMenu') {
        const [menuId, event] = args
        if (menuId !== props.menuId) {
            return
        }
        // We open the menu after the current event loop
        // because otherwise the menu will be closed immediately
        // (any click is detected and closes all context menus).
        setTimeout(() => {
            contextMenu.value!.showMenu(event)
            contextMenusStore.setMenuOpen(menuId)
        }, 0)
    }
})

const onMenuClosed = () => {
    contextMenusStore.closeMenu(props.menuId)
}

const onOptionClicked = (event: { option: MenuOption }) => {
    if (event.option.slug === 'edit') {
        emit('updateClicked')
    } else if (event.option.slug === 'delete') {
        emit('deleteClicked')
    } else if (event.option.slug === 'view') {
        emit('viewClicked')
    } else if (event.option.slug === 'deactivate') {
        emit('deactivateClicked')
    }
}
</script>
<style>
.vue-simple-context-menu {
    background-color: white !important;
}

.vue-simple-context-menu__item:hover {
    background-color: #ecf0f1 !important;
}

.vue-simple-context-menu i {
    color: var(--color-primary) !important;
}
</style>
