<template>
    <div>
        <CustomQuestionSelectOptionFields
            v-for="(_, index) in questionOptionsField.value.value"
            :key="index"
            :option-index="index"
            :option-path="`${questionPath}.options.${index}`"
            @removeOption="onClickRemoveOption(index)"
        />
        <AVButton @click="onClickAddOption" class="mb-5 mt-2"
            >Add an option</AVButton
        >
    </div>
</template>

<script setup lang="ts">
import { FieldPath, makeUseField } from '@/utils/forms'
import AVButton from '@/components/forms/AVButton.vue'
import { CustomQuestionSelect } from '@/models/custom-questions'
import CustomQuestionSelectOptionFields from './CustomQuestionSelectOptionFields.vue'
import { CampaignWrite } from '@/models/campaigns'

interface Props {
    questionPath: FieldPath<CampaignWrite, `custom_questions.${number}`>
}

const props = defineProps<Props>()

const useFieldCustomQuestion = makeUseField<
    CampaignWrite,
    CustomQuestionSelect
>(props.questionPath)
const questionOptionsField = useFieldCustomQuestion('options')

const onClickAddOption = () => {
    questionOptionsField.value.value!.push({ answer: '', prompt: '' })
}

const onClickRemoveOption = (index: number) => {
    questionOptionsField.value.value!.splice(index, 1)
}
</script>
