<template>
    <div>
        <AVTextarea
            v-bind="textField.props.value"
            v-on="textField.listeners.value"
            placeholder="backoffice.contentSamples.commentTextPlaceholder"
        />
    </div>
</template>
<script setup lang="ts">
import AVTextarea from '@/components/forms/AVTextarea.vue'
import { CampaignWrite } from '@/models/campaigns'
import { ContentSampleComment } from '@/models/content-samples'
import { makeUseField } from '@/utils/forms'
import { ContentSampleFieldsProps } from './types'

interface Props extends ContentSampleFieldsProps {}

const props = defineProps<Props>()

const useContentSampleField = makeUseField<CampaignWrite, ContentSampleComment>(
    props.contentSamplePath
)
const textField = useContentSampleField('text')
</script>
