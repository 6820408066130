<template>
    <div class="relative">
        <div v-if="!userStore.user">
            <LoaderWithOverlay />
        </div>
        <div v-else>
            <form @submit.prevent="onClickUpdateProfile">
                <ContainerWhite>
                    <div class="md:flex md:gap-8">
                        <div class="md:w-1/2">
                            <div class="font-semibold mb-2">Username:</div>
                            <div class="mb-4">
                                {{ userStore.user.username }}
                            </div>
                        </div>
                        <div class="md:w-1/2">
                            <AVButton
                                button-type="link"
                                @click="router.push({ name: 'manage_emails' })"
                                class="border-none bg-none text-primary underline"
                            >
                                Manage Emails
                            </AVButton>
                        </div>
                    </div>
                    <div class="md:flex md:gap-8">
                        <div class="md:w-1/2">
                            <div class="font-semibold mb-2">
                                Current organization:
                            </div>
                            <AVSelect
                                :options="adminOrganizations"
                                v-bind="currentOrganizationField.props.value"
                                v-on="currentOrganizationField.listeners.value"
                                class="mb-4"
                            />
                        </div>
                        <div class="md:w-1/2">
                            <div class="font-semibold mb-2">Password:</div>
                            <AVButton
                                button-type="link"
                                @click="
                                    router.push({ name: 'update_password' })
                                "
                                class="border-none bg-none text-primary underline"
                            >
                                Change Password
                            </AVButton>
                        </div>
                    </div>
                </ContainerWhite>
                <div class="flex flex-row justify-end space-x-2 mt-4">
                    <AVButton type="submit">Update profile</AVButton>
                    <AVButton
                        button-type="outline-danger"
                        @click="onClickLogout"
                    >
                        {{ t('backoffice.actions.logout') }}
                    </AVButton>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup lang="ts">
import { RequestStatus } from '@/types'
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVSelect from '@/components/forms/AVSelect.vue'
import { useI18n } from 'vue-i18n'
import { onMounted, computed } from 'vue'
import { User } from '@/models/user'
import { useForm } from 'vee-validate'
import { makeUseField } from '@/utils/forms'
import { useRouter } from 'vue-router'
import ContainerWhite from '@/AppBackoffice/components/ContainerWhite.vue'
import { useAuthStore, useUserBackofficeCrudStore } from './stores'
import { useToastsStore } from '@/stores/toasts'
const toastsStore = useToastsStore()

const { t } = useI18n()
const router = useRouter()
const store = useAuthStore()
const userStore = useUserBackofficeCrudStore()

const adminOrganizations = computed(() =>
    userStore.user
        ? userStore.user.admin_organizations.map((org) => ({
              value: org.id,
              label: org.name,
          })) || []
        : []
)

const { setValues, values } = useForm<User>()
const useFieldUser = makeUseField<User>()
const currentOrganizationField = useFieldUser('current_organization')

const onClickUpdateProfile = async () => {
    if ((await userStore.updateUser('', values)) === RequestStatus.SUCCESS) {
        toastsStore.addMessage('User updated successfully', 'success')
    }
}

const onClickLogout = () => {
    store.logout()
    router.push({ name: 'login' })
}

onMounted(async () => {
    if ((await userStore.readUser('')) === RequestStatus.SUCCESS) {
        setValues(userStore.user!)
    }
})
</script>
