import { StepDefinition } from '@/components/ProgressBar/types'

export const SEND_CAMPAIGN_STEP_DEFINITIONS = {
    StepInterpellationSelection: {
        title: 'user.email.StepInterpellationSelectionTitle',
        description: 'user.email.StepInterpellationSelectionSubtitle',
    } as StepDefinition,
    StepGeolocation: {
        title: 'user.email.StepGeolocationTitle',
        description: 'user.email.StepGeolocationSubtitle',
    } as StepDefinition,
    StepInterpellationModeSelection: {
        title: 'user.email.StepInterpellationModeSelectionTitle',
        description: 'user.email.StepInterpellationModeSelectionSubtitle',
    } as StepDefinition,
    StepSenderQuestions: {
        title: 'user.email.StepSenderQuestionsTitle',
        description: 'user.email.StepSenderQuestionsSubtitle',
    } as StepDefinition,
    StepGenerateMessage: {
        title: 'user.email.StepGenerateMessageTitle',
        description: 'user.email.StepGenerateMessageSubtitle',
    } as StepDefinition,
}

export const TWITTER_HANDLES_PLACEHOLDER = '@recipients_handles'
