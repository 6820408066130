import { CampaignWrite, campaignValidationBase } from '@/models/campaigns'
import { customQuestionValidator } from '@/models/custom-questions'
import * as Yup from 'yup'

export const campaignStepCustomQuestionsValidator: Yup.ObjectSchema<CampaignWrite> =
    Yup.object().shape({
        ...campaignValidationBase,
        custom_questions: Yup.array()
            .of(customQuestionValidator)
            .required()
            .default([]),
        // Not validating at this step
        interpellations: Yup.array().required().default([]),
        interpellation_modes: Yup.array().required().default([]),
        social_networks_image: Yup.string().nullable().default(null),
    })
