import { TKey } from '@/i18n/index'
import { FlattenAndSetPathsType } from 'vee-validate'

/**
 * Type for validation errors, supposed to mirror how validation errors
 * are taken by vee-validate `setErrors`.
 * Beware that vee-validate `initialErrors`, on the other hand, expects a different format.
 */
export type ValidationErrors<T> = FlattenAndSetPathsType<
    Partial<T>,
    Array<TKey>
> & {
    non_field_errors?: Array<TKey>
}

type _BackendValidationErrorValue =
    | TKey
    | Array<TKey>
    | BackendValidationError
    | Array<BackendValidationError>
    | 'non_field_errors'

export type BackendValidationError = {
    [key: string]: _BackendValidationErrorValue
}

type FlattenedError = { [path: string]: Array<TKey> | string[] }

/**
 * Function to convert backend validation errors which are in nested
 * object format to a flattened object format that can be used by vee-validate.
 */
export const convertValidationErrors = <T>(
    errors: BackendValidationError
): ValidationErrors<T> =>
    Object.entries(errors).reduce(
        (flattened, [key, childErrors]) => ({
            ...flattened,
            ..._convertValidationErrorsRecursive(childErrors, key),
        }),
        {}
    ) as ValidationErrors<T>

const _convertValidationErrorsRecursive = (
    errors: _BackendValidationErrorValue,
    pathPrefix: string = ''
): FlattenedError => {
    if (typeof errors === 'string') {
        return { [pathPrefix]: [errors] }
    } else if (Array.isArray(errors)) {
        if (errors.every((error) => typeof error === 'string')) {
            return { [pathPrefix]: errors as Array<TKey> }
        } else if (
            errors.every(
                (childErrors) =>
                    typeof childErrors === 'object' &&
                    !Array.isArray(childErrors)
            )
        ) {
            return errors.reduce<FlattenedError>(
                (flattened, childErrors, index) => ({
                    ...flattened,
                    ..._convertValidationErrorsRecursive(
                        childErrors,
                        `${pathPrefix}.${index}`
                    ),
                }),
                {}
            )
        } else {
            throw new Error('received unexpected error format')
        }
    } else if (typeof errors === 'object') {
        return Object.entries(errors).reduce<FlattenedError>(
            (flattened, [key, childErrors]) => ({
                ...flattened,
                ..._convertValidationErrorsRecursive(
                    childErrors,
                    `${pathPrefix}.${key}`
                ),
            }),
            {}
        )
    } else {
        throw new Error('Unexpected error type')
    }
}
