<template>
    <form @submit.prevent="onSubmit">
        <div class="mb-4">
            <AVSelectButtons
                :label="t('backoffice.person.gender')"
                v-bind="genderField.props.value as FormFieldProps<string>"
                v-on="genderField.listeners.value"
                :options="genderOptions"
            />
        </div>
        <div class="flex gap-4 mb-4">
            <div class="w-1/2">
                <AVInput
                    :label="t('backoffice.person.firstName')"
                    v-bind="firstNameField.props.value"
                    v-on="firstNameField.listeners.value"
                    :placeholder="t('backoffice.person.firstNamePlaceholder')"
                />
            </div>
            <div class="w-1/2">
                <AVInput
                    :label="t('backoffice.person.lastName')"
                    v-bind="lastNameField.props.value"
                    v-on="lastNameField.listeners.value"
                    :placeholder="t('backoffice.person.lastNamePlaceholder')"
                />
            </div>
        </div>
        <div class="flex gap-4 mb-4">
            <div class="w-1/2">
                <AVInput
                    :label="t('backoffice.person.email')"
                    v-bind="emailField.props.value"
                    v-on="emailField.listeners.value"
                    :placeholder="t('backoffice.person.emailPlaceholder')"
                />
            </div>
            <div class="w-1/2">
                <AVInput
                    :label="t('backoffice.person.facebookPage')"
                    v-bind="facebookPageField.props.value"
                    v-on="facebookPageField.listeners.value"
                    :placeholder="
                        t('backoffice.person.facebookPagePlaceholder')
                    "
                />
            </div>
        </div>

        <div class="flex gap-4 mb-4">
            <div class="w-1/2">
                <AVInput
                    prefix="@"
                    :label="t('backoffice.person.twitterHandle')"
                    v-bind="twitterHandleField.props.value"
                    v-on="twitterHandleField.listeners.value"
                    :placeholder="
                        t('backoffice.person.twitterHandlePlaceholder')
                    "
                />
            </div>
            <div class="w-1/2">
                <AVInput
                    prefix="@"
                    :label="t('backoffice.person.instagramHandle')"
                    v-bind="instagramHandleField.props.value"
                    v-on="instagramHandleField.listeners.value"
                    :placeholder="
                        t('backoffice.person.instagramHandlePlaceholder')
                    "
                />
            </div>
        </div>
        <ModalButtons
            :ok-t-key="props.submitTKey"
            :route-name-on-close="props.routeNameOnClose"
            ok-button-type="submit"
            class="mt-6"
        />
    </form>
</template>
<script setup lang="ts">
import { useForm } from 'vee-validate'
import { computed } from 'vue'
import { toTypedSchema } from '@vee-validate/yup'
import ModalButtons from '@/AppBackoffice/components/ModalButtons.vue'
import { RECIPIENT_GENDER_OPTIONS } from './constants'
import { useI18n } from 'vue-i18n'
import AVSelectButtons from '@/components/forms/AVSelectButtons.vue'
import AVInput from '@/components/forms/AVInput.vue'
import { FormFieldProps } from '@/utils/forms'
import { RouteName } from '@/types'
import { RecipientWrite, Recipient } from '@/models/recipients'
import { makeUseField } from '@/utils/forms'
import { TKey } from '@/i18n'
import { recipientValidator } from './validation'
import { ValidationErrors } from '@/utils/validation-errors'
import { onBeforeMountOrPropChange } from '@/utils/components'

interface Props {
    recipient?: Recipient
    routeNameOnClose: RouteName
    submitTKey: TKey
    validationErrors: ValidationErrors<Recipient> | null
}

interface Emits {
    (event: 'submit', recipient: RecipientWrite): void
    (event: 'cancel'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()
const validationSchema = computed(() => toTypedSchema(recipientValidator))
const genderOptions = computed(() =>
    RECIPIENT_GENDER_OPTIONS.map(({ value, label }) => ({
        value,
        label: t(label),
    }))
)
const { validate, setErrors } = useForm({
    validationSchema,
    initialValues: props.recipient || {},
})

const useRecipientField = makeUseField<RecipientWrite>()
const firstNameField = useRecipientField('first_name')
const lastNameField = useRecipientField('last_name')
const emailField = useRecipientField('email')
const facebookPageField = useRecipientField('facebook_page')
const genderField = useRecipientField('gender')
const twitterHandleField = useRecipientField('twitter_handle')
const instagramHandleField = useRecipientField('instagram_handle')

const setValidationErrors = () => {
    if (props.validationErrors) {
        setErrors(props.validationErrors)
    }
}

onBeforeMountOrPropChange(() => props.validationErrors, setValidationErrors)

const onSubmit = async () => {
    const { valid: isValid, values } = await validate()
    if (isValid) {
        emit('submit', values!)
    }
}
</script>
