import { RList, RListSimple, RListWrite } from '@/models/rlists'
import {
    RListListParams,
    createRList,
    deleteRList,
    listAllRListsSimple,
    listRList,
    readRList,
    updateRList,
} from './requests'
import { defineCrudStore } from '@/stores/crud'
import { defineStore } from 'pinia'
import { HttpStatusCode } from 'axios'
import { computed } from 'vue'
import { castToDeepReadOnly } from '@/utils/types'
import { useRequestTracker } from '@/utils/request-tracker'

export const useRListPickerStore = defineStore(
    'Backoffice:Recipients:RListPicker',
    () => {
        const requestTracker = useRequestTracker<Array<RListSimple>>()
        const listRLists = () =>
            requestTracker.runRequest(
                listAllRListsSimple,
                HttpStatusCode.Ok,
                []
            )
        const rlists = computed(requestTracker.getLoadedData)
        return castToDeepReadOnly({
            _requestTracker: requestTracker.state,
            listRLists,
            rlists,
        })
    }
)

export const useRListsBackofficeCrudStore = defineCrudStore<
    RList,
    RListWrite,
    RListListParams
>('Backoffice:RLists:Crud', {
    listRequest: listRList,
    readRequest: readRList,
    createRequest: createRList,
    updateRequest: updateRList,
    deleteRequest: deleteRList,
})
