import { components } from '@/schema'
import * as Yup from 'yup'
import {
    Interpellation,
    InterpellationPublic,
    InterpellationWrite,
} from './interpellations'
import { Locale, TKey } from '@/i18n/index'
import { CustomQuestion } from './custom-questions'
import { TypeEqualityGuard, assertTypesMatch } from '@/utils/types'

export const INTERPELLATION_MODES = [
    'facebook',
    'email',
    'twitter',
    'instagram',
] as const

export type InterpellationMode = (typeof INTERPELLATION_MODES)[number]
assertTypesMatch<
    TypeEqualityGuard<
        components['schemas']['InterpellationModesEnum'],
        InterpellationMode
    >
>()

export type CampaignState = components['schemas']['StateEnum']

export const INTERPELLATION_MODE_OPTIONS: Array<{
    label: TKey
    value: InterpellationMode
}> = [
    { label: 'backoffice.campaigns.interpellationModeEmail', value: 'email' },
    {
        label: 'backoffice.campaigns.interpellationModeFacebook',
        value: 'facebook',
    },
    {
        label: 'backoffice.campaigns.interpellationModeTwitter',
        value: 'twitter',
    },
    {
        label: 'backoffice.campaigns.interpellationModeInstagram',
        value: 'instagram',
    },
]

export type Campaign = Omit<
    components['schemas']['Campaign'],
    'custom_questions' | 'interpellations'
> & {
    custom_questions: Array<CustomQuestion>
    interpellations: Array<Interpellation>
}

export type CampaignPublic = Omit<
    components['schemas']['CampaignPublic'],
    'custom_questions' | 'interpellations'
> & {
    sharing_url?: string
    custom_questions: Array<CustomQuestion>
    interpellations: Array<InterpellationPublic>
}

export type CampaignWrite = Omit<
    Campaign,
    // Read-only fields
    | 'id'
    | 'created_date'
    | 'updated_date'
    | 'sharing_url'
    // Redefined fields
    | 'interpellations'
    | 'is_tracking_sent_emails'
> & {
    interpellations: Array<InterpellationWrite>
}

export const campaignValidationBase = {
    id: Yup.string().optional(),
    name: Yup.string().required(),
    description: Yup.string().optional(),
    language: Yup.mixed<Locale>().optional(),
    state: Yup.mixed<CampaignState>().default('active'),
    custom_prompt: Yup.string().optional(),
    sharing_url: Yup.string().optional(),
    is_geo_located: Yup.boolean().default(false),
    color_primary: Yup.string().default('#005DCA'),
    color_secondary: Yup.string().default('#FFDD28'),
    color_accent: Yup.string().default('#CB0C9F'),
}

export type CampaignStats = components['schemas']['InterpellationStats']
export type CampaignStatsListItem = components['schemas']['CampaignStats']
export type CampaignInterpellationStatsListItem =
    components['schemas']['SimpleInterpellationStats']
