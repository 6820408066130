import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import './services/yup-config'

import App from './App.vue'
import router from './router'
import {
    setupAxiosDefaults,
    setupRequestInterceptorsForApp,
} from './services/requests'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import { initializeSentry } from './services/sentry'
import i18n from './i18n'

const app = createApp(App)
initializeSentry(app, router)

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
app.use(pinia)
app.use(router)
app.use(i18n)
setupAxiosDefaults()
setupRequestInterceptorsForApp(router)
app.mount('#app')
