<template>
    <!-- Modal overlay -->
    <div
        class="fixed z-50 inset-0 flex flex-col items-center justify-center h-full bg-gray-500 bg-opacity-75"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
    >
        <Transition name="fade">
            <!-- Modal container -->
            <div
                class="flex flex-col max-w-lg w-full max-h-full bg-white rounded-lg shadow-lg p-8"
            >
                <!-- Modal header -->
                <div class="flex flex-row justify-between">
                    <h2 v-if="props.titleTKey" class="text-2xl font-bold mb-2">
                        {{ t(props.titleTKey) }}
                    </h2>
                    <!-- empty div to push close button to the right -->
                    <div v-else></div>
                    <div class="block text-right text-xl cursor-pointer">
                        <i
                            class="flex close-embed fi fi-rs-cross-small text-xl cursor-pointer"
                            @click="onCloseClicked"
                        ></i>
                    </div>
                </div>

                <!-- Modal content that shrinks if modal too high -->
                <div class="min-h-0 overflow-auto">
                    <slot name="content"></slot>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { TKey } from '@/i18n'

export interface Props {
    titleTKey?: TKey
}

export interface Emits {
    (event: 'closeModal'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()
const { t } = useI18n()

const onCloseClicked = () => {
    emit('closeModal')
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
