<template>
    <div
        class="w-full flex flex-col lg:flex-row justify-between items-start lg:items-center mb-6"
    >
        <div>
            <h1 class="text-2xl font-semibold text-gray-900">
                <slot name="title"></slot>
            </h1>
            <p class="text-gray-600">
                <slot name="subtitle"></slot>
            </p>
        </div>
        <div>
            <slot name="download"></slot>
            <slot name="actions"></slot>
        </div>
    </div>
</template>
