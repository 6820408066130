<template>
    <div class="bg-white shadow rounded-lg p-6">
        <RouterView v-if="!crudStore.isBusy && crudStore.itemData"></RouterView>
        <div
            v-if="!crudStore.isBusy && crudStore.itemData"
            class="flex justify-between items-start"
        >
            <div>
                <!-- <div class="bg-gray-300 rounded-full h-24 w-24 overflow-hidden">
                    <img
                        src=""
                        alt=""
                        class="object-cover object-center w-full h-full"
                    />
                </div> -->
                <div class="text-xl font-bold text-gray-700 mb-2">
                    {{ crudStore.itemData!.first_name }}
                    {{ crudStore.itemData!.last_name }}
                </div>
                <div class="text-gray-600">
                    <div class="mb-4">
                        <strong>{{ t('backoffice.person.gender') }}:</strong>
                        {{
                            t(
                                RECIPIENT_GENDER_OPTIONS.find(
                                    ({ value }) =>
                                        crudStore.itemData!.gender === value
                                )!.label
                            )
                        }}
                    </div>
                    <div class="mb-4">
                        <strong>{{ t('backoffice.person.firstName') }}:</strong>
                        {{ crudStore.itemData!.first_name }}
                    </div>
                    <div class="mb-4">
                        <strong>{{ t('backoffice.person.lastName') }}:</strong>
                        {{ crudStore.itemData!.last_name }}
                    </div>
                    <div class="mb-4">
                        <strong>Fonction:</strong> Député de Lorem Ipsum
                    </div>
                    <div class="mb-4">
                        <strong>{{ t('backoffice.person.email') }}:</strong>
                        {{ crudStore.itemData!.email }}
                    </div>
                    <div class="mb-4">
                        <strong>Listes:</strong>
                        <span
                            class="inline-block bg-blue-200 text-blue-800 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                            >Lorem ipsum</span
                        >
                        <span
                            class="inline-block bg-blue-200 text-blue-800 rounded-full px-3 py-1 text-sm font-semibold mr-2"
                            >Lorem ipsum</span
                        >
                        <span
                            class="inline-block bg-blue-200 text-blue-800 rounded-full px-3 py-1 text-sm font-semibold"
                            >Lorem ipsum</span
                        >
                    </div>
                </div>
            </div>
            <div class="flex items-center space-x-2">
                <RouterLink
                    class="bg-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    :to="{
                        name: 'recipient_update',
                        params: {
                            recipientId: crudStore.itemData!.id,
                        },
                    }"
                >
                    <i class="fas fa-edit"></i>
                    {{ t('backoffice.actions.edit') }}
                </RouterLink>
                <RouterLink
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    :to="{
                        name: 'recipient_delete',
                        params: {
                            recipientId: crudStore.itemData!.id,
                        },
                    }"
                >
                    <i class="fas fa-trash"></i>
                    {{ t('backoffice.actions.delete') }}
                </RouterLink>
            </div>
        </div>
        <AVLoader v-if="crudStore.isBusy" />
    </div>
</template>

<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router'
import AVLoader from '@/components/AVLoader.vue'
import { useRecipientsBackofficeCrudStore } from './stores'
import { RECIPIENT_GENDER_OPTIONS } from './constants'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { ModelId } from '@/models/types'

interface Props {
    recipientId: ModelId
}

const props = defineProps<Props>()
const { t } = useI18n()
const crudStore = useRecipientsBackofficeCrudStore()

onMounted(() => {
    crudStore.runRead(props.recipientId)
})
</script>
