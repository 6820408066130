import { RecipientPublic } from '@/models/recipients'
import { TKey } from '@/i18n'

export const createGreatingsSamplesForRecipient = (
    recipient: RecipientPublic
): TKey => {
    if (recipient.gender === 'M') {
        return 'embed.greetings.men'
    } else if (recipient.gender === 'F') {
        return 'embed.greetings.women'
    }
    return 'embed.greetings.other'
}
