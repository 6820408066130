<template>
    <CenteredContainer class="w-full md:w-fit space-y-7">
        <AVButton
            v-for="interpellationMode in props.interpellationModesAvailable"
            :key="interpellationMode"
            button-type="outline-primary"
            class="w-full h-full"
            @click="onChooseInterpellationMode(interpellationMode)"
            >{{
                t('user.campaign.interpellation_mode.' + interpellationMode)
            }}</AVButton
        >
    </CenteredContainer>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { InterpellationMode } from '@/models/campaigns'
import CenteredContainer from '../CenteredContainer.vue'
import AVButton from '@/components/forms/AVButton.vue'

interface Props {
    interpellationModesAvailable: Array<InterpellationMode>
}

const props = defineProps<Props>()

interface Emits {
    (
        event: 'choose-interpellation-mode',
        interpellationMode: InterpellationMode
    ): void
}

const emit = defineEmits<Emits>()

const { t } = useI18n()

const onChooseInterpellationMode = (interpellationMode: InterpellationMode) => {
    emit('choose-interpellation-mode', interpellationMode)
}
</script>
