import OrganizationAdmin from './OrganizationAdmin.vue'

import { RouteRecordRaw } from 'vue-router'

const organizationsRoutes: Array<RouteRecordRaw> = [
    {
        path: 'organization',
        name: 'organization_admin',
        component: OrganizationAdmin,
    },
]

export default organizationsRoutes
