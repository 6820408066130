<template>
    <div class="container flex justify-between items-center px-4 py-4">
        <form @submit.prevent="onFilterSubmit">
            <input
                type="text"
                class="border border-gray-300 rounded-full p-2"
                :placeholder="`${t('backoffice.actions.search')}...`"
                v-model="filterQuery"
                @input="onFilterInputChange"
            />
        </form>
        <PaginationNavigator
            :listConfig="listConfig"
            :listMeta="listMeta"
            @go-to-page="goToPage"
        />
    </div>
</template>

<script
    setup
    lang="ts"
    generic="T extends object, ListFilters extends ListFiltersBase"
>
import { RouteName } from '@/types'
import {
    ListConfig,
    ListFiltersBase,
    ListFiltersDefault,
    ListMeta,
} from '@/AppBackoffice/types'
import debounce from 'lodash/debounce'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { watch } from 'vue'
import PaginationNavigator from './PaginationNavigator.vue'

interface Props {
    listConfig: ListConfig<T>
    listFilters: (ListFilters & ListFiltersDefault) | null
    listMeta: ListMeta
    routeName: RouteName
}

const props = defineProps<Props>()

const { t } = useI18n()
const router = useRouter()

const filterQuery = ref<string | null>(props.listFilters?.filter || null)
const currentRouterQuery = computed(() => {
    const searchParams = new URLSearchParams(location.search)
    return {
        page: props.listConfig.page,
        filter: filterQuery.value,
        ...Object.fromEntries(searchParams.entries()),
    }
})

const onFilterSubmit = () => {
    router.push({
        name: props.routeName,
        query: {
            ...currentRouterQuery.value,
            page: 1,
        },
    })
}

const onFilterInputChange = debounce(onFilterSubmit, 750)

const goToPage = (page: number) => {
    router.push({
        name: props.routeName,
        query: {
            ...currentRouterQuery.value,
            page,
        },
    })
}

watch(
    () => props.listFilters,
    (filters) => {
        filterQuery.value = filters?.filter || null
    }
)
</script>
