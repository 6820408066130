<template>
    <i
        class="fi fi-rs-menu-dots-vertical text-primary cursor-pointer"
        :class="{ 'text-gray-400': !isShowingMenu }"
        @click="onMenuClicked"
    >
    </i>
</template>

<script setup lang="ts">
import { useContextMenusStore } from '@/stores/context-menus'
import { ContextMenuId } from './types'
import { computed } from 'vue'
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css'

interface Props {
    menuId: ContextMenuId
}

const props = defineProps<Props>()

const contextMenusStore = useContextMenusStore()
const isShowingMenu = computed(
    () => contextMenusStore.openMenuId === props.menuId
)

const onMenuClicked = (event: Event) => {
    contextMenusStore.openMenu(props.menuId, event)
}
</script>
