<template>
    <div
        :id="`toast-${props.id}`"
        class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow"
        role="alert"
    >
        <div
            class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg"
            :class="{
                'text-green-500 bg-green-100 ': props.messageType === 'success',
                'text-red-500 bg-red-100 ': props.messageType === 'danger',
                'text-blue-500 bg-blue-100 ': props.messageType === 'info',
            }"
        >
            <i
                class="fi fi-rs-check-circle translate-y-0.5"
                v-if="props.messageType === 'success'"
            ></i>
            <i
                class="fi fi-rs-cross translate-y-0.5"
                v-if="props.messageType === 'danger'"
            ></i>
            <i
                class="fi fi-rs-circle-i translate-y-0.5"
                v-if="props.messageType === 'info'"
            ></i>
        </div>
        <div class="ms-3 text-sm font-normal">{{ props.message }}</div>
        <button
            @click="close"
            type="button"
            class="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
            aria-label="Close"
        >
            <span class="sr-only">Close</span>
            <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
            >
                <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
            </svg>
        </button>
    </div>
</template>

<script setup lang="ts">
import { useToastsStore } from '@/stores/toasts'
const toastsStore = useToastsStore()

interface Props {
    id: string
    message: string
    messageType: string
}

const props = defineProps<Props>()

const close = () => {
    toastsStore.closeMessage(props.id)
}
</script>
