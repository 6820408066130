<template>
    <p class="mt-2 text-sm text-error">
        {{ t(errorTKey) }}
    </p>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { TKey } from '@/i18n'

interface Props {
    errorTKey: TKey
}

defineProps<Props>()

const { t } = useI18n()
</script>
@/index
