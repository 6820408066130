<template>
    <ContainerMain class="text-center">
        <MessagePreview
            :message-text="generatedMessageText"
            :is-streaming="!store.isStreamingIdle"
            :has-copy-button="true"
        />
        <TransitionExpands>
            <CenteredContainer
                v-show="store.isStreamingIdle"
                class="overflow-hidden"
            >
                <ButtonGenerateOther
                    label-t-key="user.campaign.comment_generate_other"
                    @generate-message="store.reformulateContentSample()"
                />
                <MessageInstructions
                    :instructions-t-key="getInstructionTKey()"
                />
                <SendButtons
                    :send-links="selectedSendLinks"
                    @send-button-clicked="() => emit('sendButtonClicked')"
                />
                <AVButton
                    v-show="
                        !isShowingAllRecipients &&
                        selectedSendLinks.length > INITIAL_RECIPIENTS_SHOWN
                    "
                    @click="onClickedShowMoreRecipients"
                    button-type="link"
                    class="mt-6 mb-8"
                >
                    {{ t('user.campaign.recipient_load_more') }}
                </AVButton>
            </CenteredContainer>
        </TransitionExpands>
    </ContainerMain>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import { computed, ref } from 'vue'
import { ContentSampleComment } from '@/models/content-samples'
import AVButton from '@/components/forms/AVButton.vue'
import CenteredContainer from '../../CenteredContainer.vue'
import ContainerMain from '../ContainerMain.vue'
import TransitionExpands from '../TransitionExpands.vue'
import MessagePreview from './MessagePreview.vue'
import ButtonGenerateOther from './ButtonGenerateOther.vue'
import MessageInstructions from './MessageInstructions.vue'
import SendButtons from './SendButtons.vue'
import shuffle from 'lodash/shuffle'
import { recipientDisplay, RecipientPublic } from '@/models/recipients'
import { SendButtonsEmits } from '../../types'

interface Emits extends SendButtonsEmits {}

const emit = defineEmits<Emits>()

const { t } = useI18n()
const store = useSendCampaignStore()

const INITIAL_RECIPIENTS_SHOWN = 3
const generatedMessageText = computed(() =>
    store.generatedContent
        ? (store.generatedContent as ContentSampleComment).text
        : null
)

const isShowingAllRecipients = ref(false)

const getCommentLink = (recipient: RecipientPublic) => {
    switch (store.interpellationMode!) {
        case 'facebook':
            return recipient.facebook_page!
        case 'instagram':
            return `https://instagram.com/${recipient.instagram_handle}`
        default:
            return ''
    }
}

const sendLinks = computed(() =>
    shuffle(store.recipients).map((recipient) => ({
        href: getCommentLink(recipient),
        label: recipientDisplay(recipient),
    }))
)

const selectedSendLinks = computed(() =>
    isShowingAllRecipients.value
        ? sendLinks.value
        : sendLinks.value.slice(0, INITIAL_RECIPIENTS_SHOWN)
)

const getInstructionTKey = () => {
    if (selectedSendLinks.value.length === 1) {
        return 'user.campaign.comment_instructions_single'
    } else {
        return 'user.campaign.comment_instructions'
    }
}

const onClickedShowMoreRecipients = () => {
    isShowingAllRecipients.value = true
}
</script>
