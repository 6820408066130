<template>
    <div class="w-full h-full" v-if="initAppStore.isComplete">
        <ToastsArea />
        <RouterView />
    </div>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import { useRestoreLocale } from '@/i18n'
import { useInitAppStore } from '@/stores/init-app'
import ToastsArea from '@/components/Toast/ToastsArea.vue'
import { onBeforeMount } from 'vue'

const initAppStore = useInitAppStore()

useRestoreLocale()

onBeforeMount(() => {
    initAppStore.start()
})
</script>
