<template>
    <div class="flex flex-col" :class="{ 'has-errors': errorTKey }">
        <label
            :class="{
                'block mb-2 text-sm font-normal text-gray-900': true,
                'block mb-2 text-sm font-medium text-red-700': errorTKey,
            }"
            v-if="label"
            >{{ label }}</label
        >
        <div class="relative flex-1">
            <select
                class="h-full pl-3.5 cursor-pointer text-ellipsis pr-6"
                :class="{
                    'border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5': true,
                    'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
                        errorTKey,
                }"
                @change="onChange"
                :required="required"
            >
                <option disabled selected="true" v-if="placeholder">
                    {{ placeholder }}
                </option>
                <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="index"
                    :selected="option.value === modelValue"
                    :disabled="option.disabled"
                >
                    {{ option.label }}
                </option>
            </select>
            <svg
                class="select-chevron"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1792 1792"
            >
                <path
                    d="M996,1314.5c-55.2,55.2-139.5,55.2-194.8,0c-2.4-2.8-2.4-2.8-2.4-2.8L154.4,670.1c-52.4-55.2-52.4-139.2,0-194.4
                c55.2-52.8,139.5-52.8,194.4,0l549.7,552.1l547.2-546.9c52.4-52.4,139.5-52.4,192,0c52.4,52.8,52.4,139.5,0,192L996,1314.5z"
                />
            </svg>
        </div>

        <AVError v-if="errorTKey" :error-t-key="errorTKey" />
    </div>
</template>

<script
    setup
    lang="ts"
    generic="T extends string | number | boolean | null | undefined"
>
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'
import AVError from '@/components/forms/AVError.vue'

interface Props extends FormFieldProps<T> {
    placeholder?: string | null
    required?: boolean
    options: Array<{ value: T; label: string; disabled?: boolean }>
}

interface Emits extends FormFieldEmits<T> {}

const props = withDefaults(defineProps<Props>(), {
    placeholder: null,
    required: false,
})

const emit = defineEmits<Emits>()

const onChange = (e: Event) => {
    // With a <select> we can only serialize strings into html,
    // and since here we use arbitrary value types, we use instead
    // the index of the option to map back to the original value.
    const selectedIndex = parseInt((e.target as HTMLSelectElement).value, 10)
    const selectedValue = props.options[selectedIndex].value
    emit('update:modelValue', selectedValue)
}
</script>
<style scoped>
select {
    /* 
    Remove default chevron for select
    REF https://stackoverflow.com/questions/14218307/select-arrow-style-change 
    */
    background: url('') no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
}

svg.select-chevron {
    position: absolute;
    top: 50%;
    right: 0.5em;
    width: 1em;
    height: 1em;
    fill: var(--color-primary);
    transform: translateY(-50%);
    pointer-events: none;
}
</style>
