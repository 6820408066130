<template>
    <AVButton
        button-type="link"
        class="mt-6 mb-8"
        @click="onClickGenerateOtherComment"
        :disabled="isGenerateOtherCommentDisabled"
    >
        {{ t(labelTKey) }}
        {{ isGenerateOtherCommentDisabled ? `(${disabledCountdown})` : '' }}
    </AVButton>
</template>
<script setup lang="ts">
import AVButton from '@/components/forms/AVButton.vue'
import { TKey } from '@/i18n'
import { onBeforeUnmount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const GENERATED_FAST_COUNT_LIMIT = 3
const DISABLE_DURATION = 10000

interface Props {
    labelTKey: TKey
}

interface Emits {
    (event: 'generateMessage'): void
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()
const isGenerateOtherCommentDisabled = ref(false)
const generatedCount = ref(1)
const disableIntervalId = ref<ReturnType<typeof setInterval> | null>(null)
const disabledCountdown = ref(DISABLE_DURATION)

const onClickGenerateOtherComment = () => {
    generatedCount.value = generatedCount.value + 1
    if (generatedCount.value >= GENERATED_FAST_COUNT_LIMIT) {
        isGenerateOtherCommentDisabled.value = true
        const intervalStartDate = Date.now()
        disableIntervalId.value = setInterval(() => {
            disabledCountdown.value = Math.ceil(
                (DISABLE_DURATION - (Date.now() - intervalStartDate)) / 1000
            )
            if (Date.now() - intervalStartDate > DISABLE_DURATION) {
                isGenerateOtherCommentDisabled.value = false
                clearInterval(disableIntervalId.value!)
            }
        }, 500)
    }
    emit('generateMessage')
}

onBeforeUnmount(() => {
    if (disableIntervalId.value) {
        clearInterval(disableIntervalId.value)
    }
})
</script>
