<template>
    <div
        class="flex justify-center items-center text-center h-screen bg-gray-100"
    >
        <div class="w-full md:w-3/12 px-5 mt-[-10%]">
            <h2
                class="text-3xl font-light mt-7 mb-3 text-center antialiased text-gray-800"
            >
                Activoice
            </h2>
            <FadingSeparator />
            <br />
            <form @submit.prevent="resetPassword" class="flex flex-col">
                <div class="form-fields text-left">
                    <AVInput
                        :name="'email'"
                        class="custom-input"
                        label="Email"
                        type="email"
                        v-model="email"
                        placeholder="Enter your email"
                    />
                </div>
                <AVButton class="mt-5" type="submit" v-show="!authStore.isBusy"
                    >Reset Password</AVButton
                >
                <AVButton
                    class="mt-5"
                    button-type="link"
                    @click="router.push({ name: 'login' })"
                    v-show="!authStore.isBusy"
                    >Login</AVButton
                >
                <AVLoader v-show="authStore.isBusy" :size="58"></AVLoader>
            </form>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useAuthStore } from './stores'
import FadingSeparator from '@/components/FadingSeparator.vue'
import AVInput from '@/components/forms/AVInput.vue'
import AVButton from '@/components/forms/AVButton.vue'
import AVLoader from '@/components/AVLoader.vue'
import { useToastsStore } from '@/stores/toasts'
import { RequestStatus } from '@/types'

const toastsStore = useToastsStore()

const router = useRouter()

const authStore = useAuthStore()

const email = ref('')

const resetPassword = async () => {
    const requestStatus = await authStore.startResetPassword(email.value)
    if (requestStatus === RequestStatus.SUCCESS) {
        toastsStore.addMessageTKey(
            'backoffice.user.password_reset_mail_sent',
            'success'
        )
        router.push({ name: 'login' })
    } else {
        toastsStore.addMessageTKey(
            'backoffice.user.password_reset_failed',
            'danger'
        )
    }
}
</script>
