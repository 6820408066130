<template>
    <tr class="h-16">
        <td class="px-4 py-2">
            {{ recipient.last_name }}
        </td>
        <td class="px-4 py-2">
            {{ recipient.first_name }}
        </td>
        <td class="px-4 py-2">
            {{ recipient.email }}
        </td>
        <td class="px-4 py-2 text-right relative">
            <ContextMenuEllipsisButton :menu-id="menuId" />
        </td>
        <ContextMenu
            :menu-id="menuId"
            :active-slugs="['edit', 'delete']"
            @delete-clicked="onDeleteRecipientClicked"
            @update-clicked="onUpdateRecipientClicked"
        />
    </tr>
</template>
<script setup lang="ts">
import { Recipient } from '@/models/recipients'
import ContextMenuEllipsisButton from '@/components/context-menus/ContextMenuEllipsisButton.vue'
import ContextMenu from '@/components/context-menus/ContextMenu.vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

interface Props {
    recipient: Recipient
}

const props = defineProps<Props>()
const router = useRouter()
const menuId = computed(() => `recipient-${props.recipient.id}`)

const onDeleteRecipientClicked = () => {
    router.push({
        name: 'recipient_delete_inPlace',
        params: {
            recipientId: props.recipient.id,
        },
    })
}

const onUpdateRecipientClicked = () => {
    router.push({
        name: 'recipient_update_inPlace',
        params: {
            recipientId: props.recipient.id,
        },
    })
}
</script>
