export const ACTIVOICE_DOCS_URL =
    'https://activoice.gitbook.io/activoice-modal-documentation'
export const ACTIVOICE_SUPPORT_EMAIL = 'hello@activoice.online'

export const PLACEHOLDER_PATTERN = /\*\*\*PLACEHOLDER\*\*\*/

export const EMBED_JS_ROOT_URL = import.meta.env.VITE_EMBED_JS_ROOT_URL

export const API_ROOT_URL = import.meta.env.VITE_DJANGO_API_URL

export const BCC_DOMAIN = import.meta.env.VITE_BCC_DOMAIN

export const PUBLIC_API_ROOT_URL =
    API_ROOT_URL + (!API_ROOT_URL.endsWith('/') ? '/' : '') + 'api'
