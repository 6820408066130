import { ModelId } from '@/models/types'
import packageJson from '../../../package.json'
import { CampaignPublic } from '@/models/campaigns'

export const generateEmbedWithDataSnippet = (
    campaign: CampaignPublic,
    embedJsRootUrl: string
) => {
    // Actually this is not 100% safe since 2 campaigns could have same name
    // when replacing special characters. But it's probably good enough.
    const safeCampaignId = _makeSafeId(campaign.name)
    return `<script src="${embedJsRootUrl}/${generateVersionedEmbedFilename()}"></script>
<activoice-embed id="activoice-embed-${safeCampaignId}"></activoice-embed>
<button id="button-${safeCampaignId}">
    ${campaign.name}
</button>
<script>
    const data = ${JSON.stringify(campaign)}
    const activoiceEmbed_${safeCampaignId} = document.getElementById('activoice-embed-${safeCampaignId}')
    document
        .getElementById('button-${safeCampaignId}')
        .addEventListener('click', () => {
            window.Activoice.bootstrap().then(() => {
                activoiceEmbed_${safeCampaignId}.open(data)
            })
        })
</script>`
}

export const generateEmbedWithIdSnippet = (
    campaignId: ModelId,
    embedJsRootUrl: string
) => {
    const safeCampaignId = _makeSafeId(campaignId)
    return `<script src="${embedJsRootUrl}/${generateVersionedEmbedFilename()}"></script>
<activoice-embed id="activoice-embed-${safeCampaignId}"></activoice-embed>
<button id="button-${safeCampaignId}">Open Campaign</button>
<script>
    const activoiceEmbed_${safeCampaignId} = document.getElementById('activoice-embed-${safeCampaignId}')
    document
        .getElementById('button-${safeCampaignId}')
        .addEventListener('click', () => {
            window.Activoice.bootstrap().then(() => {
                activoiceEmbed_${safeCampaignId}.openWithId("${campaignId}")
            })
        })
</script>`
}

export const generateVersionedEmbedFilename = () =>
    `activoice-${packageJson.version}.js`

const _makeSafeId = (id: string) => id.replace(/[^a-zA-Z0-9]/g, '_').slice(0)
