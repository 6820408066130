<template>
    <Transition name="expands">
        <slot></slot>
    </Transition>
</template>
<style scoped>
.expands-enter-active,
.expands-leave-active {
    transition:
        max-height 0.5s ease,
        opacity 0.5s ease;
    max-height: 100vh;
    opacity: 1;
}

.expands-enter-from,
.expands-leave-to {
    max-height: 0;
    opacity: 0;
}
</style>
