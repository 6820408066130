<template>
    <div class="no-list-found-container">
        <slot name="no-element-msg"></slot>
        <RouterLink
            v-if="$slots['create-element-msg']"
            :to="{ name: props.routeName }"
        >
            <AVButton class="mt-5">
                <slot name="create-element-msg"></slot>
            </AVButton>
        </RouterLink>
    </div>
</template>

<script setup lang="ts">
import { RouterLink } from 'vue-router'
import AVButton from '@/components/forms/AVButton.vue'
import { RouteName } from '@/types'

interface Props {
    routeName: RouteName
}

const props = defineProps<Props>()
</script>

<style scoped>
.no-list-found-container {
    text-align: center;
}

.no-list-found-container img {
    width: 250px;
    max-width: 50%;
}
</style>
