<template>
    <AVInput
        class="mb-3"
        v-bind="promptField.props.value"
        v-on="promptField.listeners.value"
        :label="'Instructions pour l’intelligence artificielle'"
        :placeholder="'Ecris le message du point de vue d’un habitant de *** PLACEHOLDER *** '"
    />
</template>

<script setup lang="ts">
import { FieldPath, makeUseField } from '@/utils/forms'
import AVInput from '@/components/forms/AVInput.vue'
import { CustomQuestionText } from '@/models/custom-questions'
import { CampaignWrite } from '@/models/campaigns'

interface Props {
    questionPath: FieldPath<CampaignWrite, `custom_questions.${number}`>
}

const props = defineProps<Props>()

const useFieldCustomQuestion = makeUseField<CampaignWrite, CustomQuestionText>(
    props.questionPath
)
const promptField = useFieldCustomQuestion('prompt')
</script>
