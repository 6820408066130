import { TKey } from '@/i18n/index'
import { Path, useField } from 'vee-validate'
import { computed, unref } from 'vue'
import { useI18n } from 'vue-i18n'

/** Standardized props for custom form field components */
export interface FormFieldProps<T> {
    readonly name: string
    readonly modelValue: T | undefined
    readonly errorTKey?: TKey
    readonly label?: string
}

/** Standardized emits for custom form field components */
export interface FormFieldEmits<T> {
    (event: 'update:modelValue', value: T): void
}

/** Standardized event listeners for custom form field components */
export interface FormFieldListeners<T> {
    'update:modelValue': ($event: T) => void
}

/** Helper to validate that a field path exists in `Model` */
export type FieldPath<Model, PathToField extends Path<Model>> = PathToField

/**
 * Function that wraps vee-validate's `useField` to provide
 * better typings. Especially useful when working with nested objects.
 *
 * Our customized `useField` returns an object that contains two attributes
 * `props` and `listeners`, which are designed to be passed down directly
 * to our custom form field components through `v-bind` and `v-on` vue directives.
 *
 * @example
 * <!-- PARENT COMPONENT -->
 * <script>
 *      // Setup form context for the parent component and its children.
 *      const { validate } = useForm({
 *          validationSchema: campaignValidator,
 *          initialValues: {},
 *      })
 * </script>
 *
 * <!-- CHILD COMPONENT -->
 * <script>
 *      // Create useField for the first custom question of a campaign
 *      // This allows the child component to hook up to the parent's form context.
 *      const useField = makeUseField<Campaign, CustomQuestion>()('customQuestions.0')
 *      const customQuestionTextField = useField('questionText')
 * </script>
 *
 * <template>
 *      <CustomTextField
 *          v-bind="customQuestionTextField.props.value"
 *          v-on="customQuestionTextField.listeners.value"
 *      />
 * </template>
 */
export const makeUseField =
    <RootModel, NestedModel = RootModel>(
        pathFromRootToNested?: Path<RootModel>
    ) =>
    <KeyType extends keyof NestedModel>(fieldName: KeyType) => {
        type ValueType = NestedModel[KeyType]
        const vvField = useField<ValueType | undefined>(
            pathFromRootToNested
                ? `${pathFromRootToNested}.${String(fieldName)}`
                : String(fieldName)
        )
        const props = computed<FormFieldProps<ValueType | undefined>>(() => ({
            name: unref(vvField.name),
            modelValue: vvField.value.value,
            errorTKey: vvField.errorMessage.value as TKey | undefined,
        }))
        const listeners = computed<FormFieldListeners<ValueType>>(() => ({
            'update:modelValue': ($event: ValueType) =>
                (vvField.value.value = $event),
        }))

        return {
            value: vvField.value,
            errors: vvField.errors,
            props,
            listeners,
        }
    }

export const applyI18nToSelectOptions = <T>(
    options: Array<{ label: TKey; value: T }>
) => {
    const { t } = useI18n()
    return options.map((option) => ({
        ...option,
        label: t(option.label),
    }))
}
