<template>
    <ContainerBoxShadow class="relative">
        <div class="flex flex-row justify-end">
            <div
                @click="onClickRemoveCustomQuestion"
                class="text-primary text-xs cursor-pointer"
            >
                <span class="underline inline-block -translate-y-0.5"
                    >Remove question</span
                >
                <i class="fi fi-rs-trash"></i>
            </div>
        </div>
        <div
            class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 mt-5 mb-3"
        >
            <div class="flex-1 lg:flex-auto lg:w-2/3">
                <AVInput
                    v-bind="questionTextField.props.value"
                    v-on="questionTextField.listeners.value"
                    :label="'Question'"
                    :placeholder="'Ex: What is your address?'"
                />
            </div>
            <div class="flex-1 lg:flex-auto lg:w-1/3">
                <AVSelect
                    class="h-full"
                    v-bind="questionTypeField.props.value"
                    v-on="questionTypeField.listeners.value"
                    :label="'Question type'"
                    :options="applyI18nToSelectOptions(QUESTION_TYPE_OPTIONS)"
                />
            </div>
        </div>

        <CustomQuestionTextFields
            v-if="questionTypeField.value.value == 'text'"
            :question-path="questionPath"
        />
        <CustomQuestionSelectFields
            v-if="questionTypeField.value.value == 'select'"
            :question-path="questionPath"
        />
        <div
            v-if="!displayAdvancedOptions"
            @click="displayAdvancedOptions = true"
            class="text-primary text-xs underline cursor-pointer"
        >
            Show advanced options
        </div>
        <div v-show="displayAdvancedOptions">
            <AVInput
                v-bind="questionPlaceholderField.props.value"
                v-on="questionPlaceholderField.listeners.value"
                :label="'Placeholder'"
                :placeholder="'Ex: Bleu'"
            />
        </div>
        <div
            v-if="displayAdvancedOptions"
            @click="displayAdvancedOptions = false"
            class="text-primary text-xs underline cursor-pointer mt-2"
        >
            Hide advanced options
        </div>
    </ContainerBoxShadow>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
    FieldPath,
    makeUseField,
    applyI18nToSelectOptions,
} from '@/utils/forms'
import ContainerBoxShadow from '@/AppBackoffice/components/ContainerBoxShadow.vue'
import { QUESTION_TYPE_OPTIONS } from '@/models/custom-questions'
import AVInput from '@/components/forms/AVInput.vue'
import AVSelect from '@/components/forms/AVSelect.vue'
import { CustomQuestion } from '@/models/custom-questions'
import { Locale } from '@/i18n'
import CustomQuestionTextFields from './CustomQuestionTextFields.vue'
import CustomQuestionSelectFields from './CustomQuestionSelectFields.vue'
import { CampaignWrite } from '@/models/campaigns'

interface Props {
    questionPath: FieldPath<CampaignWrite, `custom_questions.${number}`>
    language: Locale
}

interface Emits {
    (event: 'update:modelValue', customQuestion: CustomQuestion): void
    (event: 'removeCustomQuestion'): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const displayAdvancedOptions = ref(false)

const useFieldCustomQuestion = makeUseField<CampaignWrite, CustomQuestion>(
    props.questionPath
)
const questionTextField = useFieldCustomQuestion('question')
const questionTypeField = useFieldCustomQuestion('type')
const questionPlaceholderField = useFieldCustomQuestion('placeholder')

const onClickRemoveCustomQuestion = () => {
    emit('removeCustomQuestion')
}
</script>
