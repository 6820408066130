<template>
    <div>
        <LoaderWithOverlay v-if="crudStore.isBusy" />
        <div class="container mx-auto px-4 py-8">
            <RouterView></RouterView>
            <ListHeader
                titleTKey="backoffice.recipients.recipients"
                descriptionTKey="backoffice.recipients.description"
                createActionTKey="backoffice.actions.create"
                createRouteName="recipient_create"
                downloadTKey="backoffice.actions.download"
                @download-clicked="extraStore.downloadListAsCsv"
            />

            <div
                v-if="
                    !crudStore.listFilters?.filter && crudStore.isListDataEmpty
                "
            >
                <ListEmptyLayout :routeName="'recipient_create'">
                    <template #no-element-msg>
                        <p>There are no recipients yet.</p>
                    </template>
                    <template #create-element-msg>
                        Create your first recipient
                    </template>
                </ListEmptyLayout>
            </div>
            <div v-else>
                <div
                    v-if="!crudStore.isListBusy && crudStore.listMeta"
                    class="container py-2 w-full"
                >
                    <div class="bg-white rounded-lg shadow-sm">
                        <div class="overflow-x-auto">
                            <PaginationAndFilter
                                :list-config="crudStore.listConfig"
                                :list-filters="crudStore.listFilters"
                                :list-meta="crudStore.listMeta"
                                route-name="recipients_list"
                            />
                            <table class="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr
                                        class="text-left text-gray-600 text-sm h-16"
                                    >
                                        <ColumnHeader
                                            v-for="column in COLUMNS"
                                            :key="column"
                                            :label="
                                                t(
                                                    `backoffice.person.${camelCase(
                                                        column
                                                    )}`
                                                )
                                            "
                                            :column="column"
                                            :sort="{
                                                sortOrder: 'asc',
                                                sortByColumn: column,
                                            }"
                                        />
                                        <th class="px-8 py-2"></th>
                                    </tr>
                                </thead>
                                <tbody
                                    class="text-gray-700 divide-y divide-gray-200"
                                >
                                    <TableRow
                                        v-for="recipient in crudStore.listData"
                                        :key="recipient.email"
                                        :recipient="recipient"
                                    />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import camelCase from 'camelcase'
import { onBeforeMountOrPropChange } from '@/utils/components'
import { RouterView } from 'vue-router'
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import PaginationAndFilter from '@/AppBackoffice/components/PaginationAndFilter.vue'
import ListEmptyLayout from '@/AppBackoffice/components/ListEmptyLayout.vue'
import ListHeader from '@/AppBackoffice/components/ListHeader.vue'
import ColumnHeader from '@/AppBackoffice/components/ListTable/ColumnHeader.vue'
import TableRow from './TableRow.vue'
import {
    useRecipientsBackofficeCrudStore,
    useRecipientsExtraStore,
} from './stores'
import { Recipient } from '@/models/recipients'
import { useI18n } from 'vue-i18n'

interface Props {
    page: number
    filter?: string
}

const COLUMNS: Array<keyof Recipient> = ['last_name', 'first_name', 'email']

const props = defineProps<Props>()
const { t } = useI18n()
const crudStore = useRecipientsBackofficeCrudStore()
const extraStore = useRecipientsExtraStore()

const onListConfigUpdated = () => {
    crudStore.runList({
        page: props.page,
        filter: props.filter,
    })
}

onBeforeMountOrPropChange(
    [() => props.page, () => props.filter],
    onListConfigUpdated
)
</script>
