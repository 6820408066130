<template>
    <div class="mb-12 relative overflow-hidden" ref="container">
        <Transition
            name="crossfade"
            v-for="({ title, subtitle }, index) in titles"
            :key="index"
        >
            <div
                class="text-left md:text-center absolute top-0 left-0 w-full"
                :class="{
                    invisible: isInitialized === false,
                }"
                v-if="isInitialized === false || titleIndex === index"
            >
                <span
                    class="text-base lg:text-2xl font-bold text-black mb-2"
                    :class="{
                        'text-red-700': errorHappened,
                    }"
                >
                    {{ title }}
                    <ThreeDotsFlashing v-if="isLoading" />
                </span>
                <div v-if="subtitle">{{ subtitle }}</div>
                <div class="w-full text-center mt-5">
                    <button
                        v-if="isInitialized === false || errorHappened"
                        class="mt-4 lg:mt-0 bg-primary hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-2xl"
                        @click="store.reformulateContentSample()"
                    >
                        {{ $t('user.campaign.generate_new_message') }}
                    </button>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'
import { onMounted, ref } from 'vue'
import ThreeDotsFlashing from './ThreeDotsFlashing.vue'
import { watch } from 'vue'
import { useSendCampaignStore } from '@/AppCampaigns/stores'

const store = useSendCampaignStore()

interface Props {
    titles: Array<{ title: string; subtitle?: string }>
    titleIndex?: number
    isLoading?: boolean
    errorHappened?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    titleIndex: 0,
    isLoading: false,
    errorHappened: false,
})

const { width: windowW, height: windowH } = useWindowSize()
const isInitialized = ref(false)
const container = ref<HTMLDivElement>()
const sizeComputed = ref<boolean>(false)

/**
 * On mounted, compute the height of the highest title that will be displayed
 * that way when switching we avoid the container from resizing, and causing
 * the page to jump.
 */
const computeComponentHeight = () => {
    if (sizeComputed.value) {
        return
    }
    isInitialized.value = false
    let maxHeight = Math.max(
        ...Array.from(container.value!.querySelectorAll(':scope > *')).map(
            (elem) => elem.getBoundingClientRect().height
        )
    )
    container.value!.style.height = `${maxHeight}px`
    isInitialized.value = true
    sizeComputed.value = true
}

watch([windowW, windowH], computeComponentHeight)
watch(() => props.titleIndex, computeComponentHeight)
onMounted(computeComponentHeight)
</script>
<style scoped>
.crossfade-enter-active,
.crossfade-leave-active {
    transition:
        transform 0.2s linear,
        opacity 0.2s linear;
}

.crossfade-enter-from,
.crossfade-leave-to {
    opacity: 0;
}

.crossfade-enter-from {
    transform: translateY(-200%);
}

.crossfade-leave-to {
    transform: translateY(200%);
}
</style>
