import { useI18n } from 'vue-i18n'
import { useToastsStore } from '../toasts'

export const useCrudShared = () => {
    const toastsStore = useToastsStore()
    const { t } = useI18n()
    const _addErrorToast = () => {
        toastsStore.addMessage(t('backoffice.messages.error'), 'danger')
    }
    return { _addErrorToast }
}
