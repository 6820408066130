<template>
    <div ref="toasts-container" class="fixed top-4 right-10 z-[999]">
        <ToastMessage
            v-for="msg of messages"
            :key="msg.id"
            :id="msg.id"
            :message="msg.message ? msg.message : t(msg.messageTKey!)"
            :message-type="msg.type"
        />
    </div>
</template>

<script setup lang="ts">
import ToastMessage from './ToastMessage.vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'

import { useToastsStore } from '@/stores/toasts'

const { t } = useI18n()
const toastsStore = useToastsStore()

const { messages } = storeToRefs(toastsStore)
</script>
