import { RouteRecordRaw } from 'vue-router'
import CampaignCreateUpdateView from './CampaignCreateUpdateView/CampaignCreateUpdateView.vue'
import CampaignReadView from './CampaignReadView/CampaignReadView.vue'
import CampaignsListView from './CampaignsListView.vue'
import CampaignDeleteModal from './CampaignDeleteModal.vue'
import CampaignsAnalytics from './CampaignsAnalytics.vue'
import {
    propagatePreviousRouteQuery,
    routeToListViewProps,
} from '../utils/routes'

const recipientsRoutes: Array<RouteRecordRaw> = [
    {
        path: 'campaigns',
        name: 'campaigns_list',
        component: CampaignsListView,
        children: [
            {
                path: 'delete/:campaignId',
                name: 'campaign_delete_inPlace',
                component: CampaignDeleteModal,
                props: (route) => ({
                    campaignId: route.params.campaignId,
                    routeNameOnClose: 'campaigns_list',
                }),
                beforeEnter: [propagatePreviousRouteQuery],
            },
        ],
        props: routeToListViewProps,
    },
    {
        path: 'campaigns/update/:campaignId',
        name: 'campaign_edit',
        component: CampaignCreateUpdateView,
        props: (route) => ({
            campaignId: route.params.campaignId,
        }),
    },
    {
        path: 'campaigns/analytics',
        name: 'analytics',
        component: CampaignsAnalytics,
    },
    {
        path: 'campaigns/:campaignId',
        name: 'campaign_view',
        component: CampaignReadView,
        props: (route) => ({
            campaignId: route.params.campaignId,
        }),
    },
    {
        path: 'campaigns/create',
        name: 'campaign_create',
        component: CampaignCreateUpdateView,
    },
]

export default recipientsRoutes
