import TRANSLATIONS_FR from './translations/fr.json'
import TRANSLATIONS_EN from './translations/en.json'

export type TranslationsSchema = { backoffice: typeof TRANSLATIONS_EN }

export const LOCALES_AVAILABLE = ['en', 'fr'] as const

export const TRANSLATIONS: { [locale: string]: TranslationsSchema } = {
    fr: {
        backoffice: TRANSLATIONS_FR,
    },
    en: {
        backoffice: TRANSLATIONS_EN,
    },
} as const
