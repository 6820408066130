<template>
    <div
        class="copy-button-container relative"
        @click="copyToClipboard"
        :class="copyButtonClassObject"
    >
        <span class="copy-button idle">
            <i class="fi fi-rs-copy-alt text-xs"></i>
            <span class="copy-label">{{
                t('user.campaign.comment_copy')
            }}</span>
        </span>
        <span class="copy-button copied">
            <i class="fi fi-rs-check text-xs"></i>
            <span class="copy-label">{{ t('user.common.copied') }}</span>
        </span>
    </div>
</template>

<script setup lang="ts">
import { toRef } from 'vue'
import { useCopyButton } from '../utils'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

interface Props {
    contentToCopy: string
}

const props = defineProps<Props>()

const { copyToClipboard, copyButtonClassObject } = useCopyButton(
    toRef(props, 'contentToCopy')
)
</script>

<style scoped>
.copy-button-container {
    margin-top: var(--spacing-1);
}

.copy-button {
    position: absolute;
    left: 0;
    top: 0;
    color: var(--color-primary);
    cursor: pointer;
    line-height: 1.2em;
}

.copy-label {
    text-decoration: underline;
}

.copy-button:first-child {
    position: initial;
}

.copy-button i {
    font-size: 120%;
    position: relative;
    top: 0.2em;
    margin-right: 0.25em;
}

.copy-button.idle {
    opacity: 1;
}

.copy-button.copied {
    opacity: 0;
}

.copy-button-container.copied .copy-button.idle {
    opacity: 0;
}

.copy-button-container.copied .copy-button.copied {
    opacity: 1;
}

.copy-button {
    transition: opacity 0.5s;
}

.copy-button i {
    margin-left: 2px;
}
</style>
