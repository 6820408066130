<template>
    <CenteredContainer class="md:w-fit mt-4 space-y-5">
        <AVButton
            v-for="{ href, label } in sendLinks"
            :key="href"
            class="md:max-w-[20em] w-full font-semibold px-10 flex flex-row justify-between items-center"
            button-type="secondary"
            type="link"
            :href="href"
            @click="() => emit('sendButtonClicked')"
        >
            <span v-if="sendLinks.length === 1">
                {{ getButtonLabelForSingleSendLink(label) }}
            </span>
            <span v-else>
                {{ label }}
            </span>
            <i
                class="fi fi-rs-angle-right relative bold text-[80%] top-[0.18em] ml-3"
            />
        </AVButton>
        <div class="text-[12px]">
            {{ t('user.email.courtesy_warning') }}
        </div>
    </CenteredContainer>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import CenteredContainer from '../../CenteredContainer.vue'
import AVButton from '@/components/forms/AVButton.vue'
import { SendButtonsEmits } from '../../types'

const { t } = useI18n()

interface Props {
    sendLinks: Array<{ href: string; label: string }>
}

interface Emits extends SendButtonsEmits {}

defineProps<Props>()
const emit = defineEmits<Emits>()

const store = useSendCampaignStore()

const getButtonLabelForSingleSendLink = (label: string) => {
    switch (store.interpellationMode) {
        case 'facebook':
            return t('user.campaign.interpellation_mode.facebook')
        case 'instagram':
            return t('user.campaign.interpellation_mode.instagram')
        default:
            return label
    }
}
</script>
