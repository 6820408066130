<template>
    <div class="flex flex-col justify-center text-center w-full h-full">
        <div class="text-lg">{{ title }}</div>
    </div>
</template>
<script setup lang="ts">
import { RequestTrackerError } from '@/utils/request-tracker'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

interface Props {
    error: RequestTrackerError
}

const props = defineProps<Props>()

const { t } = useI18n()

const title = computed(() => {
    if (props.error.status === 404) {
        return t('embed.loadingError.description404')
    } else {
        return t('embed.loadingError.description')
    }
})
</script>
