import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Message } from '@/types'
import { TKey } from '@/i18n'

export const useToastsStore = defineStore('ToastStore', () => {
    // STATE
    const messages = ref<Array<Message>>([])

    // ACTIONS
    const addMessage = (
        message: string,
        type: Message['type'] = 'info',
        timeout: number | null = 5000
    ) =>
        _addMessage({
            id: _getRandomId(),
            message,
            messageTKey: null,
            type,
            timeout,
        })

    const addMessageTKey = (
        messageTKey: TKey,
        type: Message['type'] = 'info',
        timeout: number | null = 5000
    ) =>
        _addMessage({
            id: _getRandomId(),
            message: null,
            messageTKey,
            type,
            timeout,
        })

    const _addMessage = (messageObj: Message) => {
        messages.value.push(messageObj)
        if (messageObj.timeout) {
            setTimeout(() => {
                closeMessage(messageObj.id)
            }, messageObj.timeout)
        }
    }

    const _getRandomId = () => Math.random().toString(36)

    const closeMessage = (id: string) => {
        messages.value = messages.value.filter((message) => message.id !== id)
    }

    return {
        messages,
        closeMessage,
        addMessage,
        addMessageTKey,
    }
})
