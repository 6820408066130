<template>
    <StepContainer>
        <StepTitle
            :titles="[
                {
                    title: t('user.email.personalize_your_email'),
                    subtitle:
                        t('user.email.your_response_will_be_used') +
                        '\n' +
                        t('user.email.names_not_stored'),
                },
            ]"
        />
        <StepContainerInner>
            <form class="w-full lg:px-[75px]" @submit.prevent="onSubmit(true)">
                <SenderEmailInterpellationQuestions
                    v-if="store.interpellationMode === 'email'"
                />
                <SenderFormCustomQuestions v-if="hasCustomQuestions" />
                <CenteredContainer class="text-center mt-10">
                    <AVButton
                        type="submit"
                        button-type="primary"
                        class="main-button w-full md:w-fit inline-block"
                    >
                        {{ t('user.email.create_customized_email') }}
                        <i
                            class="fi fi-rs-arrow-alt-right inline-block text-[22px] h-0 mt-[-10px] ml-[5px] translate-y-[6px]"
                        ></i>
                    </AVButton>
                    <AVButton
                        v-if="hasCustomQuestions"
                        @click="onSubmit(false)"
                        button-type="link"
                        class="text-xs mt-8"
                    >
                        {{ t('user.email.continue_without_customization') }}
                    </AVButton>
                </CenteredContainer>
            </form>
        </StepContainerInner>
    </StepContainer>
</template>

<script setup lang="ts">
import AVButton from '@/components/forms/AVButton.vue'
import StepTitle from '../StepTitle.vue'
import SenderFormCustomQuestions from './SenderFormCustomQuestions.vue'
import SenderEmailInterpellationQuestions from './SenderEmailInterpellationQuestions.vue'
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import { computed, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'
import {
    useSendCampaignStore,
    useSendCampaignWizardStore,
} from '@/AppCampaigns/stores'
import { makeSenderQuestionsValidator } from './validation'
import CenteredContainer from '../CenteredContainer.vue'
import StepContainer from '../StepContainer.vue'
import StepContainerInner from '../StepContainerInner.vue'
import { Sender } from '../types'

const { t } = useI18n()
const validationSchema = computed(() =>
    toTypedSchema(
        makeSenderQuestionsValidator(
            store.interpellationMode!,
            store.customQuestions
        )
    )
)
const store = useSendCampaignStore()
const wizardStore = useSendCampaignWizardStore()

const { validate } = useForm({
    validationSchema,
    initialValues: store.sender,
})

const hasCustomQuestions = computed(() => {
    return (
        store.campaign!.custom_questions &&
        store.campaign!.custom_questions.length > 0
    )
})

const onSubmit = async (shouldCustomizeEmail: boolean) => {
    const { valid: isValid, values } = await validate()
    if (!shouldCustomizeEmail) {
        store.setSender(null, shouldCustomizeEmail)
        wizardStore.goToNextStep()
    } else if (isValid) {
        store.setSender(values! as Sender, shouldCustomizeEmail)
        wizardStore.goToNextStep()
    }
}

onBeforeMount(() => {
    if (!store.campaign) {
        throw new Error('Store should have am email template set')
    }

    if (!store.interpellationMode) {
        throw new Error('Store should have an interpellation mode set')
    }
})
</script>
