<template>
    <div class="flex space-x-2">
        <div class="relative">
            <input
                :class="{
                    'border border-gray-300 text-gray-900 text-[13px] rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5': true,
                    'bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500':
                        errorTKey,
                }"
                :name="name"
                :id="name"
                type="checkbox"
                :checked="modelValue"
                @input="onChange"
            />
        </div>
        <div>
            <label
                :class="{
                    'block mb-2 text-[15px] font-medium lg:font-normal text-gray-900': true,
                    'text-red-700 ': errorTKey,
                }"
                v-if="label"
                :for="name"
                >{{ label }}</label
            >
        </div>
        <AVError v-if="errorTKey" :error-t-key="errorTKey" class="flex-1" />
    </div>
</template>

<script setup lang="ts">
import AVError from '@/components/forms/AVError.vue'
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'

interface Props extends FormFieldProps<boolean | undefined> {}
interface Emits extends FormFieldEmits<boolean> {
    (event: 'blur', domEvent: Event): void
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const onChange = (event: Event) => {
    emit('update:modelValue', (event.target as HTMLInputElement).checked)
}
</script>
