import WebFont from 'webfontloader'

export const loadWebFonts = () =>
    new Promise<void>((resolve) => {
        WebFont.load({
            google: {
                families: [
                    'Open Sans:300,400,600,700',
                    'Montserrat:400,500,600,700',
                ],
            },
            custom: {
                families: ['uicons-regular-straight', 'uicons-thin-rounded'],
                urls: [
                    'https://cdn-uicons.flaticon.com/uicons-regular-straight/css/uicons-regular-straight.css',
                    'https://cdn-uicons.flaticon.com/2.1.0/uicons-thin-rounded/css/uicons-thin-rounded.css',
                    'https://cdn-uicons.flaticon.com/2.4.0/uicons-brands/css/uicons-brands.css',
                ],
            },
            timeout: 4000,
            active: resolve,
            inactive: () => {
                resolve()
            },
        })
    })
