<template>
    <hr class="sidebar-hr" />
</template>

<style scoped>
.sidebar-hr {
    background-image: linear-gradient(
        90deg,
        transparent,
        rgba(0, 0, 0, 0.4),
        transparent
    );
    background-color: transparent;
    opacity: 0.25;
    border-style: inset;
    border-width: 1px;
    border-top: none !important;
    height: 1px;
    border: 0;
}
</style>
