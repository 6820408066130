<template>
    <AVSelect
        name="languagePicker"
        class="language-picker w-32 text-sm"
        :options="applyI18nToSelectOptions(i18nStore.localeOptions)"
        v-model="locale"
        @update:model-value="i18nStore.persistLocale"
    />
</template>

<script setup lang="ts">
import { useI18CustomStore } from '@/i18n'
import { applyI18nToSelectOptions } from '@/utils/forms'
import AVSelect from '@/components/forms/AVSelect.vue'
import { useI18n } from 'vue-i18n'
const i18nStore = useI18CustomStore()
const { locale } = useI18n()
</script>
<style scoped>
.language-picker select {
    font-size: 0.8rem;
}
</style>
