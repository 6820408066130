import axios from 'axios'
import { API_ROOT_URL } from '@/constants'
import { operations } from '@/schema'
import { ExtractQueryParams } from '@/types'
import { assertTypesMatch, SameKeysGuard } from '@/utils/types'
import { serializeQueryParams } from '@/utils/requests'
import { InterpellationMode } from '@/models/campaigns'
import { ModelId } from '@/models/types'

export interface GeneratedContentListParams {
    page: number
    created_start?: Date
    created_end?: Date
    campaign_id?: ModelId
    interpellation_id?: ModelId
    interpellation_mode?: InterpellationMode
}

// Check that definition of query params match with the generated schema
assertTypesMatch<
    SameKeysGuard<
        ExtractQueryParams<
            operations['activoice_genai_generated_content_list']['parameters']['query']
        >,
        GeneratedContentListParams
    >
>()

export const listGeneratedContents = (params: GeneratedContentListParams) =>
    axios.get(`${API_ROOT_URL}/activoice/genai/generated-content/`, {
        params: serializeQueryParams(params),
    })
