<template>
    <div>
        <div v-if="crudStore.isBusy" class="text-center pt-10">
            <AVLoader />
        </div>
        <div
            v-if="!!campaign && extraStore.campaignStats !== null"
            class="container mx-auto px-4 py-8"
        >
            <div
                class="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-6"
            >
                <div class="md:max-w-[500px]">
                    <h1 class="text-2xl font-semibold text-gray-900">
                        {{ campaign.name }}
                    </h1>
                    <p class="text-gray-600">{{ campaign.description }}</p>
                    <p class="text-gray-600">
                        {{ t('backoffice.campaigns.language') }} :
                        {{ extraStore.campaignStats[0]['campaign_language'] }}
                    </p>
                </div>
                <div>
                    <AVButton
                        @click="onClickOpenEmbedCodeModal"
                        button-type="outline-primary"
                        class="mr-3 mb-2"
                    >
                        Embed campaign on your website
                    </AVButton>
                    <AVButton
                        button-type="outline-primary"
                        class="mr-3"
                        @click="onClickDuplicate"
                        >{{ t('backoffice.actions.duplicate') }}</AVButton
                    >
                    <AVButton @click="goToCampaignEdit(props.campaignId)"
                        >Edit</AVButton
                    >
                </div>
            </div>
            <div
                class="grid grid-cols-1 md:grid-cols-4 gap-0 rounded-lg bg-gray-100 mb-3"
            >
                <div class="p-4">
                    <div class="flex items-center">
                        <div class="p-4 bg-gray-500 rounded-full mr-4"></div>
                        <div>
                            <p class="text-gray-600 font-normal">
                                {{
                                    t(
                                        'backoffice.campaigns.numberInterpellations'
                                    )
                                }}
                            </p>
                            <p class="text-xl text-gray-700 font-semibold">
                                {{ extraStore.campaignStats.length }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4">
                    <div class="flex items-center">
                        <div class="p-4 bg-gray-500 rounded-full mr-4"></div>
                        <div>
                            <p class="text-gray-600 font-normal">
                                {{ t('backoffice.campaigns.numberRecipients') }}
                            </p>
                            <p class="text-xl text-gray-700 font-semibold">
                                {{ totalRecipients }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4">
                    <div class="flex items-center">
                        <div class="p-4 bg-gray-500 rounded-full mr-4"></div>
                        <div>
                            <p class="text-gray-600 font-normal">
                                {{
                                    t(
                                        'backoffice.campaigns.numberEmailGenerated'
                                    )
                                }}
                            </p>
                            <p class="text-xl text-gray-700 font-semibold">
                                {{ totalEmailsGenerated }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="p-4">
                    <div class="flex items-center">
                        <div class="p-4 bg-gray-500 rounded-full mr-4"></div>
                        <div>
                            <p class="text-gray-600 font-normal">
                                {{
                                    t(
                                        'backoffice.campaigns.numberCommentsGenerated'
                                    )
                                }}
                            </p>
                            <p class="text-xl text-gray-700 font-semibold">
                                {{ totalCommentsGenerated }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div
                v-for="(
                    interpellation, interpellationIndex
                ) in campaign.interpellations as Interpellation[]"
                :key="interpellation.id"
                class="bg-white rounded-lg p-3 mb-4"
            >
                <div class="font-semibold my-2">
                    Interpellation #{{ interpellationIndex + 1 }}
                </div>
                <div>{{ interpellation.name }}</div>
                <hr class="my-3" />
                <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-3">
                    <div class="bg-gray-100 p-4">
                        <p class="text-gray-600 font-normal">
                            {{ t('backoffice.campaigns.numberRecipients') }}
                        </p>
                        <p class="text-xl text-gray-700 font-semibold">
                            {{
                                interpellationRecipients(
                                    interpellation.id as string
                                ).length
                            }}
                        </p>
                    </div>
                    <div class="bg-gray-100 p-4">
                        <p class="text-gray-600 font-normal">
                            {{ t('backoffice.campaigns.numberEmailGenerated') }}
                        </p>
                        <p class="text-xl text-gray-700 font-semibold">
                            {{
                                interpellationGeneratedEmailNumber(
                                    interpellation.id as string
                                )
                            }}
                        </p>
                    </div>
                    <div class="bg-gray-100 p-4">
                        <p class="text-gray-600 font-normal">
                            {{
                                t(
                                    'backoffice.campaigns.numberCommentsGenerated'
                                )
                            }}
                        </p>
                        <p class="text-xl text-gray-700 font-semibold">
                            {{
                                interpellationGeneratedCommentNumber(
                                    interpellation.id as string
                                )
                            }}
                        </p>
                    </div>
                    <!-- add other destinataries infos -->
                </div>
                <hr class="my-3" />
                <div>
                    <div class="font-semibold">Send To</div>
                    <div
                        v-for="recipient in interpellation.send_to"
                        :key="recipient.email"
                        class="inline-block text-gray-600 bg-gray-50 rounded-lg p-2 mr-3 mt-2"
                    >
                        {{ recipient.email }}
                    </div>
                </div>
                <div v-if="interpellation.cc_list.length > 0">
                    <div class="font-semibold">Cc list</div>
                    <div
                        v-for="recipient in interpellation.cc_list"
                        :key="recipient.email"
                        class="inline-block text-gray-600 bg-gray-50 rounded-lg p-2 mr-3 mt-2"
                    >
                        {{ recipient.email }}
                    </div>
                </div>
                <div v-if="interpellation.bcc_list.length > 0">
                    <div class="font-semibold">Bcc list</div>
                    <div
                        v-for="recipient in interpellation.bcc_list"
                        :key="recipient.email"
                        class="inline-block text-gray-600 bg-gray-50 rounded-lg p-2 mr-3 mt-2"
                    >
                        {{ recipient.email }}
                    </div>
                </div>
                <hr class="my-3" />
                <p class="font-semibold mb-3">Content samples</p>
                <div
                    v-for="(
                        contentSample, index
                    ) in interpellation.content_samples"
                    :key="index"
                    class="bg-gray-50 rounded-md p-3 mb-3"
                >
                    <ContentSampleEmailDisplay
                        v-if="contentSample.type === 'email'"
                        :content-sample="contentSample as ContentSampleEmail"
                    />
                    <ContentSampleCommentDisplay
                        v-if="contentSample.type === 'comment'"
                        :content-sample="contentSample as ContentSampleComment"
                    />
                </div>
            </div>
            <div
                class="bg-white rounded-lg p-3 mb-4"
                v-if="
                    campaign.custom_questions &&
                    campaign.custom_questions.length > 0
                "
            >
                <p class="font-semibold">Custom questions</p>
                <ul class="list-disc list-inside">
                    <li
                        v-for="(question, index) in campaign.custom_questions"
                        :key="index"
                    >
                        {{ question.question }}
                    </li>
                </ul>
            </div>
        </div>
        <EmbedCodeModal
            v-if="isShowingEmbedCodeModal && crudStore.itemData"
            :campaign="crudStore.itemData"
            @close-modal="onCloseEmbedCodeModal"
        >
        </EmbedCodeModal>
    </div>
</template>

<script setup lang="ts">
import AVLoader from '@/components/AVLoader.vue'
import AVButton from '@/components/forms/AVButton.vue'
import EmbedCodeModal from './EmbedCodeModal.vue'
import {
    useCampaignBackofficeCrudStore,
    useCampaignExtraStore,
} from '../stores'
import { onMounted, computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { Interpellation } from '@/models/interpellations'
import ContentSampleEmailDisplay from './ContentSampleEmailDisplay.vue'
import ContentSampleCommentDisplay from './ContentSampleCommentDisplay.vue'
import {
    ContentSampleComment,
    ContentSampleEmail,
} from '@/models/content-samples'

const { t } = useI18n()

const router = useRouter()

interface Props {
    campaignId: string
}

const props = defineProps<Props>()

const crudStore = useCampaignBackofficeCrudStore()
const extraStore = useCampaignExtraStore()

const isShowingEmbedCodeModal = ref(false)

const interpellationRecipients = (id: string) =>
    extraStore.campaignStats!.find((item) => item.id === id)!.recipients_list

const interpellationGeneratedEmailNumber = (id: string) =>
    extraStore.campaignStats!.find((item) => item.id === id)!
        .generated_email_number

const interpellationGeneratedCommentNumber = (id: string) =>
    extraStore.campaignStats!.find((item) => item.id === id)!
        .generated_facebook_number

const totalRecipients = computed(() => {
    if (!extraStore.campaignStats) {
        return 0
    }
    return extraStore.campaignStats
        .map((item) => item.recipients_list.length)
        .reduce((acc: number, curr: number) => acc + curr, 0)
})

const totalEmailsGenerated = computed(() => {
    if (!extraStore.campaignStats) {
        return 0
    }
    return extraStore.campaignStats
        .map((item) => item.generated_email_number)
        .reduce((acc: number, curr: number) => acc + curr, 0)
})

const totalCommentsGenerated = computed(() => {
    if (!extraStore.campaignStats) {
        return 0
    }
    return extraStore.campaignStats
        .map((item) => item.generated_facebook_number)
        .reduce((acc: number, curr: number) => acc + curr, 0)
})

const { itemData: campaign } = storeToRefs(crudStore)

const onClickDuplicate = () => extraStore.duplicateCampaign(props.campaignId)

const onClickOpenEmbedCodeModal = () => (isShowingEmbedCodeModal.value = true)
const onCloseEmbedCodeModal = () => (isShowingEmbedCodeModal.value = false)

const resetComponent = async () => {
    crudStore.resetItemState()
    await extraStore.getCampaignStats(props.campaignId)
    await crudStore.runRead(props.campaignId)
}

function goToCampaignEdit(campaignId: string) {
    router.push({
        name: 'campaign_edit',
        params: { campaignId },
    })
}

onMounted(resetComponent)
</script>
