import { useI18n } from 'vue-i18n'
import { useToastsStore } from '@/stores/toasts'

export const usePredefinedToasts = () => {
    const toastsStore = useToastsStore()
    const { t } = useI18n()
    return {
        success: () =>
            toastsStore.addMessage(
                t('backoffice.campaigns.messageUpdated'),
                'success'
            ),
        error: () =>
            toastsStore.addMessage(
                t('backoffice.campaigns.messageError'),
                'danger'
            ),
    }
}
