<template>
    <div class="flex w-full bg-white border-b text-[11px]">
        <!-- "Previous" button section -->
        <div
            :class="`flex flex-col justify-center items-center w-[46px] ${hasPreviousButton ? 'border-r border-gray-300' : ''}`"
        >
            <div @click="onPreviousStepClicked" class="cursor-pointer">
                <i
                    class="fi fi-rs-angle-left translate-y-0.5 text-primary text-lg inline-block"
                    v-if="hasPreviousButton"
                ></i>
            </div>
        </div>

        <!-- CircleProgress and text description section -->
        <div class="flex w-10/12 py-2">
            <!-- CircleProgress section -->
            <div class="w-2/12 ml-3 mr-0 flex justify-center items-center">
                <slot name="circle"></slot>
            </div>

            <!-- Steps description section -->
            <div class="flex-1 flex flex-col justify-center">
                <div class="font-semibold">
                    {{ t(titleTKey) }}
                </div>
                <div v-if="descriptionTKey" class="font-thin">
                    {{ t(descriptionTKey) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { TKey } from '@/i18n'
import { useI18n } from 'vue-i18n'

interface Props {
    hasPreviousButton: boolean
    titleTKey: TKey
    descriptionTKey?: TKey
}

interface Emits {
    (event: 'previous'): void
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()

const onPreviousStepClicked = () => {
    emit('previous')
}
</script>
