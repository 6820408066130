import { RequestStatus } from '@/types'
import {
    BackendValidationError,
    convertValidationErrors,
} from '@/utils/validation-errors'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useToastsStore } from '../toasts'
import { useRequestTracker } from '../../utils/request-tracker'
import { useCrudShared } from './shared'
import { ListOperations } from './list'
import { CrudRequests, GenericItem } from './types'
import { ModelId } from '@/models/types'
import { TKey } from '@/i18n'
import { HttpStatusCode } from 'axios'
import { ListFiltersBase } from '@/AppBackoffice/types'

export interface SuccessConfig {
    nextRouteName: string
    setIdParam?: boolean
    message?: TKey
}

export const useCrudOperations = <
    T extends GenericItem,
    WriteT,
    ListFilters extends ListFiltersBase = ListFiltersBase,
>(
    apiRequests: Partial<CrudRequests<T, WriteT>>,
    listOperations?: ListOperations<T, ListFilters>
) => {
    const { _addErrorToast } = useCrudShared()
    const requestTracker = useRequestTracker<T>()
    const router = useRouter()
    const toastsStore = useToastsStore()
    const { t } = useI18n()

    // ACTIONS
    const runCreate = async (item: WriteT, successConfig?: SuccessConfig) => {
        if (!apiRequests.createRequest) {
            throw new Error('Not implemented')
        }

        const requestStatus = await requestTracker.runRequest(
            apiRequests.createRequest,
            HttpStatusCode.Created,
            [item]
        )
        if (requestStatus === RequestStatus.SUCCESS) {
            await afterMutatingRequestSuccess(successConfig)
        } else if (requestStatus === RequestStatus.ERROR) {
            setRequestError()
        }

        return requestStatus
    }

    const runRead = async (itemId: ModelId) => {
        if (!apiRequests.readRequest) {
            throw new Error('Not implemented')
        }

        const requestStatus = await requestTracker.runRequest(
            apiRequests.readRequest,
            HttpStatusCode.Ok,
            [itemId]
        )
        if (requestStatus === RequestStatus.ERROR) {
            setRequestError()
        }
        return requestStatus
    }

    const runUpdate = async (
        itemId: ModelId,
        item: WriteT,
        successConfig?: SuccessConfig
    ) => {
        if (!apiRequests.updateRequest) {
            throw new Error('Not implemented')
        }

        const requestStatus = await requestTracker.runRequest(
            apiRequests.updateRequest,
            HttpStatusCode.Ok,
            [itemId, item]
        )
        if (requestStatus === RequestStatus.SUCCESS) {
            await afterMutatingRequestSuccess(successConfig)
        } else if (requestStatus === RequestStatus.ERROR) {
            setRequestError()
        }
        return requestStatus
    }

    const runDelete = async (
        itemId: ModelId,
        successConfig?: SuccessConfig
    ) => {
        if (!apiRequests.deleteRequest) {
            throw new Error('Not implemented')
        }

        const requestStatus = await requestTracker.runRequest(
            apiRequests.deleteRequest,
            HttpStatusCode.NoContent,
            [itemId]
        )
        if (requestStatus === RequestStatus.SUCCESS) {
            await afterMutatingRequestSuccess(successConfig)
        } else if (requestStatus === RequestStatus.ERROR) {
            setRequestError()
        }
        return requestStatus
    }

    const reset = () => {
        requestTracker.reset()
    }

    const resetValidationErrors = () => {
        requestTracker.state.value.error = null
    }

    const setRequestError = () => {
        if (validationErrors()) {
            _addErrorToast()
        }
    }

    const afterMutatingRequestSuccess = async (
        successConfig?: SuccessConfig
    ) => {
        if (listOperations) {
            await listOperations.runList()
        }
        if (!successConfig) {
            return
        }
        if (successConfig.setIdParam && !requestTracker.getLoadedData()) {
            throw new Error('Crud store cannot set id param without an item')
        }
        toastsStore.addMessage(
            t(successConfig.message || 'backoffice.messages.success'),
            'success'
        )
        const loadedData = requestTracker.getLoadedData() as T
        router.push({
            name: successConfig.nextRouteName,
            query: successConfig.message
                ? { message: successConfig.message }
                : undefined,
            params: successConfig.setIdParam
                ? { id: loadedData.id }
                : undefined,
        })
    }

    // GETTERS
    const hasRequestSucceeded = () =>
        requestTracker.getRequestStatus() === RequestStatus.SUCCESS

    const id = () => {
        const data = requestTracker.getLoadedData() as T | null
        return data && data.id ? data.id : null
    }

    const validationErrors = () => {
        const error = requestTracker.state.value.error
        if (error && error.status === HttpStatusCode.BadRequest) {
            return convertValidationErrors<T>(
                error.data as BackendValidationError
            )
        }
        return null
    }

    return {
        requestTracker,
        // ACTIONS
        runCreate,
        runRead,
        runUpdate,
        runDelete,
        setRequestError,
        afterMutatingRequestSuccess,
        reset,
        resetValidationErrors,
        // GETTERS
        isBusy: requestTracker.isRequestInProgress,
        hasRequestSucceeded,
        validationErrors,
        id,
    }
}
