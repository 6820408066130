<template>
    <div class="flex justify-center items-center">
        <svg width="30" height="30" viewBox="0 0 100 100">
            <!-- Background circle -->
            <circle
                cx="50"
                cy="50"
                r="45"
                style="stroke: var(--color-light-gray); stroke-width: 10"
                fill="none"
            />

            <!-- Foreground circle -->
            <circle
                cx="50"
                cy="50"
                r="45"
                style="stroke: var(--color-primary); stroke-width: 10"
                :fill="progress === 'FULL' ? 'var(--color-primary)' : 'none'"
                :style="circleStyle"
            />

            <!-- Text label at the center -->
            <text
                class="label-text font-medium"
                :class="{
                    'fill-white font-size-[55px]': progress === 'FULL',
                }"
                v-if="label"
                x="50"
                y="55"
                :font-size="40"
                dominant-baseline="middle"
                text-anchor="middle"
            >
                {{ label.toString() }}
            </text>
        </svg>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

interface Props {
    progress: number | 'FULL'
    label: number | string | null
}

const props = defineProps<Props>()

const circleStyle = computed(() => {
    if (props.progress === 'FULL') {
        return {}
    }

    // Circumference of the circle
    const circumference = 2 * Math.PI * 45

    // The visible length of the circle based on the progress
    const dashArray = circumference
    const dashOffset = circumference * (1 - props.progress)

    return {
        strokeDasharray: `${dashArray}`,
        strokeDashoffset: `${dashOffset}`,
    }
})
</script>
<style scoped>
circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.label-text {
    font-size: 40px;
}
</style>
