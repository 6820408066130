import { QueryParamValue } from '@/types'

export const serializeQueryParams = <
    K extends string,
    V extends QueryParamValue,
>(
    params: Partial<Record<K, V>>
): { [k in K]: string } => {
    const queryParams = {} as { [k in K]: string }
    Object.entries(params).forEach(([k, value]) => {
        const key = k as K
        if (value === null || value === undefined) {
            return
        } else if (value instanceof Date) {
            queryParams[key] = value.toISOString()
        } else if (typeof value === 'number') {
            queryParams[key] = value.toString()
        } else if (typeof value === 'string') {
            queryParams[key] = value
        } else {
            throw new Error(`Unsupported query param type: ${typeof value}`)
        }
    })
    return queryParams
}
