import { RouteRecordRaw } from 'vue-router'
import GeneratedContentsListView from './GeneratedContentsListView.vue'
import GeneratedContentReadView from './GeneratedContentReadView.vue'
import { propagatePreviousRouteQuery } from '../utils/routes'
import { Props as GeneratedContentsListViewProps } from './GeneratedContentsListView.vue'
import { Props as GeneratedContentReadViewProps } from './GeneratedContentReadView.vue'
import * as Yup from 'yup'

const generatedContentsListViewShared = {
    page: Yup.number().required().default(1),
    campaign_id: Yup.string(),
    created_end: Yup.date(),
    created_start: Yup.date(),
    interpellation_id: Yup.string(),
    interpellation_mode: Yup.string().oneOf(['twitter', 'facebook', 'email']),
}

const generatedContentsListViewPropsValidator: Yup.ObjectSchema<GeneratedContentsListViewProps> =
    Yup.object().shape(generatedContentsListViewShared)

const generatedContentReadViewPropsValidator: Yup.ObjectSchema<GeneratedContentReadViewProps> =
    Yup.object().shape({
        ...generatedContentsListViewShared,
        generatedContentId: Yup.string().required(),
    })

const generatedContentsRoutes: Array<RouteRecordRaw> = [
    {
        path: 'generated-contents',
        name: 'generated_contents',
        component: GeneratedContentsListView,
        props: (route): GeneratedContentsListViewProps =>
            generatedContentsListViewPropsValidator.cast(route.query),
    },
    {
        path: 'generated-contents/:generatedContentId',
        name: 'generated_contents_read',
        component: GeneratedContentReadView,
        props: (route): GeneratedContentReadViewProps =>
            generatedContentReadViewPropsValidator.cast({
                ...route.query,
                ...route.params,
            }),
        beforeEnter: [propagatePreviousRouteQuery],
    },
]

export default generatedContentsRoutes
