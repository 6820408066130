import { ChangePassword } from '@/models/user'
import * as Yup from 'yup'

export const changePasswordValidator: Yup.ObjectSchema<ChangePassword> =
    Yup.object().shape({
        old_password: Yup.string().required(),
        password: Yup.string().required(),
        password2: Yup.string()
            .required()
            .oneOf([Yup.ref('password')]),
    })
