import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'

const env_name = import.meta.env.VITE_ENV_NAME

let sentry_url = ''

if (env_name == 'STAGING') {
    sentry_url =
        'https://c4aceb20f54539cb24e1a4361933f3ae@o4506212450959360.ingest.sentry.io/4506217969876992'
} else if (env_name == 'PRODUCTION') {
    sentry_url =
        'https://9d95d951592c689035c1047d487733e3@o4506212450959360.ingest.sentry.io/4506217971843072'
} else {
    sentry_url =
        'https://315acd848099a984119d4108fbeb429c@o4506212450959360.ingest.sentry.io/4506212452532224'
}

export function initializeSentry(app: App, router: Router | null = null) {
    Sentry.init({
        app,
        dsn: sentry_url,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    'localhost',
                    'staging.activoice.online',
                    'activoice.online',
                ],
                ...(router
                    ? {
                          routingInstrumentation:
                              Sentry.vueRouterInstrumentation(router),
                      }
                    : {}),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}
