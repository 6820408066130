import { RouteRecordRaw } from 'vue-router'
import RListsListView from './RListsListView.vue'
import RListCreateModal from './RListCreateModal.vue'
import RListUpdateModal from './RListUpdateModal.vue'
import RListDeleteModal from './RListDeleteModal.vue'
import RListUploadView from './RListUploadView.vue'
import {
    propagatePreviousRouteQuery,
    routeToListViewProps,
} from '../utils/routes'

const rListsRoutes: Array<RouteRecordRaw> = [
    {
        path: 'rlists',
        name: 'rlists_list',
        component: RListsListView,
        props: routeToListViewProps,
        children: [
            {
                path: 'create',
                name: 'rlist_create',
                component: RListCreateModal,
                props: { routeNameOnClose: 'rlists_list' },
            },
            {
                path: 'update/:rlistId',
                name: 'rlist_update_inPlace',
                component: RListUpdateModal,
                props: (route) => ({
                    rlistId: route.params.rlistId,
                    routeNameOnClose: 'rlists_list',
                }),
                beforeEnter: [propagatePreviousRouteQuery],
            },
            {
                path: 'delete/:rlistId',
                name: 'rlist_delete_inPlace',
                component: RListDeleteModal,
                props: (route) => ({
                    rlistId: route.params.rlistId,
                    routeNameOnClose: 'rlists_list',
                }),
                beforeEnter: [propagatePreviousRouteQuery],
            },
        ],
    },
    {
        path: 'rlists/upload',
        name: 'rlist_upload',
        component: RListUploadView,
    },
]

export default rListsRoutes
