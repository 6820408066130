<template>
    <div class="user-name-row" v-if="store.interpellationMode == 'email'">
        <AVInput
            class="custom-input w-full mb-6 lg:mb-6"
            v-bind="firstNameField.props.value"
            v-on="firstNameField.listeners.value"
            :label="$t('user.forms.your_first_name')"
            :placeholder="$t('user.forms.first_name')"
        />
        <AVInput
            class="custom-input mt-3 w-full mb-6 lg:mb-6"
            v-bind="lastNameField.props.value"
            v-on="lastNameField.listeners.value"
            :label="$t('user.forms.your_last_name')"
            :placeholder="$t('user.forms.last_name')"
        />
    </div>
</template>
<script setup lang="ts">
import AVInput from '@/components/forms/AVInput.vue'
import { makeUseField } from '@/utils/forms'
import { Sender } from '../types'
import { useSendCampaignStore } from '@/AppCampaigns/stores'

const store = useSendCampaignStore()

const useSenderQuestionField = makeUseField<Sender>()
const firstNameField = useSenderQuestionField('firstName')
const lastNameField = useSenderQuestionField('lastName')
</script>
