import { Organization, OrganizationWrite } from '@/models/organizations'
import axios from 'axios'
import { API_ROOT_URL } from '@/constants'

export const readMyOrganization = () => {
    return axios.get<Organization>(`${API_ROOT_URL}/activoice/my-organization/`)
}

export const updateMyOrganization = (organization: OrganizationWrite) => {
    return axios.put<Organization>(
        `${API_ROOT_URL}/activoice/my-organization/`,
        organization
    )
}

export const updateLogoOrganizationRequest = (formData: FormData) => {
    return axios.post(
        `${API_ROOT_URL}/activoice/my-organization/logo/`,
        formData
    )
}
