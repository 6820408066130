<template>
    <div class="flex flex-col justify-center items-center h-full">
        <div class="text-lg">
            <span v-if="hasFailed">
                {{ t('backoffice.user.emailVerificationFailed') }}
            </span>
            <span v-else>
                {{ t('backoffice.user.emailVerificationInProgress') }}
            </span>
        </div>
        <AVLoader v-show="authStore.isBusy" :size="58"></AVLoader>
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from './stores'
import AVLoader from '@/components/AVLoader.vue'
import router from '@/router'
import { RequestStatus } from '@/types'
import { useToastsStore } from '@/stores/toasts'
const toastsStore = useToastsStore()

interface Props {
    emailKey: string
}

const props = defineProps<Props>()
const { t } = useI18n()
const hasFailed = computed(
    () =>
        authStore.tokensRequestTracker.getRequestStatus() ===
        RequestStatus.ERROR
)

const authStore = useAuthStore()

onMounted(async () => {
    const requestStatus = await authStore.verifyEmail(props.emailKey)
    if (requestStatus === RequestStatus.SUCCESS) {
        toastsStore.addMessageTKey('backoffice.user.emailVerified', 'success')
        router.push({ name: 'manage_emails' })
    } else {
        for (const error of (
            authStore.tokensRequestTracker.state.error?.data as {
                errors: Array<{ message: string }>
            }
        ).errors!) {
            toastsStore.addMessage(error.message, 'danger')
        }
    }
})
</script>
