/**
 * Allows to assert that two types match. Useful for testing
 * frontend types against backend generated schema.
 */

import { DeepReadonly } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertTypesMatch<T extends never>() {}
export type TypeEqualityGuard<A, B> = Exclude<A, B> | Exclude<B, A>
export type SameKeysGuard<A, B> = TypeEqualityGuard<keyof A, keyof B>

export const castToDeepReadOnly = <T>(obj: T): DeepReadonly<T> =>
    obj as DeepReadonly<T>
