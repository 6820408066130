import { RequestStatus } from '@/types'
import { HttpStatusCode } from 'axios'
import { ref, UnwrapRef } from 'vue'

/** Simple id to protect from race conditions,
 * and allow ignoring a response if a newer request
 * was issued in the meantime. */
type RequestId = number

export interface RequestTrackerError {
    status: HttpStatusCode
    data: object
}

export interface RequestTrackerData<T> {
    currentRequestId: RequestId
    requestStatus: RequestStatus
    loadedData: T | null
    error: RequestTrackerError | null
}

export const useRequestTracker = <T>() => {
    const state = ref<RequestTrackerData<T>>({
        currentRequestId: 0,
        requestStatus: RequestStatus.INIT,
        loadedData: null,
        error: null,
    })

    const runRequest = async <
        F extends (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...args: any
        ) => Promise<{ status: HttpStatusCode; data: object }>,
    >(
        runAxiosRequest: F,
        expected: HttpStatusCode,
        args: Parameters<F>
    ) => {
        const requestId = _setRequestInProgress()
        const response = await runAxiosRequest(...args)

        // If this is false, this means there was race conditions
        // and another request has been issued to this tracker
        // in the meantime, so we should ignore this response.
        if (!_isCurrentlyTrackedRequest(requestId)) {
            return RequestStatus.ABORTED
        } else if (response.status === expected) {
            _setRequestSuccess(response.data as T)
            return RequestStatus.SUCCESS
        } else {
            _setRequestError(response.status, response.data)
            return RequestStatus.ERROR
        }
    }

    const reset = () => {
        state.value = {
            ...state.value,
            requestStatus: RequestStatus.INIT,
            loadedData: null,
        }
    }

    const getLoadedData = () => state.value.loadedData

    const getRequestStatus = () => state.value.requestStatus

    const isRequestInProgress = () =>
        state.value.requestStatus === RequestStatus.IN_PROGRESS

    const _setRequestInProgress = () => {
        state.value = {
            currentRequestId: state.value.currentRequestId + 1,
            requestStatus: RequestStatus.IN_PROGRESS,
            loadedData: state.value.loadedData,
            error: null,
        }
        return state.value.currentRequestId
    }

    const _setRequestSuccess = (loadedData: T | null) => {
        state.value = {
            ...state.value,
            requestStatus: RequestStatus.SUCCESS,
            loadedData: loadedData as UnwrapRef<T> | null,
        }
    }

    const _setRequestError = (status: HttpStatusCode, errorData: object) => {
        state.value = {
            ...state.value,
            requestStatus: RequestStatus.ERROR,
            loadedData: null,
            error: {
                status: status,
                data: errorData,
            },
        }
    }

    const _isCurrentlyTrackedRequest = (requestId: RequestId) => {
        return state.value.currentRequestId === requestId
    }

    return {
        state,
        runRequest,
        reset,
        getLoadedData,
        getRequestStatus,
        isRequestInProgress,
    }
}
