<template>
    <div>
        <div
            v-if="
                !rlistPickerStore.rlists || rlistPickerStore.rlists.length == 0
            "
            class="mb-3 text-gray-500"
        >
            You have not created any lists yet
        </div>
        <AVSelectButtons
            v-bind="props"
            :multiple="true"
            :options="
                (rlistPickerStore.rlists || [])
                    .filter((rlist) => {
                        return !props.geolocated || rlist.geolocation_available
                    })
                    .map((rlist) => ({
                        value: rlist as ModelReference,
                        label: rlist.name,
                    }))
            "
            :compare-function="(a, b) => a.id === b.id"
        />
    </div>
</template>
<script setup lang="ts">
import { onBeforeMount } from 'vue'
import { useRListPickerStore } from '@/AppBackoffice/rlists/stores'
import { FormFieldEmits, FormFieldProps } from '@/utils/forms'
import { ModelReference } from '@/models/types'
import AVSelectButtons from '@/components/forms/AVSelectButtons.vue'

interface Props extends FormFieldProps<Array<ModelReference>> {
    geolocated?: boolean
}
interface Emits extends FormFieldEmits<Array<ModelReference>> {}

const props = withDefaults(defineProps<Props>(), {
    geolocated: false,
})

defineEmits<Emits>()

const rlistPickerStore = useRListPickerStore()

onBeforeMount(() => {
    rlistPickerStore.listRLists()
})
</script>
