<template>
    <AVModalWithRouter :route-name-on-close="props.routeNameOnClose">
        <template v-slot:content>
            <p class="text-gray-600 mb-6">
                {{ t('backoffice.recipients.deleteConfirmation') }}
            </p>
            <ModalButtons
                buttons-align="center"
                ok-t-key="backoffice.actions.delete"
                @ok="onDeleteClicked"
                :route-name-on-close="props.routeNameOnClose"
            />
        </template>
    </AVModalWithRouter>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import AVModalWithRouter from '@/components/AVModalWithRouter.vue'
import ModalButtons from '@/AppBackoffice/components/ModalButtons.vue'
import { useRecipientsBackofficeCrudStore } from './stores'
import { RouteName } from '@/types'
import { ModelId } from '@/models/types'

interface Props {
    routeNameOnClose: RouteName
    recipientId: ModelId
}

const props = defineProps<Props>()
const crudStore = useRecipientsBackofficeCrudStore()
const { t } = useI18n()

const onDeleteClicked = async () => {
    await crudStore.runDelete(props.recipientId, {
        nextRouteName: props.routeNameOnClose,
        message: 'backoffice.recipients.messageDeleted',
    })
    crudStore.runList()
}
</script>
<style scoped>
p {
    text-align: center;
}
</style>
