import { RouteRecordRaw } from 'vue-router'
import SendCampaignView from '@/AppCampaigns/SendCampaignView.vue'

const entryPoint: RouteRecordRaw = {
    path: '',
    children: [
        {
            path: '/campaign/:campaignId/',
            name: 'send_campaign',
            component: SendCampaignView,
            props: true,
        },
    ],
}

export default entryPoint
