<template>
    <div
        class="flex"
        :class="{ 'justify-end': !hasPrevious, 'justify-between': hasPrevious }"
    >
        <AVButton
            @click="emit('previous')"
            button-type="outline-primary"
            type="button"
            form="custom-questions-form"
            class="mt-10"
            v-if="hasPrevious"
        >
            <i
                class="fi fi-rs-angle-left translate-y-0.5 text-primary inline-block mr-2"
            ></i
            >{{ t('backoffice.pagination.previous') }}
        </AVButton>
        <div class="flex justify-end mt-10 space-x-2">
            <AVButton
                @click="emit('save')"
                button-type="accent"
                type="button"
                :form="formId || undefined"
                >Save</AVButton
            >
            <AVButton type="submit" :form="formId || undefined"
                >{{ t(nextButtonTKey)
                }}<i
                    class="fi fi-rs-angle-right translate-y-0.5 inline-block ml-2"
                    v-if="hasNext"
                >
                </i
            ></AVButton>
        </div>
    </div>
</template>
<script setup lang="ts">
import AVButton from '@/components/forms/AVButton.vue'
import { TKey } from '@/i18n'
import { useI18n } from 'vue-i18n'

interface Props {
    formId?: string
    nextButtonTKey?: TKey
    hasPrevious?: boolean
    hasNext?: boolean
}

withDefaults(defineProps<Props>(), {
    nextButtonTKey: 'backoffice.pagination.next',
    hasPrevious: false,
    hasNext: false,
})
const { t } = useI18n()

interface Emits {
    (event: 'save'): void
    (event: 'previous'): void
}

const emit = defineEmits<Emits>()
</script>
