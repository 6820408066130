<template>
    <div class="relative p-6">
        <LoaderWithOverlay v-if="listStore.isBusy" />
        <div
            v-if="listStore.data && generatedContent"
            class="flex flex-col justify-between items-start"
        >
            <PageHeader>
                <template #title>
                    {{ generatedContent.content.type }}
                </template>
                <template #subtitle>
                    {{ generatedContent.campaign_name }} -
                    {{ generatedContent.interpellation_name }}
                </template>
                <template #actions>
                    <AVButton
                        class="w-40 mr-2"
                        button-type="outline-primary"
                        :disabled="isFirstInList"
                        @click="onPreviousClicked"
                    >
                        {{ '<' }} {{ t('backoffice.pagination.previous') }}
                    </AVButton>
                    <AVButton
                        class="w-40"
                        button-type="outline-primary"
                        :disabled="isLastInList"
                        @click="onNextClicked"
                    >
                        {{ t('backoffice.pagination.next') }} {{ '>' }}
                    </AVButton>
                </template>
            </PageHeader>
            <div class="bg-white w-full shadow rounded-lg p-8">
                <div class="mb-4">
                    <strong
                        >{{
                            t('backoffice.generatedContents.campaignName')
                        }}:</strong
                    >
                    {{ generatedContent.campaign_name }}
                </div>
                <div class="mb-4">
                    <strong
                        >{{
                            t(
                                'backoffice.generatedContents.interpellationName'
                            )
                        }}:</strong
                    >
                    {{ generatedContent.interpellation_name }}
                </div>
                <div class="mb-4">
                    <strong
                        >{{ t('backoffice.generatedContents.date') }}:</strong
                    >
                    {{
                        new Date(
                            generatedContent.created_date
                        ).toLocaleDateString()
                    }}
                    -
                    {{
                        new Date(
                            generatedContent.created_date
                        ).toLocaleTimeString()
                    }}
                </div>
                <div class="mb-4">
                    <strong
                        >{{
                            t('backoffice.generatedContents.content')
                        }}:</strong
                    >
                    <div v-if="generatedContent.content.type === 'email'">
                        <div class="mb-2">
                            {{ generatedContent.content.subject }}
                        </div>
                        <div class="mb-2">
                            {{ generatedContent.content.body }}
                        </div>
                    </div>
                    <div
                        v-else-if="
                            generatedContent.content.type === 'comment' ||
                            generatedContent.content.type === 'tweet'
                        "
                    >
                        {{ generatedContent.content.text }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { RequestStatus } from '@/types'
import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue'
import PageHeader from '@/AppBackoffice/components/PageHeader.vue'
import { useGeneratedContentsBackofficeCrudStore } from './stores'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { ModelId } from '@/models/types'
import { GeneratedContent } from '@/models/generated-content'
import AVButton from '@/components/forms/AVButton.vue'
import { useToastsStore } from '@/stores/toasts'
import { GeneratedContentListParams } from './requests'
import last from 'lodash/last'
import { useRouter } from 'vue-router'
import { findIndex } from 'lodash'
import { watch } from 'vue'

export interface Props extends GeneratedContentListParams {
    generatedContentId: ModelId
}

const props = defineProps<Props>()

const { t } = useI18n()
const router = useRouter()
const toastsStore = useToastsStore()
const listStore = useGeneratedContentsBackofficeCrudStore()
const generatedContent = ref<GeneratedContent | null>(null)
const indexInPage = computed(() =>
    findIndex(
        listStore.data,
        (generatedContent) => generatedContent.id === props.generatedContentId
    )
)
const isLastItemInPage = computed(
    () => !!listStore.data && indexInPage.value === listStore.data.length - 1
)
const hasPreviousPage = computed(() => listStore.config.page > 1)
const hasNextPage = computed(
    () => !!listStore.meta && listStore.config.page < listStore.meta.totalPages
)
const isFirstInList = computed<boolean>(
    () =>
        !!listStore.data &&
        indexInPage.value === 0 &&
        hasPreviousPage.value === false
)
const isLastInList = computed<boolean>(
    () =>
        !!listStore.data &&
        isLastItemInPage.value === true &&
        hasNextPage.value === false
)

const onPreviousClicked = async () => {
    let newGeneratedContentId: ModelId
    let newPage = props.page
    // If first item in current page, we need to load previous page.
    if (indexInPage.value === 0) {
        if (hasPreviousPage.value) {
            newPage = listStore.config.page - 1
            await _loadData({
                ...props,
                page: newPage,
            })
            newGeneratedContentId = last(listStore.data)!.id

            // Button should be disabled in that case, so this should never happen.
        } else {
            throw new Error('No previous item')
        }
    } else {
        newGeneratedContentId = listStore.data![indexInPage.value - 1].id
    }
    _navigateToGeneratedContent(newPage, newGeneratedContentId)
}

const onNextClicked = async () => {
    let newGeneratedContentId: ModelId
    let newPage = props.page
    // If last item in page, we need to load next page.
    if (isLastItemInPage.value === true) {
        if (hasNextPage.value) {
            newPage = listStore.config.page + 1
            await _loadData({
                ...props,
                page: newPage,
            })
            newGeneratedContentId = listStore.data![0]!.id

            // Button should be disabled in that case, so this should never happen.
        } else {
            throw new Error('No next item')
        }
    } else {
        newGeneratedContentId = listStore.data![indexInPage.value + 1].id
    }
    _navigateToGeneratedContent(newPage, newGeneratedContentId)
}

const _navigateToGeneratedContent = (
    page: number,
    generatedContentId: ModelId
) => {
    router.push({
        name: 'generated_contents_read',
        params: {
            generatedContentId,
        },
        query: {
            ...router.currentRoute.value.query,
            page,
        },
    })
}

const _loadData = async (params: Props) => {
    const requestStatus = await listStore.runList(params)
    if (requestStatus === RequestStatus.ERROR) {
        toastsStore.addMessage(t('backoffice.messages.error'), 'danger')
        throw new Error('Failed to load generated contents')
    }
}

const _refreshGeneratedContent = () => {
    generatedContent.value = listStore.getItemById(props.generatedContentId)
    if (!generatedContent.value) {
        toastsStore.addMessage(t('backoffice.messages.error'), 'danger')
        throw new Error('GeneratedContent not found')
    }
}

onBeforeMount(async () => {
    await _loadData(props)
    _refreshGeneratedContent()
})
watch(() => [props.generatedContentId, props.page], _refreshGeneratedContent)
</script>
