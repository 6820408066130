<template>
    <div v-if="selectedStepsDefinitions.length > 1">
        <ProgressBarDesktop
            class="pt-6 hidden md:block"
            :selected-steps-definitions="selectedStepsDefinitions"
            :current-step-index="currentStepIndex"
            :can-go-backward="canGoBackward"
            :can-go-forward="canGoForward"
            @change-current-step="onChangeCurrentStep"
        />
        <ProgressBarMobile
            class="block md:hidden"
            :has-previous-button="canGoBackward"
            :title-t-key="currentStepDefinition.title"
            :description-t-key="currentStepDefinition.description"
            @previous="onMobilePreviousClicked"
        >
            <template #circle>
                <CircleProgress
                    :progress="
                        (currentStepIndex + 1) / selectedStepsDefinitions.length
                    "
                    :label="currentStepIndex + 1"
                />
            </template>
        </ProgressBarMobile>
    </div>
</template>
<script setup lang="ts">
import { StepDefinition } from './types'
import ProgressBarDesktop from './ProgressBarDesktop.vue'
import ProgressBarMobile from './ProgressBarMobile.vue'
import CircleProgress from './CircleProgress.vue'
import { computed } from 'vue'

interface Props {
    currentStepIndex: number
    selectedStepsDefinitions: Readonly<Array<StepDefinition>>
    canGoForward: boolean
    canGoBackward: boolean
}

interface Emits {
    (event: 'change-current-step', newStepIndex: number): void
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const currentStepDefinition = computed(
    () => props.selectedStepsDefinitions[props.currentStepIndex]
)

const onMobilePreviousClicked = () => {
    if (props.canGoBackward) {
        emit('change-current-step', props.currentStepIndex - 1)
    }
}

const onChangeCurrentStep = (newStepIndex: number) => {
    emit('change-current-step', newStepIndex)
}
</script>
