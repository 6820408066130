<template>
    <div v-if="store.customQuestions && store.customQuestions.length > 0">
        <div
            v-for="(question, index) in store.customQuestions"
            :key="index"
            class="mb-6 lg:mb-6"
        >
            <div v-if="question.type === 'text'">
                <AVInput
                    :name="buildCustomQuestionPath(index)"
                    v-model="store.customQuestionsAnswers[index]"
                    :label="question.question"
                    :placeholder="question.placeholder"
                />
            </div>
            <div v-if="question.type === 'select'">
                <AVSelect
                    :name="buildCustomQuestionPath(index)"
                    v-model="store.customQuestionsAnswers[index]"
                    :options="
                        question.options.map((option) => ({
                            value: option.prompt,
                            label: option.answer,
                        }))
                    "
                    :label="question.question"
                    :placeholder="question.placeholder"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import AVSelect from '@/components/forms/AVSelect.vue'
import AVInput from '@/components/forms/AVInput.vue'
import { useSendCampaignStore } from '@/AppCampaigns/stores'
import { buildCustomQuestionPath } from './validation'
const store = useSendCampaignStore()
</script>
